const isUndefinedOrWhiteSpace = (text: string = ''): boolean =>
    text === undefined ||
    text === null ||
    text === void 0 ||
    text === '' ||
    text.trim() === '';

const getStringOrHifen = (text: string = '-'): string => text;

const StringUtils = {
    isUndefinedOrWhiteSpace,
    getStringOrHifen,
};

export default StringUtils;
