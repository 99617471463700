import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllShapeStandard } from '@app/store/shapeStandard/reducer';

export const useShapeStandard = (): void => {
    const { isFetching, lastUpdated, shapeStandards } = useAppSelector(
        (state) => state.shapeStandard
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && shapeStandards.length === 0 && !lastUpdated) {
            dispatch(getAllShapeStandard());
        }
    }, []);
};
