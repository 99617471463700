import TablePaginator from '@app/molecules/TablePaginator';
import { TableRow, TableFooter } from '@mui/material';
import { PropsWithChildren, memo } from 'react';

interface TableFooterRowProps {
    paginationControl: {
        rowsPerPageOptions?: Array<number | { label: string; value: number }>;
        count: number;
        rowsPerPage: number;
        setRowsPerPage: (value: number) => void;
        page: number;
        setPage: (value: number) => void;
    };
    colSpan?: number;
}

const TableFooterRow = (props: PropsWithChildren<TableFooterRowProps>) => (
    <TableFooter>
        <TableRow>
            <TablePaginator
                paginationControl={props.paginationControl}
                colSpan={props.colSpan}
            />
        </TableRow>
    </TableFooter>
);

export default memo(TableFooterRow);
