import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useCoatingAreaType } from '@app/hooks/useCoatingAreaType';
import { useCustomer } from '@app/hooks/useCustomer';
import { useKilnStatus } from '@app/hooks/useKilnStatus';
import { useProcessType } from '@app/hooks/useProcessType';
import { useSectionType } from '@app/hooks/useSectionType';
import { useStressAreaType } from '@app/hooks/useStressAreaType';
import { BaseMeasurableModel } from '@app/models/BaseMeasurableModel';
import { CoatingAreaModel } from '@app/models/CoatingAreaModel';
import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import { KilnTireModel } from '@app/models/KilnTireModel';
import { SectionModel } from '@app/models/SectionModel';
import { StressAreaModel } from '@app/models/StressAreaModel';
import KilnCoatingBundle from '@app/organisms/KilnProfile/KilnCoatingAreas/KilnCoatingBundle';
import KilnParametersBundle from '@app/organisms/KilnProfile/KilnParameters/KilnParametersBundle';
import KilnSectionsBundle from '@app/organisms/KilnProfile/KilnSections/KilnSectionsBundle';
import KilnStatusBundle from '@app/organisms/KilnProfile/KilnStatus/KilnStatusBundle';
import KilnStressBundle from '@app/organisms/KilnProfile/KilnStressAreas/KilnStressBundle';

import { KilnProfileStructureService } from '@app/services/KilnProfileStructureService';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import NumberUtils from '@app/utils/NumberUtils';
import StringUtils from '@app/utils/StringUtils';
import UriUtils from '@app/utils/UriUtils';
import { Grid, Typography, Button } from '@mui/material';
import { useEffect, useMemo, useState, useCallback, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const INITIAL_STATE = {
    id: '',
    createdBy: '',
    createdAt: new Date(),
};

const KilnProfilePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { customerId, kilnProfileId, isEdit, isNew } =
        UriUtils.parseQueryString(location.search);

    const { coatingAreaTypes } = useAppSelector(
        (state) => state.coatingAreaType
    );
    const { stressAreaTypes } = useAppSelector((state) => state.stressAreaType);
    const { sectionTypes } = useAppSelector((state) => state.sectionType);
    const { kilnStatus } = useAppSelector((state) => state.kilnStatus);
    const { processTypes } = useAppSelector((state) => state.processType);

    const [idKilnStatus, setIdKilnStatus] = useState<string>('');
    const [kilnName, setKilnName] = useState<string>('');
    const [salesPerson, setSalesPerson] = useState<string>('');
    const [salesOrg, setSalesOrg] = useState<string>();

    const [idProcessType, setIdProcessType] = useState<string>('');
    const [mrk, setMrk] = useState<number>(610);
    const [diameter, setDiameter] = useState<number>();
    const [length, setLength] = useState<number>();
    const [clinkerCapacity, setClinkerCapacity] = useState<number>();
    const [girthGear, setGirthGear] = useState<number>();

    const tire: KilnTireModel = {position: 0, id: "", 
    createdAt: new Date(), createdBy: "undefined"};

    const [tires, setTires] = useState<KilnTireModel[]>([tire]);
    const [sections, setSections] = useState<SectionModel[]>([]);
    const [stressAreas, setStressAreas] = useState<StressAreaModel[]>([]);
    const [coatingAreas, setCoatingAreas] = useState<CoatingAreaModel[]>([]);

    useCustomer(customerId as string);
    useCoatingAreaType();
    useStressAreaType();
    useSectionType();
    useKilnStatus();
    useProcessType();

    const kilnProfileService = useMemo(
        () => new KilnProfileStructureService(),
        []
    );

    useEffect(() => {
        if (
            !StringUtils.isUndefinedOrWhiteSpace(kilnProfileId as string) &&
            kilnStatus.length &&
            processTypes.length
        ) {
            const fetchKiln = async () => {
                try {
                    dispatch(setLoading(true));
                    const data = await kilnProfileService.getById(
                        kilnProfileId as string
                    );

                    if (data) {
                        setSections(data.sections);
                        setStressAreas(data.stressAreas);
                        setCoatingAreas(data.coatingAreas);
                        setIdProcessType(data.idProcessType);
                        setIdKilnStatus(data.idKilnStatus);
                        setKilnName(data.kilnName);
                        setSalesPerson(data.salesPerson);
                        setSalesOrg(data.salesOrg);
                        setMrk(data.mrk);
                        setDiameter(data.diameter);
                        setLength(data.length);
                        setClinkerCapacity(data.clinkerCapacity);
                        setGirthGear(data.girthGear);
                        setTires(data.tires);
                    }

                    dispatch(
                        setMessage({
                            message: 'Successfully loaded kiln profile data!',
                            title: 'Success',
                            color: 'success',
                            open: true,
                        })
                    );
                } catch (ex) {
                    dispatch(
                        setMessage({
                            message: 'Error loading kiln profile data!',
                            title: 'Error',
                            color: 'error',
                            open: true,
                        })
                    );
                }
                dispatch(setLoading(false));
            };
            fetchKiln();
        }
    }, [kilnProfileId, processTypes, kilnStatus]);

    const [expanded, setExpanded] = useState<number>(-1);

    const testLength = (values: BaseMeasurableModel[]) => {
        if (length) {
            const hasLength = values.length > 0;

            if (hasLength) {
                let overrides = false;
                const copy = values.slice();
                copy.sort((a, b) => a.start - b.start);
                copy.forEach((value, index) => {
                    if (index > 0) {
                        overrides =
                            copy[index - 1].end !== value.start || overrides;
                    }
                    overrides = value.end < value.start || overrides;
                });
                const startsAtZero = copy[0].start === 0;
                const endsAtLength = copy[copy.length - 1].end === length;

                return !overrides && startsAtZero && endsAtLength;
            }
        }

        return false;
    };

    const testDiameter = (values: SectionModel[]) => {
        if (diameter) {
            const hasLength = values.length > 0;

            if (hasLength) {
                let isNotZero = true;
                values.forEach((value) => {
                    isNotZero =
                        isNotZero && value.diameter > 0 && value.diameter2 > 0;
                });

                return isNotZero;
            }
        }

        return false;
    };

    const sectionsValid = useMemo(
        () =>
            testLength(sections) &&
            testDiameter(sections) &&
            sections.every(
                (section) =>
                    !StringUtils.isUndefinedOrWhiteSpace(section.idSectionType)
            ),
        [sections, length, diameter]
    );

    const coatingsValid = useMemo(
        () =>
            coatingAreas.every(
                (coating) =>
                    !StringUtils.isUndefinedOrWhiteSpace(
                        coating.idCoatingAreaType
                    )
            ),
        [coatingAreas, length]
    );

    const stressValid = useMemo(
        () =>
            stressAreas.every((stress) =>
                stress.stressAreaTypes.every(
                    (stressType) =>
                        !StringUtils.isUndefinedOrWhiteSpace(
                            stressType.idStressAreaType
                        )
                )
            ),
        [stressAreas, length]
    );

    const isValid = useMemo(
        () =>
            sectionsValid &&
            coatingsValid &&
            stressValid &&
            !StringUtils.isUndefinedOrWhiteSpace(idKilnStatus) &&
            !StringUtils.isUndefinedOrWhiteSpace(idProcessType) &&
            NumberUtils.isValidNumber(clinkerCapacity) &&
            !StringUtils.isUndefinedOrWhiteSpace(kilnName) &&
            !StringUtils.isUndefinedOrWhiteSpace(salesPerson) &&
            NumberUtils.isValidNumber(mrk) &&
            NumberUtils.getNumber(diameter) > 0 &&
            NumberUtils.getNumber(length) > 0 &&
            (isEdit || isNew),
        [
            coatingAreas,
            stressAreas,
            sections,
            idKilnStatus,
            idProcessType,
            clinkerCapacity,
            kilnName,
            salesOrg,
            salesPerson,
            mrk,
            diameter,
            length,
        ]
    );

    const getKilnProfile = (): KilnProfileStructureModel => {
        return {
            ...INITIAL_STATE,
            id: (kilnProfileId as string) ?? '',
            idCustomer: customerId as string,
            coatingAreas,
            sections,
            stressAreas,
            tires,
            idProcessType: idProcessType,
            idKilnStatus: idKilnStatus,
            salesPerson: salesPerson,
            kilnName: kilnName,
            mrk: NumberUtils.getNumber(mrk),
            diameter: NumberUtils.getNumber(diameter),
            length: NumberUtils.getNumber(length),
            clinkerCapacity: NumberUtils.getNumber(clinkerCapacity),
            girthGear: girthGear ? NumberUtils.getNumber(girthGear) : undefined,
            salesOrg,
        };
    };

    const createKilnProfile = async () => {
        if (isNew && isValid && !isEdit) {
            try {
                await kilnProfileService.createStructure(getKilnProfile());
                dispatch(
                    setMessage({
                        message: 'Successfully created kiln profile!',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: 'Error creating kiln profile data!',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
                throw ex;
            }
        }
    };

    const editKilnProfile = async () => {
        if (isEdit && !isNew && isValid) {
            try {
                await kilnProfileService.updateStructure(getKilnProfile());
                dispatch(
                    setMessage({
                        message: 'Successfully created kiln profile!',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: 'Error creating kiln profile data!',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
                throw ex;
            }
        }
    };

    const renderSections = useCallback(
        () =>
            length !== undefined && diameter !== undefined ? (
                <KilnSectionsBundle
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setSections={setSections}
                    sections={sections}
                    sectionTypes={sectionTypes}
                    length={length}
                    diameter={diameter}
                />
            ) : (
                <></>
            ),
        [length, diameter, sections, expanded, sectionTypes]
    );

    const renderCoatingAreas = useCallback(
        () =>
            length !== undefined ? (
                <KilnCoatingBundle
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setCoatingAreas={setCoatingAreas}
                    coatingAreas={coatingAreas}
                    coatingAreaTypes={coatingAreaTypes}
                    length={length}
                />
            ) : (
                <></>
            ),
        [length, expanded, coatingAreas, coatingAreaTypes]
    );

    const renderStressAreas = useCallback(
        () =>
            length !== undefined ? (
                <KilnStressBundle
                    expanded={expanded}
                    setExpanded={setExpanded}
                    setStressAreas={setStressAreas}
                    stressAreas={stressAreas}
                    stressAreaTypes={stressAreaTypes}
                    length={length}
                />
            ) : (
                <></>
            ),
        [length, expanded, stressAreas, stressAreaTypes]
    );

    return (
        <>
            <Grid container justifyContent='space-between'>
                <Grid item>
                    <Typography variant='h5' color='secondary'>
                        {isEdit ? 'Edit Kiln Profile' : 'New Kiln Profile'}
                    </Typography>
                    <Typography variant='overline'>
                        {isEdit
                            ? 'Edit a Kiln Profile'
                            : 'Create a Kiln Profile'}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        color='secondary'
                        size='large'
                        disabled={!isValid}
                        onClick={async () => {
                            dispatch(setLoading(true));
                            try {
                                if (isEdit) {
                                    await editKilnProfile();
                                } else {
                                    await createKilnProfile();
                                }
                                navigate(
                                    UriUtils.stringifyQueryObject('/overview', {
                                        customerId: customerId,
                                        kilnProfileId: kilnProfileId,
                                        view: 'campaigns',
                                    })
                                );
                            } catch (ignored) {}
                            dispatch(setLoading(false));
                        }}
                    >
                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                            SAVE
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <KilnStatusBundle
                expanded={expanded}
                setExpanded={setExpanded}
                setIdKilnStatus={setIdKilnStatus}
                idKilnStatus={idKilnStatus}
                setKilnName={setKilnName}
                kilnName={kilnName}
                setSalesOrg={setSalesOrg}
                salesOrg={salesOrg}
                setSalesPerson={setSalesPerson}
                salesPerson={salesPerson}
                kilnStatus={kilnStatus}
            />
            <KilnParametersBundle
                expanded={expanded}
                setExpanded={setExpanded}
                setIdProcessType={setIdProcessType}
                idProcessType={idProcessType}
                setDiameter={setDiameter}
                diameter={diameter}
                setLength={setLength}
                length={length}
                setMrk={setMrk}
                mrk={mrk}
                setTires={setTires}
                tires={tires}
                setClinkerCapacity={setClinkerCapacity}
                clinkerCapacity={clinkerCapacity}
                setGirthGear={setGirthGear}
                girthGear={girthGear}
                processTypes={processTypes}
            />
            {renderSections()}
            {renderStressAreas()}
            {renderCoatingAreas()}
        </>
    );
};

export default memo(KilnProfilePage);
