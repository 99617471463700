import { useAppDispatch } from '@app/hooks';
import { setLoading } from '@app/store/loading/reducer';
import { MessageState, setMessage } from '@app/store/message/reducer';
import { useEffect, useState } from 'react';

type IProps = {
    method: Function;
    service: any;
    success?: MessageState;
    error?: MessageState;
    default?: any;
    params?: any;
};

const useRequest = (props: IProps): any => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<any>(props.default);

    useEffect(() => {
        const doRequest = async () => {
            try {
                dispatch(setLoading(true));
                const response = await props.method.call(
                    props.service,
                    props.params
                );
                setData(response);

                if (props.success) dispatch(setMessage(props.success));
            } catch (error) {
                if (props.error) dispatch(setMessage(props.error));
            }
            dispatch(setLoading(false));
        };
        doRequest();
    }, []);

    return data;
};

export default useRequest;
