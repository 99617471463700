import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllMPG } from '@app/store/mpg/reducer';

export const useMPG = (): void => {
    const { isFetching, lastUpdated, MPGs } = useAppSelector(
        (state) => state.mpg
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && MPGs.length === 0 && !lastUpdated) {
            dispatch(getAllMPG());
        }
    }, []);
};
