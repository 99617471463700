import TableList from '@app/organisms/TableList';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Tooltip,
} from '@mui/material';
import { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '@app/theme';
import StringUtils from '@app/utils/StringUtils';
import { CustomerModel } from '@app/models/CustomerModel';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCustomer } from '@app/store/customer/reducer';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import { ArrowForward } from '@mui/icons-material';
import UriUtils from '@app/utils/UriUtils';
import { RootState } from '@app/store';
import { useCustomer } from '@app/hooks/useCustomer';
import { useSalesUnit } from '@app/hooks/useSalesUnit';
import { useUserSettings } from '@app/hooks/useUserSettings';

const LaunchpadPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { customer, salesUnit } = useAppSelector((state: RootState) => state);

    const [salesUnitFilter, setSalesUnitFilter] = useState<string>('All');
    const [enabled, setEnabled] = useState<string>('All');

    useUserSettings();
    useCustomer();
    useSalesUnit();

    const getSelectAction = useCallback((x: CustomerModel) => {
        const handleClick = async () => {
            dispatch(selectCustomer(x));
            navigate(
                UriUtils.stringifyQueryObject('/overview', {
                    customerId: x.id,
                })
            );
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip
                    title={
                        x.enabled
                            ? 'Select Customer'
                            : 'This account is disabled'
                    }
                >
                    <ArrowForward
                        sx={
                            x.enabled
                                ? { cursor: 'pointer' }
                                : { cursor: 'not-valid', opacity: 0.3 }
                        }
                        onClick={x.enabled ? handleClick : () => {}}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const columns = useMemo(
        () => [
            {
                label: 'Customer Code',
                value: 'customerId',
            },
            {
                label: 'Customer Site Name',
                value: 'name',
            },
            {
                label: 'Country',
                value: 'country',
            },
            {
                label: 'City',
                value: 'city',
            },
            {
                label: 'Select',
                value: TableBodyRowSystem.ROW,
                handle: getSelectAction,
            },
        ],
        []
    );

    const getAdditionalFilters = useCallback(() => {
        const handleEnabledChange = ({
            target: { value },
        }: {
            target: { value: any };
        }) => {
            if (value !== enabled) setEnabled(value);
        };

        const handleSalesUnitChange = ({
            target: { value },
        }: {
            target: { value: any };
        }) => {
            if (value !== salesUnit) setSalesUnitFilter(value);
        };

        return (
            <Grid container margin='0' gap='8px'>
                <Grid item margin='0'>
                    <FormControl fullWidth>
                        <InputLabel
                            variant='outlined'
                            htmlFor='enabled'
                            sx={{ backgroundColor: colors['White-RHIM'] }}
                            shrink
                        >
                            Customer Status
                        </InputLabel>
                        <Select
                            value={enabled}
                            label='Enabled'
                            size='small'
                            sx={{ margin: '0', width: '140px' }}
                            onChange={handleEnabledChange}
                            inputProps={{
                                id: 'enabled',
                                variant: 'outlined',
                            }}
                        >
                            <MenuItem key={`enabled-item-all`} value='All'>
                                All
                            </MenuItem>
                            <MenuItem key={`enabled-item-all`} value='true'>
                                Active
                            </MenuItem>
                            <MenuItem key={`enabled-item-all`} value='false'>
                                Inactive
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item margin='0'>
                    <FormControl fullWidth>
                        <InputLabel
                            variant='outlined'
                            htmlFor='salesUnit'
                            sx={{ backgroundColor: colors['White-RHIM'] }}
                            shrink
                        >
                            Sales Unit
                        </InputLabel>
                        <Select
                            value={salesUnitFilter}
                            label='Sales Unit'
                            size='small'
                            sx={{ margin: '0', width: '140px' }}
                            onChange={handleSalesUnitChange}
                            inputProps={{
                                id: 'salesUnit',
                                variant: 'outlined',
                            }}
                        >
                            <MenuItem key={`sales-unit-item-all`} value='All'>
                                All
                            </MenuItem>
                            {salesUnit.salesUnits.map((su, index) => (
                                <MenuItem
                                    key={`sales-unit-item-${index}`}
                                    value={su.id}
                                >
                                    {su.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }, [salesUnit, salesUnitFilter, enabled]);

    const filteredData = useMemo(() => {
        const filtered =
            StringUtils.isUndefinedOrWhiteSpace(salesUnitFilter) ||
            salesUnitFilter === 'All'
                ? customer.customers
                : customer.customers.filter(
                      (x: CustomerModel) => x.idSalesUnit === salesUnitFilter
                  );
        return StringUtils.isUndefinedOrWhiteSpace(enabled) || enabled === 'All'
            ? filtered
            : filtered.filter(
                  (x: CustomerModel) => x.enabled.toString() === enabled
              );
    }, [customer, salesUnitFilter, enabled]);

    return (
        <Grid container margin={0}>
            <Typography variant='h5' color='secondary'>
                Launchpad
            </Typography>
            <TableList
                columns={columns}
                data={filteredData}
                rowsPerPage={5}
                searchColumns={[
                    'customerId',
                    'name',
                    'city',
                    'country',
                    'salesPerson',
                ]}
                searchSibling={getAdditionalFilters()}
            />
        </Grid>
    );
};

export default memo(LaunchpadPage);
