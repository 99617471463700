import { useMemo, memo } from 'react';
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Grid,
    FormHelperText,
} from '@mui/material';
import StringUtils from '@app/utils/StringUtils';

type Option = {
    value: any;
    label: string;
};

type IProps = {
    variant?: 'filled' | 'outlined' | 'standard';
    title?: string;
    label?: string;
    value: string;
    onChange: Function;
    size?: 'medium' | 'small';
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    index?: number;
    options?: Option[];
    error?: boolean;
    helperText?: string;
    placeholder?: string;
};

const SelectInput = (props: IProps) => {
    const {
        variant = 'outlined',
        label,
        title,
        value,
        options = [],
        onChange,
        size = 'small',
        required = false,
        readonly = false,
        disabled = false,
        error = false,
        helperText,
        index = 0,
        placeholder,
    } = props;

    const hasLabel = useMemo(
        () => !StringUtils.isUndefinedOrWhiteSpace(label),
        [label]
    );

    const hasTitle = useMemo(
        () => !StringUtils.isUndefinedOrWhiteSpace(title),
        [title]
    );

    const notFilled = useMemo(
        () => required && StringUtils.isUndefinedOrWhiteSpace(value),
        [required, value]
    );

    const legend = useMemo(() => {
        if (error) return helperText;
        if (notFilled) return 'Field must be filled.';
    }, [error, notFilled, helperText]);

    const hasError = useMemo(() => error || notFilled, [error, notFilled]);

    return (
        <Grid sx={{ width: '100%', margin: '0' }}>
            {hasTitle ? (
                <InputLabel>{`${title} ${required ? '*' : ''}`}</InputLabel>
            ) : (
                <></>
            )}
            <FormControl
                variant={variant}
                size={size}
                fullWidth
                error={hasError}
            >
                {hasLabel ? (
                    <InputLabel
                        id={`select-${label ?? ''}-${index}`}
                    >{`${label} ${required ? '*' : ''}`}</InputLabel>
                ) : (
                    <></>
                )}
                <Select
                    label={label}
                    size={size}
                    labelId={`select-${label ?? ''}-${index}`}
                    id={`select-${label ?? ''}-${index}`}
                    value={value}
                    onChange={({ target: { value } }: any) => {
                        onChange(value);
                    }}
                    required={required}
                    readOnly={readonly}
                    disabled={disabled}
                    error={hasError}
                    placeholder={placeholder}
                >
                    {options.map((option: Option, id: number) => (
                        <MenuItem
                            key={`select-${
                                label ?? ''
                            }-${index}--menu-item-${id}`}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                {hasError ? <FormHelperText>{legend}</FormHelperText> : <></>}
            </FormControl>
        </Grid>
    );
};

export default memo(SelectInput);
