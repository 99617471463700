import { Button, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { PropsWithChildren, ReactElement, memo } from 'react';

interface ModalProps {
    title: string | ReactElement;
    showModal: boolean;
    handleClose: () => void;
    handleCancel: () => void;
    handleConfirm: () => void;
    confirmDisabled?: boolean;
    confirmTitle?: string;
    confirmColor?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    confirmVariant?: 'text' | 'outlined' | 'contained';
    cancelTitle?: string;
    cancelColor?:
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning';
    cancelVariant?: 'text' | 'outlined' | 'contained';
    width?: string;
    height?: string;
    extraButton?: ReactElement;
}

const Modal = (props: PropsWithChildren<ModalProps>) => (
    <Dialog
        open={props.showModal}
        onClose={props.handleClose}
        PaperProps={{
            sx: {
                padding: '32px',
                overflow: 'hidden',
                width: props.width ?? 'auto',
                height: props.height ?? 'auto',
            },
        }}
    >
        <DialogTitle
            color='secondary'
            sx={{ padding: 0, marginBottom: '24px' }}
        >
            {props.title}
        </DialogTitle>
        {props.children}
        <Grid
            container
            justifyContent='flex-end'
            marginTop='24px'
            paddingRight='8px'
            gap='8px'
        >
            <Button
                variant={props.cancelVariant ?? 'outlined'}
                color={props.cancelColor ?? 'secondary'}
                sx={
                    props.cancelVariant !== undefined &&
                    props.cancelVariant === 'text'
                        ? { boxShadow: 'none' }
                        : {}
                }
                size='large'
                onClick={props.handleCancel}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {props.cancelTitle ?? 'CANCEL'}
                </Typography>
            </Button>
            <Button
                variant={props.confirmVariant ?? 'contained'}
                color={props.confirmColor ?? 'secondary'}
                sx={
                    props.confirmVariant !== undefined &&
                    props.confirmVariant === 'text'
                        ? { boxShadow: 'none' }
                        : {}
                }
                size='large'
                onClick={props.handleConfirm}
                disabled={props.confirmDisabled ?? false}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {props.confirmTitle ?? 'SAVE'}
                </Typography>
            </Button>
            {props.extraButton ?? <></>}
        </Grid>
    </Dialog>
);

export default memo(Modal);
