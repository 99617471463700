import { UserSettingsModel } from '@app/models/UserSettingsModel';
import { UserSettingsRepository } from '@app/repositories/UserSettingsRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class UserSettingsService extends ServiceBase<
    UserSettingsModel,
    UserSettingsRepository<UserSettingsModel>
> {
    public constructor() {
        super(new UserSettingsRepository());
    }
}
