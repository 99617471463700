import { createContext, memo, useEffect, useState, useMemo } from 'react';
import { OptionMap } from '@app/organisms/CardFilter/FilterModal';

type IProviderProps = {
    applied: OptionMap;
    setApplied: (value: OptionMap) => void;
    hasFilterApplied: boolean;
    previousContainsApplied: boolean;
    children?: any;
    previousFilter: OptionMap;
};

export const INITIAL_VALUES: IProviderProps = {
    applied: {},
    previousFilter: {},
    hasFilterApplied: false,
    previousContainsApplied: false,
    setApplied: () => {},
};

export const CardFilterContextManager = createContext(INITIAL_VALUES);

const CardFilterContext = (props: IProviderProps) => {
    const [applied, setApplied] = useState<OptionMap>(
        props.applied ?? INITIAL_VALUES.applied
    );

    const [previousFilter, setPreviousFilter] = useState<OptionMap>(
        applied ?? INITIAL_VALUES.previousFilter
    );

    useEffect(() => {
        if (applied !== props.applied) {
            setPreviousFilter(applied);
            setApplied(props.applied);
        }
    }, [props.applied]);

    const hasApplied = useMemo(() => {
        const values = Object.values(applied);
        return (
            applied !== INITIAL_VALUES.applied &&
            values.length > 0 &&
            values.some((apply) => apply !== undefined && apply.length > 0)
        );
    }, [applied]);

    const previousContainsApplied = useMemo(() => {
        const keys = Object.keys(previousFilter);
        return (
            (keys.length > 0 &&
                keys.every((key) =>
                    previousFilter[key].every((previous) =>
                        applied[key].some(
                            (apply) => apply.value === previous.value
                        )
                    )
                )) ||
            (keys.length === 0 && Object.keys(applied).length > 0)
        );
    }, [applied, previousFilter]);

    return (
        <CardFilterContextManager.Provider
            value={{
                applied,
                setApplied,
                hasFilterApplied: hasApplied,
                previousFilter,
                previousContainsApplied,
            }}
        >
            {props.children}
        </CardFilterContextManager.Provider>
    );
};

export default memo(CardFilterContext);
