import { memo, useCallback, useEffect } from 'react';
import { Add, Delete } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { colors } from '@app/theme';
import NumberInput from '@app/atoms/NumberInput';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { KilnTireModel } from '@app/models/KilnTireModel';

export interface TiresAddProps {
    handleTiresChange: Function;
    tires: KilnTireModel[];
}

const TiresAdd = (props: TiresAddProps) => {
    useUserSettings();

    const { handleTiresChange, tires } = props;

    useEffect(() => {
        if (!tires.length) handleTiresAddition();
    }, [tires]);

    const handleTiresAddition = () => {
        handleTiresChange([...tires, undefined]);
    };

    const handleTiresDeletion = () => {
        handleTiresChange(tires.slice(0, -1));
    };

    const renderTires = useCallback(() => {
        const handleTireChange = (value: number, index: number) => {
            const newValue = { position: value ?? 0 } as KilnTireModel;
            const values = tires.slice();
            values.splice(index, 1, newValue);
            handleTiresChange(values);
        };

        return tires.map((tire: KilnTireModel, index: number) => (
            <Grid
                item
                marginLeft='0'
                marginRight='8px'
                key={`tires-add-${index}`}
            >
                <NumberInput
                    title={`Tire ${index + 1}`}
                    value={tire?.position}
                    size='small'
                    onChange={(value: number) => {
                        handleTireChange(value, index);
                    }}
                    error={
                        index > 0 && tires[index - 1]?.position > tire?.position
                    }
                    helperText={`Value must be bigger than tire ${index}.`}
                />
            </Grid>
        ));
    }, [tires, handleTiresChange]);

    return (
        <>
            {renderTires()}
            {tires.length < 10 && (
                <IconButton
                    size='large'
                    aria-label='add tire'
                    sx={{ marginTop: '30px' }}
                    onClick={handleTiresAddition}
                >
                    <Add fontSize='small' />
                </IconButton>
            )}
            {tires.length > 1 && (
                <IconButton
                    size='large'
                    aria-label='delete tire'
                    sx={{
                        marginTop: '30px',
                        borderLeft: 0,
                    }}
                    onClick={handleTiresDeletion}
                >
                    <Delete
                        fontSize='small'
                        sx={{ color: colors['Red-RHIM'] }}
                    />
                </IconButton>
            )}
        </>
    );
};

export default memo(TiresAdd);
