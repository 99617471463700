import MapChart, { Country } from '@app/atoms/MapChart';
import PizzaChart, { ChartRegistry } from '@app/atoms/PizzaChart';
import { Grid } from '@mui/material';
import { memo } from 'react';
import CardFilter from '../CardFilter/CardFilter';
import { OptionMap, Option, DisabledFilter } from '../CardFilter/FilterModal';
import TotalTonnageInfo from './TotalTonnageInfo';

type OverviewTabProps = {
    options: OptionMap;
    chartData: ChartRegistry[];
    columns: string[];
    countries: Country[];
    total: number;
    years: string[];
    year?: string;
    setYear: Function;
    minimumFilter?: OptionMap;
    disabledFilters?: DisabledFilter;
};

const OverviewTab = (props: OverviewTabProps) => (
    <CardFilter
        width='100%'
        height='400px'
        title='Market Share & Market Size'
        years={props.years}
        setYear={props.setYear}
        year={props.year}
        data={props.options}
        columns={props.columns}
        minimumFilter={props.minimumFilter}
        disabledFilters={props.disabledFilters}
    >
        <Grid
            container
            margin='0'
            sx={{
                display: 'grid',
                gridTemplateColumns: '500px auto',
                gridGap: '24px',
            }}
        >
            <Grid item margin='0'>
                <PizzaChart data={props.chartData} radius={100} />
                <TotalTonnageInfo total={props.total} />
            </Grid>
            <Grid item margin='0'>
                <MapChart markers={[]} countries={props.countries} />
            </Grid>
        </Grid>
    </CardFilter>
);

export default memo(OverviewTab);
