import {
    Box,
    Button,
    Chip,
    Card,
    CardContent,
    CardHeader,
    Select,
    MenuItem,
    Typography,
    Badge,
    Grid,
} from '@mui/material';
import {
    PropsWithChildren,
    useState,
    memo,
    useContext,
    useCallback,
    useMemo,
} from 'react';
import FilterModal, { OptionMap, Option, DisabledFilter } from './FilterModal';
import { FilterAlt } from '@mui/icons-material';
import { CardFilterContextManager } from '@app/contexts/CardFilterContext';

type CardFilterProps = {
    width: string;
    height: string;
    title: string;
    years: string[];
    setYear: Function;
    year?: string;
    data?: OptionMap;
    columns?: string[];
    minimumFilter?: OptionMap;
    disabledFilters?: DisabledFilter;
};

const CardFilter = (props: PropsWithChildren<CardFilterProps>) => {
    const { applied, setApplied } = useContext(CardFilterContextManager);
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleChange = ({ target: { value } }: any) => {
        props.setYear(value);
    };

    const handleChipDelete = (column: string, index: number) => {
        if (
            props.minimumFilter === undefined ||
            props.minimumFilter[column] === undefined ||
            !props.minimumFilter[column].some(
                (min) => min.value === applied[column][index].value
            )
        ) {
            applied[column].splice(index, 1);
            setApplied({ ...applied });
        }
    };

    const appliedCount = useMemo(
        () =>
            props.columns?.filter((column: string) => applied[column]?.length)
                ?.length ?? 0,
        [applied, props.columns]
    );

    const renderFilterButton = useCallback(
        () => (
            <Button
                color='secondary'
                variant='outlined'
                startIcon={<FilterAlt />}
                onClick={handleClick}
            >
                FILTER
            </Button>
        ),
        []
    );

    const renderFilterBadgeButton = useCallback(
        () =>
            appliedCount ? (
                <Badge badgeContent={appliedCount} color='primary'>
                    {renderFilterButton()}
                </Badge>
            ) : (
                renderFilterButton()
            ),
        [appliedCount]
    );

    const renderSelectedFilters = useCallback(
        () =>
            (props.columns ?? []).map((column) =>
                (applied[column] ?? []).map((option, index) => (
                    <Chip
                        key={`card-filter--chips-${index}`}
                        label={option.label ?? option.value}
                        onDelete={() => {
                            handleChipDelete(column, index);
                        }}
                    />
                ))
            ),
        [applied, props.columns]
    );

    const renderSelectYear = useCallback(
        () => (
            <Select
                placeholder='Year'
                value={props.year}
                onChange={handleChange}
                sx={{ height: '40px', marginLeft: '12px' }}
            >
                <MenuItem
                    key={`sales-unit-item-all`}
                    value='All'
                    defaultChecked
                >
                    All
                </MenuItem>
                {props.years.map((y, index) => (
                    <MenuItem
                        value={y}
                        key={`card-filter--year-select-${index}`}
                    >
                        {y}
                    </MenuItem>
                ))}
            </Select>
        ),
        [props.year, props.years]
    );

    return (
        <Box width={props.width} height={props.height}>
            <Card variant='outlined'>
                <CardHeader
                    color='secondary'
                    title={
                        <Typography variant='h5' color='secondary'>
                            {props.title}
                        </Typography>
                    }
                    action={
                        <>
                            {renderFilterBadgeButton()}
                            {renderSelectYear()}
                        </>
                    }
                />
                <Grid container margin='0 16px'>
                    {renderSelectedFilters()}
                </Grid>
                <CardContent>{props.children}</CardContent>
            </Card>
            <FilterModal
                columns={props.columns ?? []}
                open={open}
                setOpen={setOpen}
                data={props.data ?? {}}
                minimumFilter={props.minimumFilter}
                disabledFilters={props.disabledFilters}
            />
        </Box>
    );
};

export default memo(CardFilter);
