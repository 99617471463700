import { memo, useMemo } from 'react';
import { Grid } from '@mui/material';
import CustomerTab from '@app/organisms/Tabs/Customer/Tab';
import KilnTab from '@app/organisms/Tabs/Kiln/Tab';
import InsightsTab from '@app/organisms/Tabs/Insights/Tab';
import TabContainer from '@app/organisms/TabContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCustomer } from '@app/hooks/useCustomer';
import UriUtils from '@app/utils/UriUtils';
import StringUtils from '@app/utils/StringUtils';

const OverviewPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { customerId } = UriUtils.parseQueryString(location.search);
    const customer = useCustomer(customerId as string);

    if (StringUtils.isUndefinedOrWhiteSpace(customerId as string))
        navigate('/launchpad');

    const tabs = useMemo(
        () =>
            customer
                ? [
                      {
                          component: <CustomerTab customer={customer} />,
                          label: 'CUSTOMER OVERVIEW',
                          disabled: Boolean(!customer),
                      },
                      {
                          component: <KilnTab />,
                          label: 'KILN OVERVIEW',
                          disabled: Boolean(!customer),
                      },
                      {
                          component: <InsightsTab customer={customer} />,
                          label: 'INSIGHTS',
                          disabled: Boolean(!customer),
                      },
                  ]
                : [],
        [customer]
    );

    return (
        <Grid container margin={0}>
            <TabContainer
                areaTitle={customer?.name ?? 'No customer selected'}
                tabsList={tabs}
                active={1}
            ></TabContainer>
        </Grid>
    );
};

export default memo(OverviewPage);
