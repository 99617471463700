import { BrandModel } from '@app/models/BrandModel';
import { BrandRepository } from '@app/repositories/BrandRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class BrandService extends ServiceBase<
    BrandModel,
    BrandRepository<BrandModel>
> {
    public constructor() {
        super(new BrandRepository());
    }
}
