import {
    ZoomableGroup,
    ComposableMap,
    Geographies,
    Geography,
    Marker,
} from 'react-simple-maps';
import geography from '@app/geo/topology.geo.json';
import { Tooltip } from '@mui/material';
import { colors } from '@app/theme';
import { ReactElement, memo, useState } from 'react';
import { geoCentroid, ExtendedFeature } from 'd3-geo';
import { MultiPolygon } from 'geojson';

export interface CountryMarkerFromFeature
    extends ExtendedFeature<MultiPolygon> {}

export type MarkerType = {
    name: string;
    coordinates: [number, number];
    element?: ReactElement;
    label?: string;
};

export type Country = {
    name: string;
    element?: ReactElement;
    label?: string;
};

type IMapChart = {
    markers: MarkerType[];
    showRegions?: boolean;
    countries?: Country[];
};

type ZoomablePosition = {
    coordinates: [number, number];
    zoom: number;
};

const MapChart = (props: IMapChart) => {
    const [position, setPosition] = useState<ZoomablePosition>({
        coordinates: [0, 0],
        zoom: 1,
    });

    const handleZoomIn = () => {
        if (position.zoom >= 10) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    };

    const handleZoomOut = () => {
        if (position.zoom <= 1) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    };

    const handleMoveEnd = (position: ZoomablePosition) => {
        setPosition(position);
    };

    const regions: MarkerType[] = [
        { name: 'South America', coordinates: [-70, -17] },
        { name: 'North America', coordinates: [-110, 41] },
        { name: 'Central America', coordinates: [-90, 15] },
        { name: 'Europe', coordinates: [25, 50] },
        { name: 'Asia', coordinates: [75, 40] },
        { name: 'Oceania', coordinates: [125, -27] },
        { name: 'Africa', coordinates: [10, 10] },
    ];

    return (
        <div
            style={{
                backgroundColor: colors['White-RHIM'],
                width: '100%',
                height: '100%',
            }}
        >
            <ComposableMap projection='geoMercator'>
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography={geography}>
                        {(props) =>
                            props.geographies.map((geo) => (
                                <Geography
                                    pointerEvents='none'
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={colors['Grey-RHIM']}
                                    stroke={colors['Grey20-RHIM']}
                                />
                            ))
                        }
                    </Geographies>
                    {props.showRegions &&
                        regions.map(({ name, coordinates }) => (
                            <Marker key={name} coordinates={coordinates}>
                                <text
                                    fontSize='14px'
                                    fontWeight='400'
                                    fill={colors['Grey40-RHIM']}
                                >
                                    {name}
                                </text>
                            </Marker>
                        ))}
                    {props.markers.map(
                        ({ name, coordinates, label, element }: MarkerType) => (
                            <Marker key={name} coordinates={coordinates}>
                                <Tooltip title={name}>
                                    {element ?? <text>{label}</text>}
                                </Tooltip>
                            </Marker>
                        )
                    )}
                    {props.countries?.map(
                        ({ name, label, element }: Country) => {
                            const useTitleFromGeoJson = name.length <= 3;
                            const countries = geography.features;
                            const country = countries.find(
                                (c) =>
                                    c.properties.name === name || c.id === name
                            );

                            if (country) {
                                const coordinates: [number, number] =
                                    geoCentroid(
                                        country as CountryMarkerFromFeature
                                    );
                                return (
                                    <Marker
                                        key={name}
                                        coordinates={coordinates}
                                    >
                                        <Tooltip
                                            title={
                                                useTitleFromGeoJson
                                                    ? label
                                                        ? `${country.properties.name}: ${label}`
                                                        : country.properties
                                                              .name
                                                    : label ?? name
                                            }
                                        >
                                            {element ?? <text>{label}</text>}
                                        </Tooltip>
                                    </Marker>
                                );
                            }

                            return <></>;
                        }
                    )}
                </ZoomableGroup>
            </ComposableMap>
            <div>
                <button onClick={handleZoomIn}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth='3'
                    >
                        <line x1='12' y1='5' x2='12' y2='19' />
                        <line x1='5' y1='12' x2='19' y2='12' />
                    </svg>
                </button>
                <button onClick={handleZoomOut}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth='3'
                    >
                        <line x1='5' y1='12' x2='19' y2='12' />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default memo(MapChart);
