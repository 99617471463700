import { SupplierModel } from '@app/models/SupplierModel';
import { SupplierService } from '@app/services/SupplierService';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { Add, Edit } from '@mui/icons-material';
import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import Modal from '@app/molecules/Modal';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import StringUtils from '@app/utils/StringUtils';
import { getAllSupplier } from '@app/store/supplier/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useSupplier } from '@app/hooks/useSupplier';

const INITIAL_STATE: SupplierModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    name: '',
    country: '',
    code: '#000000',
};

const SuppliersList = () => {
    const dispatch = useAppDispatch();
    const { suppliers } = useAppSelector((state) => state.supplier);
    useSupplier();

    const suppliersService = useMemo(
        () => new SupplierService(),
        []
    );

    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingSupplier, setEditingSupplier] = useState<SupplierModel>({
        ...INITIAL_STATE,
    });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingSupplier({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                name: value,
            });
        };

        const handleCountryChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                country: value,
            });
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                code: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await suppliersService.create({
                    ...editingSupplier,
                });
                dispatch(getAllSupplier());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingSupplier.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingSupplier.country);

        return (
            <Modal
                title='New Supplier'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingSupplier?.name}
                    label='Supplier name'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingSupplier?.country}
                    label='Country'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleCountryChange}
                />
                <Grid container item margin='0' sx={{ width: '367px' }}>
                    <TextField
                        value={editingSupplier?.code}
                        label='Color code'
                        type='color'
                        size='small'
                        sx={{ margin: '8px 0', width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleCodeChange}
                    />
                </Grid>
            </Modal>
        );
    }, [editingSupplier, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingSupplier({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                name: value,
            });
        };

        const handleCountryChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                country: value,
            });
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingSupplier({
                ...editingSupplier,
                code: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await suppliersService.update({
                    ...editingSupplier,
                });
                dispatch(getAllSupplier());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await suppliersService.delete(editingSupplier.id);
                dispatch(getAllSupplier());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingSupplier.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingSupplier.country);

        return (
            <Modal
                title='Edit Supplier'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingSupplier?.name}
                    label='Supplier name'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingSupplier?.country}
                    label='Country'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleCountryChange}
                />
                <Grid container item margin='0' sx={{ width: '367px' }}>
                    <TextField
                        value={editingSupplier?.code}
                        label='Color code'
                        type='color'
                        size='small'
                        sx={{ margin: '8px 0', width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleCodeChange}
                    />
                </Grid>
            </Modal>
        );
    }, [editingSupplier, showModalEditing]);

    const getEditAction = useCallback((supplier: SupplierModel) => {
        const handleClick = () => {
            setEditingSupplier(supplier);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Supplier'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((supplier: SupplierModel) => {
        const isChecked = supplier.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await suppliersService.update({
                    ...supplier,
                    enabled: !isChecked,
                });
                dispatch(getAllSupplier());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable Supplier'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(() => {
        const handleClick = () => {
            setEditingSupplier(INITIAL_STATE);
            setShowModalCreating(true);
        };

        return (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={handleClick}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW SUPPLIER
                </Typography>
            </Button>
        );
    }, []);

    const columns = useMemo(
        () => [
            { label: 'Supplier Name', value: 'name' },
            {
                label: 'Created By',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            { label: 'Country', value: 'country' },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={suppliers}
                    rowsPerPage={5}
                    searchColumns={['name', 'country']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(SuppliersList);
