import { useMemo, memo } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Autocomplete,
} from '@mui/material';
import StringUtils from '@app/utils/StringUtils';

export type Option = {
    value: any;
    label: string;
};

type IProps = {
    variant?: 'filled' | 'outlined' | 'standard';
    label?: string;
    title?: string;
    selected?: Option[];
    onChange: Function;
    size?: 'medium' | 'small';
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    index?: number;
    multiple?: boolean;
    options?: Option[];
    error?: boolean;
};

const SearcheableSelectInput = (props: IProps) => {
    const {
        variant = 'outlined',
        label,
        title,
        selected,
        options = [],
        onChange,
        size = 'medium',
        required = false,
        readonly = false,
        disabled = false,
        multiple = false,
        error = false,
        index = 0,
    } = props;

    const hasTitle = useMemo(
        () => !StringUtils.isUndefinedOrWhiteSpace(title),
        [title]
    );

    const handleChange = (_: any, value: Option[] | Option | null) => {
        onChange(value);
    };

    return (
        <FormControl variant={variant} size={size} fullWidth>
            {hasTitle ? <InputLabel>{title}</InputLabel> : <></>}
            <Autocomplete
                key={`autocomplete-multiple-${index}--input`}
                fullWidth
                value={selected}
                onChange={handleChange}
                multiple={multiple}
                options={options}
                readOnly={readonly}
                disabled={disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant={variant}
                        label={label}
                        key={`autocomplete-multiple-${index}--input-item`}
                        required={required}
                    />
                )}
            />
        </FormControl>
    );
};

export default memo(SearcheableSelectInput);
