import { createTheme } from '@mui/material/styles';
import { shadows } from '@mui/system';

type Shadows = [
    'none',
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
];

export const colors = {
    'Info-RHIM': '#61C7FA',
    'InfoLightBlue-RHIM': '#DAEDFF',
    'InfoLighterBlue-RHIM': '#DDF4FF',
    'Blue-RHIM': '#003262',
    'ChildMenu-RHIM': 'rgb(0, 0, 0, 0.04)',
    'WarningYellow-RHIM': '#FEFCE6',
    'Yellow-RHIM': '#F6BC26',
    'AlertSuccessGreen-RHIM': '#E4F8E9',
    'Green-RHIM': '#00CC66',
    'ErrorRed-RHIM': '#FFEBED',
    'Red-RHIM': '#ED0909',
    'Orange-RHIM': '#ED6612',
    'BlackOverlay-RHIM': '#262824',
    'Black-RHIM': '#262824',
    'DarkGrey-RHIM': '#3D5567',
    'Grey-RHIM': '#929898',
    'Grey40-RHIM': '#B1BBC2',
    'Grey20-RHIM': '#D8DDE1',
    'Grey10-RHIM': '#ECEEF0',
    'Grey5-RHIM': '#F7F8FA',
    'White-RHIM': '#FFFFFF',
    none: 'transparent',
};

shadows({});
const customShadows = shadows.filterProps.splice(
    0,
    5,
    ...[
        'none',
        '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)',
        '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.2)',
        '0px 0px 8px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2)',
        '0px 0px 12px rgba(0, 0, 0, 0.2), 0px 8px 16px rgba(0, 0, 0, 0.2)',
        '0px 0px 16px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.2)',
    ]
) as Shadows;

const typography = {
    fontFamily: `"Roboto", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
};

const palette = {
    primary: {
        main: colors['Red-RHIM'],
        light: colors['Red-RHIM'],
        dark: colors['Red-RHIM'],
    },
    secondary: {
        main: colors['Blue-RHIM'],
        light: colors['Blue-RHIM'],
        dark: colors['Blue-RHIM'],
    },
    warning: {
        main: colors['Yellow-RHIM'],
        light: colors['Yellow-RHIM'],
        dark: colors['Yellow-RHIM'],
    },
    info: {
        main: colors['Info-RHIM'],
        light: colors['Info-RHIM'],
        dark: colors['Info-RHIM'],
    },
    sucess: {
        main: colors['Green-RHIM'],
        light: colors['Green-RHIM'],
        dark: colors['Green-RHIM'],
    },
    error: {
        main: colors['Red-RHIM'],
        light: colors['Red-RHIM'],
        dark: colors['Red-RHIM'],
    },
    text: {
        primary: colors['Black-RHIM'],
        secondary: colors['White-RHIM'],
        disabled: colors['Grey20-RHIM'],
    },
    background: {
        default: colors['White-RHIM'],
        paper: colors['White-RHIM'],
    },
    action: {
        disabledOpacity: 0.3,
        disabledBackground: colors['Grey10-RHIM'],
    },
    divider: colors['Grey20-RHIM'],
};

export const theme = createTheme({
    typography,
    palette,
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: colors['White-RHIM'],
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    backgroundColor: colors['White-RHIM'],
                    margin: '8px',
                    borderRadius: '4px',
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: colors['Grey-RHIM'],
                    'ol li:last-child a': {
                        color: colors['Blue-RHIM'],
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: '40px',
                    textAlign: 'center',
                    boxShadow: shadows.filterProps.at(2),
                    borderRadius: '4px',
                },
                containedPrimary: {
                    backgroundColor: colors['Red-RHIM'],
                    color: colors['White-RHIM'],
                },
                outlinedPrimary: {
                    borderColor: colors['Red-RHIM'],
                    backgroundColor: colors['none'],
                    color: colors['Red-RHIM'],
                },
                containedSecondary: {
                    backgroundColor: colors['Blue-RHIM'],
                    color: colors['White-RHIM'],
                },
                outlinedSecondary: {
                    borderColor: colors['Blue-RHIM'],
                    backgroundColor: colors['none'],
                    color: colors['Blue-RHIM'],
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: colors['Grey-RHIM'],
                    textDecoration: 'none',
                    fontWeight: 400,
                    fontSize: '21px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '18px',
                },
                body2: {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '18px',
                    color: colors['Blue-RHIM'],
                },
                h1: {
                    color: colors['Red-RHIM'],
                    fontWeight: 700,
                },
                h2: {
                    color: colors['Blue-RHIM'],
                    fontWeight: 700,
                    fontSize: '20px',
                },
                h3: {
                    color: colors['Red-RHIM'],
                    fontWeight: 700,
                },
                h4: {
                    color: colors['Red-RHIM'],
                    fontWeight: 700,
                },
                h5: {
                    color: colors['Red-RHIM'],
                    fontWeight: 700,
                },
                h6: {
                    color: colors['Red-RHIM'],
                    fontWeight: 700,
                },
                overline: {
                    color: colors['Grey40-RHIM'],
                    fontWeight: 300,
                },
                subtitle1: {
                    color: colors['DarkGrey-RHIM'],
                    fontWeight: 600,
                    fontSize: '14px',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    backgroundColor: colors['Grey10-RHIM'],
                    padding: '8px 8px 8px 8px',
                    alignItems: 'flex-end',
                    height: '40px',
                    boxShadow: shadows.filterProps.at(0),
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: 600,
                    color: colors['DarkGrey-RHIM'],
                    height: '21px',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    height: '40px',
                    borderRadius: '4px',
                    color: colors['Black-RHIM'],
                    fontSize: '14px',
                    fontWeight: 400,
                },
            },
            defaultProps: {
                placeholder: colors['Grey40-RHIM'],
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    '&:not(:last-child)': {
                        borderBottom: 0,
                    },
                    '&:before': {
                        display: 'none',
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    backgroundColor: colors['Grey10-RHIM'],
                    flexDirection: 'row-reverse',
                },
                expandIconWrapper: {
                    ':after': {
                        transform: 'rotate(180deg)',
                    },
                },
                content: {
                    marginLeft: '8px',
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '16px',
                    borderTop: `1px solid ${colors['Grey20-RHIM']}`,
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    borderLeft: '4px solid',
                    borderRadius: '4px',
                    color: colors['DarkGrey-RHIM'],
                    backgroundColor: colors['White-RHIM'],
                },
            },
            variants: [
                {
                    props: {
                        severity: 'error',
                    },
                    style: {
                        backgroundColor: colors['ErrorRed-RHIM'],
                        borderColor: colors['Red-RHIM'],
                        color: colors['Red-RHIM'],
                    },
                },
                {
                    props: {
                        severity: 'warning',
                    },
                    style: {
                        backgroundColor: colors['WarningYellow-RHIM'],
                        borderColor: colors['Yellow-RHIM'],
                    },
                },
                {
                    props: {
                        severity: 'info',
                    },
                    style: {
                        borderColor: colors['InfoLightBlue-RHIM'],
                    },
                },
                {
                    props: {
                        severity: 'success',
                    },
                    style: {
                        borderColor: colors['Green-RHIM'],
                    },
                },
            ],
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    border: `solid 1px ${colors['Grey20-RHIM']}`,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 700,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: colors['Blue-RHIM'],
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                thumb: {
                    border: `1px solid ${colors['Grey40-RHIM']}`,
                },
            },
        },
    },
    shadows: customShadows,
});
