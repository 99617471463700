import { SupplierModel } from '@app/models/SupplierModel';
import { SupplierService } from '@app/services/SupplierService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_SUPPLIER_REQUEST = `${window.env.__REDUX_PATH__}/supplier/GET_SUPPLIER_REQUEST`;
const GET_SUPPLIER_SUCCESS = `${window.env.__REDUX_PATH__}/supplier/GET_SUPPLIER_SUCCESS`;
const GET_SUPPLIER_FAILURE = `${window.env.__REDUX_PATH__}/supplier/GET_SUPPLIER_FAILURE`;

export interface SupplierState extends BaseState {
    suppliers: SupplierModel[];
}

// Action Types
interface GetSupplierRequestAction {
    type: typeof GET_SUPPLIER_REQUEST;
}

interface GetSupplierSuccessAction {
    type: typeof GET_SUPPLIER_SUCCESS;
    payload?: SupplierModel[];
}

interface GetSupplierFailureAction {
    type: typeof GET_SUPPLIER_FAILURE;
    payload?: string;
}

type SupplierActionTypes =
    | GetSupplierRequestAction
    | GetSupplierSuccessAction
    | GetSupplierFailureAction;

// Reducer
const initialState: SupplierState = {
    suppliers: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getSupplierRequestReducer = (state: SupplierState): SupplierState => {
    return {
        ...state,
        suppliers: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getSupplierSuccessReducer = (
    state: SupplierState,
    action: GetSupplierSuccessAction
): SupplierState => {
    return {
        ...state,
        suppliers: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getSupplierFailureReducer = (
    state: SupplierState,
    action: GetSupplierFailureAction
): SupplierState => {
    return {
        ...state,
        suppliers: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: SupplierState = initialState,
    action: SupplierActionTypes
): SupplierState => {
    switch (action.type) {
        case GET_SUPPLIER_REQUEST:
            return getSupplierRequestReducer(state);
        case GET_SUPPLIER_SUCCESS:
            return getSupplierSuccessReducer(
                state,
                action as GetSupplierSuccessAction
            );
        case GET_SUPPLIER_FAILURE:
            return getSupplierFailureReducer(
                state,
                action as GetSupplierFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getSupplierRequest = (): GetSupplierRequestAction => {
    return {
        type: GET_SUPPLIER_REQUEST,
    };
};

export const getSupplierSuccess = (
    payload: SupplierModel[]
): GetSupplierSuccessAction => {
    return {
        type: GET_SUPPLIER_SUCCESS,
        payload,
    };
};

export const getSupplierFailure = (
    payload: string
): GetSupplierFailureAction => {
    return {
        type: GET_SUPPLIER_FAILURE,
        payload,
    };
};

export const getAllSupplier = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getSupplierRequest());

        try {
            const service = new SupplierService();
            const response = await service.getAll();

            dispatch(getSupplierSuccess(response));
            dispatch(
                showMessage({
                    message: 'Supplier load successfully!',
                    title: 'Supplier Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getSupplierFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Supplier could not be load!',
                        title: 'Supplier Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getSupplier = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getSupplierRequest());

        try {
            const service = new SupplierService();
            const response = await service.get(params);

            dispatch(getSupplierSuccess(response));
            dispatch(
                showMessage({
                    message: 'Supplier load successfully!',
                    title: 'Supplier Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getSupplierFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Supplier could not be load!',
                        title: 'Supplier Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
