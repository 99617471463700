import { useState, memo } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';
import Header from './organisms/Header';
import Sidebar from './organisms/Sidebar';
import MainContent from './organisms/MainContent';
import SnackbarContext from './contexts/SnackbarContext';
import { loginRequest } from './authConfig';
import { InteractionStatus } from '@azure/msal-browser';
import BackdropContext from './contexts/BackdropContext';
import './App.css';
import { useAppSelector } from './hooks';
import { RootState } from './store';

function App() {
    const { loading, message } = useAppSelector((state: RootState) => state);
    const [open, setOpen] = useState(false);
    const [logged, setLogged] = useState(false);

    const { instance, accounts, inProgress } = useMsal();

    const acquireToken = () => {
        if (inProgress === InteractionStatus.None)
            if (accounts.length === 0) {
                instance.acquireTokenRedirect(loginRequest).catch((e) => {
                    console.error(e);
                });
            } else {
                instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response) => {
                        axios.defaults.headers.common = {
                            authorization: `Bearer ${response.accessToken}`,
                        };
                        setLogged(true);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
    };

    acquireToken();

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (401 === error.response.status) {
                acquireToken();
            } else {
                return Promise.reject(error);
            }
        }
    );

    return (
        <Box className='App'>
            <BackdropContext loading={loading}>
                <SnackbarContext message={message}>
                    <BrowserRouter>
                        <Sidebar isExpand={open} onExpand={setOpen}>
                            <Header isMenuOpen={open}>
                                {logged ? (
                                    <AuthenticatedTemplate>
                                        <MainContent isMenuOpen={open} />
                                    </AuthenticatedTemplate>
                                ) : (
                                    <></>
                                )}
                            </Header>
                        </Sidebar>
                    </BrowserRouter>
                </SnackbarContext>
            </BackdropContext>
        </Box>
    );
}

export default memo(App);
