import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllSupplier } from '@app/store/supplier/reducer';

export const useSupplier = (): void => {
    const { isFetching, lastUpdated, suppliers } = useAppSelector(
        (state) => state.supplier
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && suppliers.length === 0 && !lastUpdated) {
            dispatch(getAllSupplier());
        }
    }, []);
};
