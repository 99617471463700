import TabContainer from '@app/organisms/TabContainer'
import CoatingAreatypeList from '@app/organisms/Tabs/Admin/Kiln/CoatingAreatypeList'
import KilnStatusList from '@app/organisms/Tabs/Admin/Kiln/KilnStatusList'
import ProcessTypesList from '@app/organisms/Tabs/Admin/Kiln/ProcessTypesList'
import StressAreaTypeList from '@app/organisms/Tabs/Admin/Kiln/StressAreaTypeList'

const KilnPage = () => {
  const tabs = [
    {
      component: <KilnStatusList />,
      label: 'KILN STATUS',
    },
    {
      component: <ProcessTypesList />,
      label: 'PROCESS TYPE',
    },
    {
      component: <StressAreaTypeList />,
      label: 'STRESS AREA TYPE',
    },
    {
      component: <CoatingAreatypeList />,
      label: 'COATING AREA TYPE',
    },
  ]
  return <TabContainer areaTitle={'Admin Area Kiln Profile'} tabsList={tabs} containsButtonExportExcel={true}/>
}
export default KilnPage
