import axios, { AxiosInstance, AxiosResponse } from 'axios';

export abstract class RepositoryBase<T> {
    private readonly baseURL: string = window.env.__CLIP_API__;
    private basePath: string = '';
    protected api: AxiosInstance;

    public constructor(basePath: string) {
        this.basePath = basePath;
        this.api = axios.create({
            headers: axios.defaults.headers.common,
            baseURL: `${this.baseURL}${this.basePath}`,
        });
    }

    public async getAll(): Promise<T[]> {
        return this.handleResponse(await this.api.get(''));
    }

    public async get<P>(params: P): Promise<T[]> {
        return this.handleResponse(await this.api.get('', { params }));
    }

    public async getById(id: string): Promise<T> {
        return this.handleResponse(await this.api.get(`/${id}`));
    }

    public async update(entity: T): Promise<T> {
        return this.handleResponse(await this.api.put('', entity));
    }

    public async delete(id: string): Promise<T> {
        return this.handleResponse(await this.api.delete(`/${id}`));
    }

    public async create(entity: T): Promise<T> {
        return this.handleResponse(await this.api.post('', entity));
    }

    protected handleResponse(response: AxiosResponse) {
        if (response.status >= 500) {
            throw new Error('Internal server error');
        }

        if (response.status >= 400) {
            throw new Error('Could not process the request');
        }

        return response.data;
    }
}
