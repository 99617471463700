import { Typography, Box } from '@mui/material';
import SelectInput from '@app/atoms/SelectInput';
import { colors } from '@app/theme';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { memo, useMemo } from 'react';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { UserSettingsService } from '@app/services/UserSettingsService';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { getAllUserSettings } from '@app/store/userSettings/reducer';

const UserSettingsPage = () => {
    const dispatch = useAppDispatch();
    const { settings } = useAppSelector((state) => state.userSettings);

    useUserSettings();

    const uom = useMemo(
        () =>
            settings.find((s) => s.settingsName === 'UOM')?.settingsValue ??
            'METRIC',
        [settings]
    );

    const service = useMemo(
        () => new UserSettingsService(),
        []
    );

    const options = useMemo(
        () => [
            {
                value: 'METRIC',
                label: 'Metric',
            },
            {
                value: 'IMPERIAL',
                label: 'Imperial',
            },
        ],
        []
    );

    return (
        <>
            <Typography variant='h5' color='secondary'>
                User Settings
            </Typography>
            <Box
                sx={{
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    borderRadius: '4px',
                    width: '640px',
                    height: '68px',
                    display: 'grid',
                    gridTemplateColumns: '50% 50%',
                    padding: '8px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography variant='body1'>Unit of Measurement</Typography>
                <SelectInput
                    value={uom}
                    options={options}
                    onChange={async (value: string) => {
                        try {
                            dispatch(setLoading(true));
                            await service.create({
                                settingsValue: value,
                                settingsName: 'UOM',
                                id: '',
                                createdBy: '',
                                createdAt: new Date(),
                            });
                            dispatch(getAllUserSettings());
                            dispatch(
                                setMessage({
                                    color: 'success',
                                    message:
                                        'Successfully created User Settings!',
                                    title: 'Success',
                                    open: true,
                                })
                            );
                        } catch (ex) {
                            dispatch(
                                setMessage({
                                    color: 'error',
                                    message: 'Could not created User Settings!',
                                    title: 'Error',
                                    open: true,
                                })
                            );
                        }
                        dispatch(setLoading(false));
                    }}
                />
            </Box>
        </>
    );
};

export default memo(UserSettingsPage);
