import { memo, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import KilnBuilderHeader from '../KilnBuilderHeader';
import NumberInput from '@app/atoms/NumberInput';
import { colors } from '@app/theme';
import { SectionModel } from '@app/models/SectionModel';
import NumberUtils from '@app/utils/NumberUtils';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { getIsMetric } from '@app/utils/ConvertUtils';

type IProps = {
    index: number;
    isConic: boolean;
    handleChange: Function;
    section: SectionModel;
    length: number;
    diameter: number;
    diameterIndex: number;
    handleDeletion: Function;
    handleAddition: Function;
};

const KilnSection = (props: IProps) => {

    useUserSettings();

    const {
        index,
        isConic,
        handleChange,
        section,
        length,
        diameter,
        diameterIndex,
        handleDeletion,
        handleAddition,
    } = props;

    const errorMapping = useMemo(
        () => ({
            diameterLower: "The diameter can't be lower than or equals 0.",
            startLower: "The start can't be lower than 0.",
            endLower: "The end can't be lower than or equals 0.",
            endStart: "The end can't be lower than the start.",
            length: "The start/end can't be higher than the kiln length.",
        }),
        []
    );

    const handleChangeStart = (value: number) => {
        const newValue = {
            ...section,
            start: value,
        };
        handleChange(newValue, index);
    };

    const handleChangeEnd = (value: number) => {
        const newValue = {
            ...section,
            end: value,
        };
        handleChange(newValue, index);
    };

    const handleChangeDiameter = (value: number) => {
        const newValue = { ...section, diameter: value };

        if (!isConic) newValue.diameter2 = value;

        handleChange(newValue, index);
    };

    const handleChangeDiameter2 = (value: number) => {
        const newValue = {
            ...section,
            diameter2: value,
        };
        handleChange(newValue, index);
    };

    const renderDiameter2 = useCallback(
        () =>
            isConic ? (
                <Grid item margin='0'>
                    <NumberInput
                        label={`Diameter ${diameterIndex}`}
                        value={section.diameter2}
                        placeholder={NumberUtils.getUomPlaceholder(getIsMetric())}
                        onChange={handleChangeDiameter2}
                        error={section.diameter2 <= 0}
                        helperText={errorMapping.diameterLower}
                        required
                    />
                </Grid>
            ) : (
                <></>
            ),
        [
            section.diameter2,
            isConic,
            diameterIndex,
            diameter,
            errorMapping.diameterLower,
            getIsMetric(),
        ]
    );

    return (
        <Box sx={{ margin: '8px 0' }}>
            <KilnBuilderHeader
                title={
                    isConic
                        ? `Conical ${index + 1}`
                        : `Non-Conical ${index + 1}`
                }
                hasAdd
                hasDelete
                index={index}
                handleDeletion={handleDeletion}
                handleAddition={() => {
                    handleAddition(isConic);
                }}
            />
            <Grid
                container
                margin='0'
                sx={{
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    borderTop: 0,
                    borderRadius: '0 0 4px 4px',
                }}
            >
                <Grid
                    item
                    container
                    display='grid'
                    gap='8px'
                    gridTemplateColumns={
                        isConic ? 'calc(50% - 4px) calc(50% - 4px)' : ''
                    }
                >
                    <Grid item margin='0'>
                        <NumberInput
                            label={`Diameter ${
                                isConic ? diameterIndex - 1 : diameterIndex
                            }`}
                            value={section.diameter}
                            placeholder={NumberUtils.getUomPlaceholder(
                                getIsMetric()
                            )}
                            onChange={handleChangeDiameter}
                            error={section.diameter <= 0}
                            helperText={errorMapping.diameterLower}
                            required
                        />
                    </Grid>
                    {renderDiameter2()}
                </Grid>
                <Grid
                    item
                    container
                    display='grid'
                    gap='8px'
                    gridTemplateColumns='calc(50% - 4px) calc(50% - 4px)'
                >
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: Start'
                            value={section.start}
                            onChange={handleChangeStart}
                            placeholder={NumberUtils.getUomPlaceholder(
                                getIsMetric()
                            )}
                            error={section.start < 0}
                            helperText={
                                section.start < 0
                                    ? errorMapping.startLower
                                    : section.start > length
                                    ? errorMapping.length
                                    : ''
                            }
                            required
                        />
                    </Grid>
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: End'
                            value={section.end}
                            onChange={handleChangeEnd}
                            placeholder={NumberUtils.getUomPlaceholder(
                                getIsMetric()
                            )}
                            error={
                                section.start > section.end ||
                                section.end <= 0 ||
                                section.end > length
                            }
                            helperText={
                                section.end <= 0
                                    ? errorMapping.endLower
                                    : section.end > length
                                    ? errorMapping.length
                                    : errorMapping.endStart
                            }
                            required
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(KilnSection);
