import { KilnStatusModel } from '@app/models/KilnStatusModel';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { KilnStatusService } from '@app/services/KilnStatusService';
import { Add, Edit } from '@mui/icons-material';
import Modal from '@app/molecules/Modal';
import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useCallback, memo, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useKilnStatus } from '@app/hooks/useKilnStatus';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { getAllKilnStatus } from '@app/store/kilnStatus/reducer';

const INITIAL_STATE: KilnStatusModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    deletedAt: new Date(),
    deletedBy: '',
    updatedAt: new Date(),
    updatedBy: '',
    name: '',
    description: '',
    code: '#000000',
};

const KilnStatusList = () => {
    const dispatch = useAppDispatch();
    const { kilnStatus } = useAppSelector((state) => state.kilnStatus);
    useKilnStatus();

    const kilnStatusesService = useMemo(
        () => new KilnStatusService(),
        []
    );

    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingKilnStatus, setEditingKilnStatus] = useState<KilnStatusModel>(
        {
            ...INITIAL_STATE,
        }
    );

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingKilnStatus({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingKilnStatus({
                ...editingKilnStatus,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await kilnStatusesService.create({
                    ...editingKilnStatus,
                    description: `Status ${editingKilnStatus.name}`,
                    createdAt: new Date(),
                });
                dispatch(getAllKilnStatus());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingKilnStatus({
                ...editingKilnStatus,
                code: value,
            });
        };

        return (
            <Modal
                title='New Kiln Status'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
            >
                <TextField
                    value={editingKilnStatus?.name}
                    label='Kiln Status'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <Grid container item margin='0' sx={{ width: '367px' }}>
                    <TextField
                        value={editingKilnStatus?.code}
                        label='Color code'
                        type='color'
                        size='small'
                        sx={{ margin: '8px 0', width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleCodeChange}
                    />
                </Grid>
            </Modal>
        );
    }, [editingKilnStatus, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingKilnStatus({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingKilnStatus({
                ...editingKilnStatus,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await kilnStatusesService.update({
                    ...editingKilnStatus,
                });
                dispatch(getAllKilnStatus());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await kilnStatusesService.delete(editingKilnStatus.id);
                dispatch(getAllKilnStatus());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingKilnStatus({
                ...editingKilnStatus,
                code: value,
            });
        };

        return (
            <Modal
                title='Edit Kiln Status'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
            >
                <TextField
                    value={editingKilnStatus?.name}
                    label='Kiln Status'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <Grid container item margin='0' sx={{ width: '367px' }}>
                    <TextField
                        value={editingKilnStatus?.code}
                        label='Color code'
                        type='color'
                        size='small'
                        sx={{ margin: '8px 0', width: '100%' }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleCodeChange}
                    />
                </Grid>
            </Modal>
        );
    }, [editingKilnStatus, showModalEditing]);

    const getEditAction = useCallback((x: KilnStatusModel) => {
        const handleClick = () => {
            setEditingKilnStatus(x);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Kiln Status'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((x: KilnStatusModel) => {
        const isChecked = x.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await kilnStatusesService.update({ ...x, enabled: !isChecked });
                dispatch(getAllKilnStatus());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable Kiln Status'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW KILN STATUS
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Kiln Status', value: 'name' },
            {
                label: 'Created by',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={kilnStatus}
                    rowsPerPage={5}
                    searchColumns={['name', 'createdBy']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(KilnStatusList);
