import { BrandModel } from '@app/models/BrandModel';
import { BrandService } from '@app/services/BrandService';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { Add, Edit } from '@mui/icons-material';
import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import Modal from '@app/molecules/Modal';
import { colors } from '@app/theme';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import StringUtils from '@app/utils/StringUtils';
import NumberUtils from '@app/utils/NumberUtils';
import { useBrandName } from '@app/hooks/useBrandName';
import { useMPG } from '@app/hooks/useMPG';
import { useSupplier } from '@app/hooks/useSupplier';
import { getAllBrands } from '@app/store/brandName/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';

const INITIAL_STATE: BrandModel = {
    id: '',
    enabled: true,
    idmpg: '',
    idSupplier: '',
    createdAt: new Date(),
    createdBy: '',
    name: '',
    density: 0,
    code: '#000000',
};

const BrandsList = () => {
    useBrandName();
    useMPG();
    useSupplier();

    const dispatch = useAppDispatch();
    const { brands } = useAppSelector((state) => state.brandName);
    const { MPGs } = useAppSelector((state) => state.mpg);
    const { suppliers } = useAppSelector((state) => state.supplier);

    const brandsService = useMemo(() => new BrandService(), []);

    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingBrand, setEditingBrand] = useState<BrandModel>({
        ...INITIAL_STATE,
    });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingBrand({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                name: value,
            });
        };

        const handleMPGChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                idmpg: value,
            });
        };

        const handleSupplierChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                idSupplier: value,
            });
        };

        const handleDensityMetricChange = ({ target: { value } }: any) => {
            if (
                NumberUtils.isValidNumber(value) &&
                value !== editingBrand.density
            )
                setEditingBrand({
                    ...editingBrand,
                    density: NumberUtils.getNumber(value),
                });
        };

        const handleDensityImperialChange = ({ target: { value } }: any) => {
            if (
                NumberUtils.isValidNumber(value) &&
                value !== editingBrand.density
            )
                setEditingBrand({
                    ...editingBrand,
                    density: NumberUtils.getNumberMetric(value, 'lb/ft³') ?? 0,
                });
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                code: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await brandsService.create({
                    ...editingBrand,
                });
                dispatch(getAllBrands());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.idSupplier) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.idmpg) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.code) ||
            !NumberUtils.isValidNumber(editingBrand.density);

        return (
            <Modal
                title='Create Brand Name'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
                confirmDisabled={isConfirmDisabled()}
            >
                <Grid container margin='0' sx={{ width: '368px' }}>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <TextField
                            value={editingBrand?.name}
                            label='Brand name'
                            size='small'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleNameChange}
                        />
                    </Grid>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <TextField
                            value={editingBrand?.code}
                            label='Color code'
                            size='small'
                            type='color'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleCodeChange}
                        />
                    </Grid>
                    <Grid
                        item
                        margin='0'
                        marginRight='8px'
                        sx={{ width: '180px' }}
                    >
                        <TextField
                            value={editingBrand?.density}
                            label='Material density/Metric'
                            placeholder='[g/cm³] = [ton/m³]'
                            size='small'
                            type='number'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDensityMetricChange}
                        />
                    </Grid>
                    <Grid item margin='0' sx={{ width: '180px' }}>
                        <TextField
                            value={
                                editingBrand.density !== undefined
                                    ? NumberUtils.getNumberImperial(
                                          editingBrand?.density,
                                          'ton/m³'
                                      ).toFixed(2)
                                    : ''
                            }
                            label='Material density/Imperial'
                            placeholder='[lb/ft³]'
                            size='small'
                            type='number'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDensityImperialChange}
                        />
                    </Grid>
                    <Grid container item margin='8px 0' sx={{ width: '367px' }}>
                        <FormControl fullWidth>
                            <InputLabel
                                variant='outlined'
                                htmlFor='supplierCreate'
                                sx={{ backgroundColor: colors['White-RHIM'] }}
                                shrink
                            >
                                Supplier
                            </InputLabel>
                            <Select
                                value={editingBrand?.idSupplier}
                                size='small'
                                label='Supplier'
                                sx={{ margin: '0', width: '100%' }}
                                onChange={handleSupplierChange}
                                inputProps={{
                                    id: 'supplierCreate',
                                    variant: 'outlined',
                                    shrink: true,
                                }}
                            >
                                {suppliers.map((supplier) => (
                                    <MenuItem value={supplier.id}>
                                        {supplier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item margin='8px 0' sx={{ width: '367px' }}>
                        <FormControl fullWidth>
                            <InputLabel
                                variant='outlined'
                                htmlFor='mpgCreate'
                                sx={{ backgroundColor: colors['White-RHIM'] }}
                                shrink
                            >
                                MPG
                            </InputLabel>
                            <Select
                                value={editingBrand?.idmpg}
                                label='MPG'
                                size='small'
                                sx={{ margin: '0', width: '100%' }}
                                onChange={handleMPGChange}
                                inputProps={{
                                    id: 'mpgCreate',
                                    variant: 'outlined',
                                    shrink: true,
                                }}
                            >
                                {MPGs.map((MPG) => (
                                    <MenuItem value={MPG.id}>
                                        {MPG.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Modal>
        );
    }, [editingBrand, showModalCreating, suppliers, MPGs]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingBrand({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await brandsService.update({
                    ...editingBrand,
                });
                dispatch(getAllBrands());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await brandsService.delete(editingBrand.id);
                dispatch(getAllBrands());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                name: value,
            });
        };

        const handleMPGChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                idmpg: value,
            });
        };

        const handleSupplierChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                idSupplier: value,
            });
        };

        const handleDensityMetricChange = ({ target: { value } }: any) => {
            if (
                NumberUtils.isValidNumber(value) &&
                value !== editingBrand.density
            )
                setEditingBrand({
                    ...editingBrand,
                    density: NumberUtils.getNumber(value),
                });
        };

        const handleDensityImperialChange = ({ target: { value } }: any) => {
            if (
                NumberUtils.isValidNumber(value) &&
                value !== editingBrand.density
            )
                setEditingBrand({
                    ...editingBrand,
                    density: NumberUtils.getNumberMetric(value, 'lb/ft³') ?? 0,
                });
        };

        const handleCodeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingBrand({
                ...editingBrand,
                code: value,
            });
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.idSupplier) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.idmpg) ||
            StringUtils.isUndefinedOrWhiteSpace(editingBrand.code) ||
            !NumberUtils.isValidNumber(editingBrand.density);

        return (
            <Modal
                title='Edit Brand Name'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
                confirmDisabled={isConfirmDisabled()}
            >
                <Grid container margin='0' sx={{ width: '368px' }}>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <TextField
                            value={editingBrand?.name}
                            label='Brand name'
                            size='small'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleNameChange}
                        />
                    </Grid>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <TextField
                            value={editingBrand?.code}
                            label='Color code'
                            type='color'
                            size='small'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleCodeChange}
                        />
                    </Grid>
                    <Grid
                        item
                        margin='0'
                        marginRight='8px'
                        sx={{ width: '180px' }}
                    >
                        <TextField
                            value={
                                editingBrand.density !== undefined
                                    ? +editingBrand?.density.toFixed(2)
                                    : editingBrand?.density
                            }
                            label='Material density/Metric'
                            placeholder='[g/cm³] = [ton/m³]'
                            size='small'
                            type='number'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDensityMetricChange}
                        />
                    </Grid>
                    <Grid item margin='0' sx={{ width: '180px' }}>
                        <TextField
                            value={
                                editingBrand.density !== undefined
                                    ? NumberUtils.getNumberImperial(
                                          editingBrand?.density,
                                          'ton/m³'
                                      ).toFixed(2)
                                    : ''
                            }
                            label='Material density/Imperial'
                            placeholder='[lb/ft³]'
                            size='small'
                            type='number'
                            sx={{ margin: '8px 0', width: '100%' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleDensityImperialChange}
                        />
                    </Grid>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <FormControl fullWidth>
                            <InputLabel
                                variant='outlined'
                                htmlFor='supplierEdit'
                                sx={{ backgroundColor: colors['White-RHIM'] }}
                                shrink
                            >
                                Supplier
                            </InputLabel>
                            <Select
                                value={editingBrand?.idSupplier}
                                size='small'
                                label='Supplier'
                                sx={{ margin: '8px 0', width: '100%' }}
                                onChange={handleSupplierChange}
                                inputProps={{
                                    id: 'supplierEdit',
                                    variant: 'outlined',
                                    shrink: true,
                                }}
                            >
                                {suppliers.map((supplier) => (
                                    <MenuItem value={supplier.id}>
                                        {supplier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container item margin='0' sx={{ width: '367px' }}>
                        <FormControl fullWidth>
                            <InputLabel
                                sx={{ backgroundColor: colors['White-RHIM'] }}
                                variant='outlined'
                                htmlFor='mpgEdit'
                                shrink
                            >
                                MPG
                            </InputLabel>
                            <Select
                                value={editingBrand?.idmpg}
                                label='MPG'
                                size='small'
                                sx={{ margin: '8px 0', width: '100%' }}
                                onChange={handleMPGChange}
                                inputProps={{
                                    id: 'mpgEdit',
                                    variant: 'outlined',
                                    shrink: true,
                                }}
                            >
                                {MPGs.map((MPG) => (
                                    <MenuItem value={MPG.id}>
                                        {MPG.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Modal>
        );
    }, [editingBrand, showModalEditing, suppliers, MPGs]);

    const getEditAction = useCallback((brand: BrandModel) => {
        const handleClick = () => {
            setEditingBrand(brand);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Brand Name'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((brand: BrandModel) => {
        const isChecked = brand.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await brandsService.update({
                    ...brand,
                    enabled: !isChecked,
                });
                dispatch(getAllBrands());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable Brand Name'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW BRAND NAME
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Brand Name', value: 'name' },
            {
                label: 'Created By',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Material Density',
                subtitle: '[g/cm³] = [ton/m³]',
                value: 'density',
                handle: (value: number) => +value.toFixed(2),
            },
            {
                label: 'Material Density',
                subtitle: '[lb/ft³]',
                value: 'density',
                handle: (value: number) =>
                    NumberUtils.getNumberImperial(value, 'ton/m³').toFixed(2),
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={brands}
                    rowsPerPage={5}
                    searchColumns={['name']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(BrandsList);
