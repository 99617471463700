import { useCustomer } from '@app/hooks/useCustomer';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import LiningChartDrawing, {
    LiningDrawingSlice,
} from '@app/molecules/LiningChartDrawing';
import LiningChartTable from '@app/molecules/LiningChartTable';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Link,
    Button,
    Input,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import { useZone } from '@app/hooks/useZone';
import { useMPG } from '@app/hooks/useMPG';
import { useSupplier } from '@app/hooks/useSupplier';
import { useBrandName } from '@app/hooks/useBrandName';
import { useShapeStandard } from '@app/hooks/useShapeStandard';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CampaignModel } from '@app/models/CampaignModel';
import { CampaignService } from '@app/services/CampaignService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from '@app/theme';
import { LiningDataModel } from '@app/models/LiningDataModel';
import { Check, NavigateNext } from '@mui/icons-material';
import UriUtils from '@app/utils/UriUtils';
import { LiningChartService } from '@app/services/LiningChartService';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { useMsal } from '@azure/msal-react';
import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import DateUtils from '@app/utils/DateUtils';
import NumberUtils from '@app/utils/NumberUtils';
import StringUtils from '@app/utils/StringUtils';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { getIsMetric } from '@app/utils/ConvertUtils';

type BrandMap = {
    [key: string]: { name: string; code: string };
};

const LiningChart = (kiln: KilnProfileStructureModel) => {
    useUserSettings();

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { accounts } = useMsal();

    const params = UriUtils.parseQueryString(location.search);

    const { customerId, isNew, isCopy, campaignId, kilnProfileId } = params;
    useCustomer(customerId as string);

    const INITIAL_VALUE = {
        installDate: new Date(),
        idKilnProfile: kilnProfileId as string,
        createdAt: new Date(),
        createdBy: '',
        id: '',
    };

    const { zone, mpg, supplier, brandName, shapeStandard } = useAppSelector(
        (state) => state
    );
    const [data, setData] = useState<CampaignModel>(INITIAL_VALUE);
    const [validYear, setValidYear] = useState<boolean>(true);

    useZone();
    useMPG();
    useSupplier();
    useBrandName();
    useShapeStandard();

    const [linings, setLinings] = useState<LiningDataModel[]>([]);

    const serviceLining = useMemo(() => new LiningChartService(), []);
    const serviceCampaign = useMemo(() => new CampaignService(), []);

    useEffect(() => {
        if (campaignId !== undefined && (!isNew || isCopy)) {
            const fetchCampaignData = async () => {
                dispatch(setLoading(true));
                try {
                    const response = await serviceCampaign.getById(
                        campaignId as string
                    );

                    setData(response);
                    dispatch(
                        setMessage({
                            title: 'Success',
                            message: 'Successefully loaded linings',
                            color: 'success',
                            open: true,
                        })
                    );
                } catch (ex) {
                    dispatch(
                        setMessage({
                            title: 'Error',
                            message: 'Could not load linings',
                            color: 'error',
                            open: true,
                        })
                    );
                }
                dispatch(setLoading(false));
            };
            fetchCampaignData();
        }
    }, [isNew, campaignId]);

    useEffect(() => {
        if (data !== undefined && data.liningChart !== undefined) {
            setLinings(data.liningChart);
        }
        if (data !== undefined && data.installDate !== undefined) {
            setValidYear(validateYear(data.installDate));
        }
    }, [data]);

    const breadcrumbs = useMemo(
        () => [
            <Link
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', { customerId })}
            >
                Kiln Overview
            </Link>,
            <Link
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', {
                    view: 'campaigns',
                    customerId,
                    kilnProfileId,
                })}
            >
                {kiln?.kilnName}
            </Link>,
        ],
        [kiln, customerId, kilnProfileId]
    );

    const breadcrumbsAppendNew = useMemo(
        () => [
            <Typography variant='h6' color='secondary'>
                New Lining Chart
            </Typography>,
        ],
        []
    );

    const breadcrumbsAppendEdit = useMemo(
        () => [
            <Link
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', {
                    view: 'campaigns',
                    customerId,
                    kilnProfileId,
                })}
            >
                Lining Chart
            </Link>,
            <Typography variant='h6' color='secondary'>
                {DateUtils.formatCampaignMonthYear(data?.installDate)}
            </Typography>,
        ],
        [data, customerId, kilnProfileId]
    );

    const handleLinigChartSave = async () => {
        linings.sort((a, b) => a.start - b.start);
        dispatch(setLoading(true));
        try {
            let id = '';
            if (isNew) {
                const response = await serviceCampaign.create({
                    ...data,
                    id: '',
                });
                id = response.id;
            } else {
                const response = await serviceCampaign.update(data);
                id = response.id;
            }

            if (!StringUtils.isUndefinedOrWhiteSpace(id)) {
                await serviceLining.createMultiple(
                    linings.map((lining) => ({
                        id: lining.id,
                        idCampaign: id,
                        idBrandName: lining.idBrandName,
                        idZone: lining.idZone,
                        start: +lining.start,
                        end: +lining.end,
                        brickThickness: +lining.brickThickness,
                        idShapeStandard: lining.idShapeStandard,
                        expectedLife: +lining.expectedLife,
                        createdAt: lining.createdAt,
                        createdBy: accounts[0].username,
                    }))
                );
            } else {
                throw new Error('Could not save campaign');
            }

            dispatch(
                setMessage({
                    message: '',
                    title: 'Successfully saved Lining Chart!',
                    color: 'success',
                    open: true,
                })
            );
            navigate(
                UriUtils.stringifyQueryObject('/overview', {
                    view: 'campaigns',
                    customerId,
                    kilnProfileId,
                })
            );
        } catch (e) {
            dispatch(
                setMessage({
                    message: '',
                    title: 'Could not save Lining Chart!',
                    color: 'error',
                    open: true,
                })
            );
        }
        dispatch(setLoading(false));
    };

    const brandMap: BrandMap = useMemo(
        () =>
            brandName.brands?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: { code: j.code, name: j.name },
                }),
                {}
            ),
        [brandName]
    );

    const renderDrawing = useCallback(() => {
        const kilnLength = Math.ceil(kiln?.length);
        const arrayLength = kilnLength * 5;
        const current: LiningDrawingSlice[] = new Array(arrayLength).fill({
            code: colors['Grey5-RHIM'],
            brandName: 'unknown',
        });

        if (linings) {
            linings.forEach((lining: LiningDataModel) => {
                const brand = brandMap[lining.idBrandName] ?? {};
                const length = Math.abs(lining.end - lining.start);
                const meter = Math.ceil(length * 5);

                if (NumberUtils.isValidNumber(meter)) {
                    const newSlices: LiningDrawingSlice[] = new Array(
                        meter
                    ).fill({
                        code: brand.code ?? '',
                        brandName: brand.name ?? '',
                        start: lining.start.toFixed(2),
                        end: lining.end.toFixed(2),
                        installDate: DateUtils.formatCampaignMonthYear(
                            data?.installDate
                        ),
                    });
                    const start = Math.ceil(lining.start * 5);
                    current.splice(start, meter, ...newSlices);
                }
            });
        }

        if (
            kiln.girthGear !== undefined &&
            NumberUtils.isValidNumber(kiln.girthGear)
        ) {
            const arrayIndex = Math.ceil(kiln.girthGear * 5) - 1;
            current[arrayIndex] = {
                ...current[arrayIndex],
                girthGear: kiln.girthGear.toFixed(2),
            };
        }

        if (kiln.tires !== undefined && kiln.tires.length > 0) {
            kiln.tires.forEach((tire) => {
                const arrayIndex = Math.ceil(tire.position * 5) - 1;
                current[arrayIndex] = {
                    ...current[arrayIndex],
                    tire: tire.position.toFixed(2),
                };
            });
        }
        return <LiningChartDrawing linings={current} kilnLength={kilnLength} />;
    }, [kiln, linings, brandMap, kilnProfileId, customerId]);

    const isOverriding = useMemo(() => {
        let doesNotOverride = true;
        let sortedLinings = linings.slice().sort((a, b) => a.start - b.start);
        sortedLinings.forEach((value, index) => {
            if (index > 0) {
                doesNotOverride =
                    doesNotOverride &&
                    sortedLinings[index - 1].end <= value.start;
            }
            doesNotOverride = doesNotOverride && value.end > value.start;
        });
        return !doesNotOverride;
    }, [linings]);

    const validateYear = (date: Date) => {
        const dateCopy = new Date(date);
        const dateYear = dateCopy.getFullYear();
        return dateYear >= 1900;
    };

  
    const isValid = useMemo(() => 
    {
        return (
            linings.length > 0 &&
            linings.every(
                (lining) =>
                    NumberUtils.getNumber(lining.brickThickness) > 0 &&
                NumberUtils.getNumber(lining.end) > 0 &&
                NumberUtils.getNumber(lining.start) >= 0 &&
                lining.start < lining.end &&
                NumberUtils.getNumber(lining.expectedLife) > 0 &&
                !StringUtils.isUndefinedOrWhiteSpace(lining.idBrandName) &&
                !StringUtils.isUndefinedOrWhiteSpace(lining.idZone) &&
                !StringUtils.isUndefinedOrWhiteSpace(
                    lining.idShapeStandard
                ) &&
                lining.end <= kiln.length &&
                kiln.sections
                    .filter(
                        (section) => section.diameter !== section.diameter2 &&
                            ((section.start <= lining.start &&
                                section.end > lining.start) ||
                                (section.start < lining.end &&
                                    section.end >= lining.end))
                    )
                    .every(
                        (section) => section.start >= lining.start &&
                            section.end <= lining.end
                    ) &&
                kiln.sections
                    .filter(
                        (section) => (section.start <= lining.start &&
                            section.end > lining.start) ||
                            (section.start < lining.end &&
                                section.end >= lining.end)
                    )
                    .every(
                        (section) => {
                            const valueToConvertInMorFt = getIsMetric() ? 1000 : 12;
                            const brickThicknessFT = lining.brickThickness / valueToConvertInMorFt;
                            const radius = Math.min(section.diameter, section.diameter2) / 2;
                            const brickThicknessIsLowerThanRadius = brickThicknessFT < radius;
    
                            return brickThicknessIsLowerThanRadius;
                        }
                    )
            ) &&
            !isOverriding
        );
    }, [linings, data, isOverriding]);

    return (
        <Grid container margin='0' rowGap='8px'>
            <Grid
                container
                margin='0'
                justifyContent='space-between'
                alignItems='center'
            >
                <Grid item margin='0'>
                    <Breadcrumbs
                        separator={<NavigateNext />}
                        aria-label='breadcrumb'
                    >
                        {breadcrumbs.concat(
                            isNew ? breadcrumbsAppendNew : breadcrumbsAppendEdit
                        )}
                    </Breadcrumbs>
                </Grid>
                <Grid item margin='0'>
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={!isValid || !validYear}
                        startIcon={<Check fontSize='small' />}
                        size='large'
                        onClick={handleLinigChartSave}
                    >
                        <Typography
                            sx={{
                                whiteSpace: 'nowrap',
                            }}
                        >
                            SAVE
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid container margin='0'>
                <Grid item>
                    <FormControl sx={{ width: '100%' }} variant='outlined'>
                        <InputLabel shrink>Install Date</InputLabel>
                        <Input
                            name='installDate'
                            key='filter-date-install'
                            id='date'
                            type='date'
                            value={DateUtils.formatUTCDate(data?.installDate)}
                            onChange={({ target: { value } }) => {
                                setData({
                                    ...data,
                                    installDate: new Date(
                                        `${value}T00:00:00.000`
                                    ),
                                });
                            }}
                            onBlur={({ target: { value } }) => {
                                let newInstallDate = new Date(
                                    `${value}T00:00:00.000`
                                );
                                setValidYear(validateYear(newInstallDate));
                            }}
                        />
                    </FormControl>
                    <FormControl error={!validYear}>
                        {!validYear ? (
                            <FormHelperText>
                                <b>
                                    The Install Date should be filled in dd/mm/yyyy format and should have a valid year, superior to 1900.
                                </b>
                            </FormHelperText>
                        ) : (
                            <></>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
            {renderDrawing()}
            <LiningChartTable
                isValid={isValid}
                isEdit
                campaign={data}
                zones={zone.zones}
                MPGs={mpg.MPGs}
                brands={brandName.brands}
                suppliers={supplier.suppliers}
                shapeStandards={shapeStandard.shapeStandards}
                liningData={{ linings, setLinings }}
                kilnLength={Math.ceil(kiln?.length)}
                sections={kiln.sections}
            />
        </Grid>
    );
};

export default LiningChart;
