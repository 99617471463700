import { Grid } from '@mui/material';
import { useMemo, memo } from 'react';
import { colors } from '@app/theme';
import StringUtils from '@app/utils/StringUtils';

export interface LiningDrawingSlice {
    code: string;
    brandName: string;
    start?: string;
    end?: string;
    installDate?: string;
    tire?: string;
    girthGear?: string;
}

interface LiningDrawingProps {
    linings: LiningDrawingSlice[];
    kilnLength: number;
}

const LiningChartDrawing = (props: LiningDrawingProps) => {
    const { linings, kilnLength } = props;

    const parts = useMemo(
        () => (linings.length ? new Array(kilnLength).fill(0) : []),
        [kilnLength, linings.length]
    );

    return (
        <Grid
            container
            margin='0'
            sx={{
                overflowX: 'auto',
                overflowY: 'hidden',
                minWidth: '100%',
                height: '89px',
                display: 'grid',
                gridTemplateColumns: `repeat(${kilnLength}, 1fr)`,
            }}
        >
            {parts.map((_, index) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    key={`meter-${index}`}
                >
                    <div
                        style={{
                            height: '24px',
                            minWidth: '30px',
                            fontSize: '12px',
                            fontWeight: '700',
                        }}
                        key={`meter-rule-${index}`}
                    >
                        {index}
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                        }}
                        key={`meter-slices-container-${index}`}
                    >
                        {linings
                            .slice(index * 5, index * 5 + 5)
                            .map((lining, idx) => {
                                const showLength =
                                    !StringUtils.isUndefinedOrWhiteSpace(
                                        lining.start
                                    ) &&
                                    !StringUtils.isUndefinedOrWhiteSpace(
                                        lining.end
                                    );
                                const showInstallDate =
                                    lining.installDate !== undefined;

                                let dates = showInstallDate
                                    ? lining.installDate
                                    : '';
                                dates += showInstallDate ? ' | ' : '';

                                let lengths = showLength
                                    ? `: ${lining.start} - ${lining.end}`
                                    : '';

                                const title = `${dates}${lining.brandName}${lengths}`;

                                return (
                                    <div key={`meter-slice-${idx}`}>
                                        {lining.girthGear !== undefined ? (
                                            <abbr
                                                title={`Girth Gear: ${lining.girthGear}`}
                                                key={`bar-slice-lining-drawing-${idx}-girth-gear`}
                                                style={{
                                                    backgroundColor:
                                                        colors['Grey5-RHIM'],
                                                    minWidth: '8px',
                                                    height: '48px',
                                                }}
                                            >
                                                <div
                                                    key={`bar-slice-lining-drawing-${idx}-girth-gear-inner`}
                                                    style={{
                                                        height: '8px',
                                                        minWidth: '8px',
                                                        backgroundColor:
                                                            colors[
                                                                'Black-RHIM'
                                                            ],
                                                    }}
                                                />
                                            </abbr>
                                        ) : (
                                            <div
                                                key={`bar-slice-lining-drawing-${idx}-girth-gear`}
                                                style={{
                                                    height: '4px',
                                                    minWidth: '5px',
                                                    backgroundColor:
                                                        colors['Grey5-RHIM'],
                                                }}
                                            />
                                        )}
                                        {lining.tire !== undefined ? (
                                            <abbr
                                                title={`Tire: ${lining.tire}`}
                                                key={`bar-slice-lining-drawing-${idx}-tire`}
                                                style={{
                                                    backgroundColor:
                                                        colors['Grey5-RHIM'],
                                                    minWidth: '8px',
                                                    height: '48px',
                                                }}
                                            >
                                                <div
                                                    key={`bar-slice-lining-drawing-${idx}-tire-inner`}
                                                    style={{
                                                        height: '8px',
                                                        minWidth: '8px',
                                                        backgroundColor:
                                                            colors[
                                                                'Black-RHIM'
                                                            ],
                                                    }}
                                                />
                                            </abbr>
                                        ) : (
                                            <div
                                                style={{
                                                    height: '4px',
                                                    minWidth: '5px',
                                                    backgroundColor:
                                                        colors['Grey5-RHIM'],
                                                }}
                                                key={`bar-slice-lining-drawning-${idx}-tire`}
                                            />
                                        )}
                                        <abbr
                                            title={title}
                                            key={`bar-slice-lining-drawing-${idx}-tooltip`}
                                            style={{
                                                backgroundColor:
                                                    colors['Grey5-RHIM'],
                                                minWidth: '6px',
                                                height: '56px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        lining.code,
                                                    minWidth: '6px',
                                                    height: '40px',
                                                }}
                                                key={`bar-slice-lining-drawing-${idx}`}
                                            />
                                        </abbr>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            ))}
        </Grid>
    );
};

export default memo(LiningChartDrawing);
