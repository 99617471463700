import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllProcessTypes } from '@app/store/processType/reducer';

export const useProcessType = (): void => {
    const { isFetching, lastUpdated, processTypes } = useAppSelector(
        (state) => state.processType
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && processTypes.length === 0 && !lastUpdated) {
            dispatch(getAllProcessTypes());
        }
    }, []);
};
