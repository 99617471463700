import { useMemo, memo } from 'react';
import StringUtils from '@app/utils/StringUtils';
import { InputLabel, Grid, TextField } from '@mui/material';
import NumberUtils from '@app/utils/NumberUtils';
import { useUserSettings } from '@app/hooks/useUserSettings';

type IProps = {
    variant?: 'filled' | 'outlined' | 'standard';
    label?: string;
    title?: string;
    value?: number;
    onChange: Function;
    size?: 'medium' | 'small';
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    placeholder?: string;
    step?: number;
};

const NumberInput = (props: IProps) => {
    const {
        variant = 'outlined',
        label,
        title,
        value = '',
        onChange,
        size = 'small',
        required = false,
        readonly = false,
        disabled = false,
        error = false,
        helperText,
        placeholder,
        step = 1.0,
    } = props;

    useUserSettings();

    const hasTitle = useMemo(
        () => !StringUtils.isUndefinedOrWhiteSpace(title),
        [title]
    );

    const notFilled = useMemo(
        () => required && !NumberUtils.isValidNumber(value),
        [required, value]
    );

    const legend = useMemo(() => {
        if (error) return helperText;
        if (notFilled) return 'Field must be filled.';
    }, [error, notFilled, helperText]);

    const valueToShow = useMemo(() => {
        if (value === undefined || value === '' || Number(value) < 0) return '';

        return value;
    }, [value]);

    return (
        <Grid sx={{ width: '100%', margin: '0' }}>
            {hasTitle ? (
                <InputLabel>{`${title} ${required ? '*' : ''}`}</InputLabel>
            ) : (
                <></>
            )}
            <TextField
                fullWidth
                value={valueToShow}
                
                size={size}
                type='number'
                onChange={({ target: { value } }: any) => {
                    if (NumberUtils.isValidNumber(value)) {
                        onChange(NumberUtils.getNumber(value));
                    } else {
                        onChange(undefined);
                    }
                }}
                label={label}
                variant={variant}
                required={required}
                disabled={disabled}
                inputProps={{
                    step: step,
                }}
                InputProps={{
                    readOnly: readonly,
                }}
                error={error || notFilled}
                helperText={legend}
                placeholder={placeholder}
            />
        </Grid>
    );
};

export default memo(NumberInput);
