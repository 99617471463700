import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllCoatingAreaTypes } from '@app/store/coatingAreaType/reducer';

export const useCoatingAreaType = (): void => {
    const { isFetching, lastUpdated, coatingAreaTypes } = useAppSelector(
        (state) => state.coatingAreaType
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && coatingAreaTypes.length === 0 && !lastUpdated) {
            dispatch(getAllCoatingAreaTypes());
        }
    }, []);
};
