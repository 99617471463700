import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import NumberInput from '@app/atoms/NumberInput';
import SelectInput from '@app/atoms/SelectInput';
import { ProcessTypeModel } from '@app/models/ProcessTypeModel';
import TiresAdd from '@app/organisms/KilnProfile/KilnParameters/TiresAdd';
import NumberUtils from '@app/utils/NumberUtils';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { getIsMetric } from '@app/utils/ConvertUtils';
import { KilnTireModel } from '@app/models/KilnTireModel';

interface IProps {
    setIdProcessType: Function;
    idProcessType: string;
    setDiameter: Function;
    diameter?: number;
    setLength: Function;
    length?: number;
    setMrk: Function;
    mrk?: number;
    setTires: Function;
    tires: KilnTireModel[];
    setClinkerCapacity: Function;
    clinkerCapacity?: number;
    setGirthGear: Function;
    girthGear?: number;
    processTypes: ProcessTypeModel[];
}

const KilnParameters = (props: IProps) => {
    useUserSettings();

    const handleChangeIdProcessType = (value: string) => {
        props.setIdProcessType(value);
    };

    const handleChangeMrk = (value: number) => {
        props.setMrk(value);
    };

    const handleChangeDiameter = (value: number) => {
        props.setDiameter(value);
    };

    const handleChangeLength = (value: number) => {
        props.setLength(value);
    };

    const handleChangeClinkerCapacity = (value: number) => {
        props.setClinkerCapacity(value);
    };

    const handleChangeGirthGear = (value: number) => {
        props.setGirthGear(value);
    };

    const handleChangeTires = (values: KilnTireModel[]) => {
        props.setTires(values);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid container item>
                    <NumberInput
                        title='MRK'
                        placeholder='610'
                        value={props.mrk}
                        size='small'
                        onChange={handleChangeMrk}
                        required
                    />
                </Grid>
                <Grid
                    item
                    container
                    display='grid'
                    gap='8px'
                    gridTemplateColumns='calc(50% - 4px) calc(50% - 4px)'
                >
                    <Grid item margin='0'>
                        <NumberInput
                            title='Diameter'
                            placeholder={NumberUtils.getUomPlaceholder(
                                getIsMetric()
                            )}
                            value={props.diameter}
                            onChange={handleChangeDiameter}
                            required
                        />
                    </Grid>
                    <Grid item margin='0'>
                        <NumberInput
                            title='Length'
                            placeholder={NumberUtils.getUomPlaceholder(
                                getIsMetric()
                            )}
                            value={props.length}
                            onChange={handleChangeLength}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid item container>
                    <NumberInput
                        title='Clinker Capacity'
                        placeholder='TONs per day'
                        value={props.clinkerCapacity}
                        size='small'
                        onChange={handleChangeClinkerCapacity}
                        required
                    />
                </Grid>
                <Grid item container>
                    <SelectInput
                        title='Process Type'
                        value={props.idProcessType}
                        options={props.processTypes
                            .filter((x) => x.enabled)
                            .map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                        onChange={handleChangeIdProcessType}
                        required
                    />
                </Grid>
                <Grid item xs={8}>
                    <NumberInput
                        title='Girth Gear'
                        value={props.girthGear}
                        placeholder={NumberUtils.getUomPlaceholder(
                            getIsMetric()
                        )}
                        size='small'
                        onChange={handleChangeGirthGear}
                    />
                </Grid>
                <TiresAdd
                    handleTiresChange={handleChangeTires}
                    tires={props.tires}
                />
            </Grid>
        </Box>
    );
};

export default memo(KilnParameters);
