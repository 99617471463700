import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import { StressAreaTypeService } from '@app/services/StressAreaTypeService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_STRESS_AREA_TYPE_REQUEST = `${window.env.__REDUX_PATH__}/stressAreaType/GET_STRESS_AREA_TYPE_REQUEST`;
const GET_STRESS_AREA_TYPE_SUCCESS = `${window.env.__REDUX_PATH__}/stressAreaType/GET_STRESS_AREA_TYPE_SUCCESS`;
const GET_STRESS_AREA_TYPE_FAILURE = `${window.env.__REDUX_PATH__}/stressAreaType/GET_STRESS_AREA_TYPE_FAILURE`;

export interface StressAreaTypeState extends BaseState {
    stressAreaTypes: StressAreaTypeModel[];
}

// Action Types
interface GetStressAreaTypeRequestAction {
    type: typeof GET_STRESS_AREA_TYPE_REQUEST;
}

interface GetStressAreaTypeSuccessAction {
    type: typeof GET_STRESS_AREA_TYPE_SUCCESS;
    payload?: StressAreaTypeModel[];
}

interface GetStressAreaTypeFailureAction {
    type: typeof GET_STRESS_AREA_TYPE_FAILURE;
    payload?: string;
}

type StressAreaTypeActionTypes =
    | GetStressAreaTypeRequestAction
    | GetStressAreaTypeSuccessAction
    | GetStressAreaTypeFailureAction;

// Reducer
const initialState: StressAreaTypeState = {
    stressAreaTypes: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getStressAreaTypeRequestReducer = (
    state: StressAreaTypeState
): StressAreaTypeState => {
    return {
        ...state,
        stressAreaTypes: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getStressAreaTypeSuccessReducer = (
    state: StressAreaTypeState,
    action: GetStressAreaTypeSuccessAction
): StressAreaTypeState => {
    return {
        ...state,
        stressAreaTypes: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getStressAreaTypeFailureReducer = (
    state: StressAreaTypeState,
    action: GetStressAreaTypeFailureAction
): StressAreaTypeState => {
    return {
        ...state,
        stressAreaTypes: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: StressAreaTypeState = initialState,
    action: StressAreaTypeActionTypes
): StressAreaTypeState => {
    switch (action.type) {
        case GET_STRESS_AREA_TYPE_REQUEST:
            return getStressAreaTypeRequestReducer(state);
        case GET_STRESS_AREA_TYPE_SUCCESS:
            return getStressAreaTypeSuccessReducer(
                state,
                action as GetStressAreaTypeSuccessAction
            );
        case GET_STRESS_AREA_TYPE_FAILURE:
            return getStressAreaTypeFailureReducer(
                state,
                action as GetStressAreaTypeFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getStressAreaTypeRequest = (): GetStressAreaTypeRequestAction => {
    return {
        type: GET_STRESS_AREA_TYPE_REQUEST,
    };
};

export const getStressAreaTypeSuccess = (
    payload: StressAreaTypeModel[]
): GetStressAreaTypeSuccessAction => {
    return {
        type: GET_STRESS_AREA_TYPE_SUCCESS,
        payload,
    };
};

export const getStressAreaTypeFailure = (
    payload: string
): GetStressAreaTypeFailureAction => {
    return {
        type: GET_STRESS_AREA_TYPE_FAILURE,
        payload,
    };
};

export const getAllStressAreaType = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getStressAreaTypeRequest());

        try {
            const service = new StressAreaTypeService();
            const response = await service.getAll();

            dispatch(getStressAreaTypeSuccess(response));
            dispatch(
                showMessage({
                    message: 'StressAreaType load successfully!',
                    title: 'StressAreaType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getStressAreaTypeFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'StressAreaType could not be load!',
                        title: 'StressAreaType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getStressAreaType = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getStressAreaTypeRequest());

        try {
            const service = new StressAreaTypeService();
            const response = await service.get(params);

            dispatch(getStressAreaTypeSuccess(response));
            dispatch(
                showMessage({
                    message: 'StressAreaType load successfully!',
                    title: 'StressAreaType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getStressAreaTypeFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'StressAreaType could not be load!',
                        title: 'StressAreaType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
