import { memo, useCallback } from 'react';
import { Grid } from '@mui/material';
import { StressAreaModel } from '@app/models/StressAreaModel';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import KilnStressAreaType from './KilnStressAreaType';

type IProps = {
    stressAreas: StressAreaModel[];
    stressAreaTypes: StressAreaTypeModel[];
    handleChange: Function;
    index: number;
};

const KilnStressAreaTypes = (props: IProps) => {
    const { index, stressAreas, stressAreaTypes, handleChange } = props;

    const handleDeletion = (id: number) => {
        stressAreas[index].stressAreaTypes.splice(id, 1);

        handleChange();
    };

    const handleAddition = () => {
        stressAreas[index].stressAreaTypes.push({
            idStressAreaType: '',
            idStressArea: '',
            id: '',
            createdBy: '',
            createdAt: new Date(),
        });

        handleChange();
    };

    const handleChangeStressAreaType = (value: string, id: number) => {
        if (
            (id > 0 &&
                stressAreas[index].stressAreaTypes[id - 1].idStressAreaType !==
                    value) ||
            id === 0
        ) {
            const newType = {
                idStressAreaType: value,
                idStressArea: '',
                id: '',
                createdAt: new Date(),
                createdBy: '',
            };

            stressAreas[index].stressAreaTypes.splice(id, 1, newType);

            handleChange();
        }
    };

    const render = useCallback(
        () =>
            stressAreas[index].stressAreaTypes.map((sat, id) => (
                <Grid
                    item
                    container
                    margin='0'
                    key={`grid-stress-area${index}--stress-area-type-${id}`}
                >
                    <KilnStressAreaType
                        stressAreaTypes={stressAreaTypes}
                        index={id}
                        handleDeletion={handleDeletion}
                        handleAddition={handleAddition}
                        hasAdd={
                            id === stressAreas[index].stressAreaTypes.length - 1
                        }
                        hasDelete={
                            stressAreas[index].stressAreaTypes.length > 1
                        }
                        handleChange={handleChangeStressAreaType}
                        idStressAreaType={sat.idStressAreaType}
                    />
                </Grid>
            )),
        [stressAreas, stressAreaTypes]
    );

    return <>{render()}</>;
};

export default memo(KilnStressAreaTypes);
