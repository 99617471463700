import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import Modal from '@app/molecules/Modal';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { StressAreaTypeService } from '@app/services/StressAreaTypeService';
import { Add, Edit } from '@mui/icons-material';
import {
    TextField,
    Grid,
    Tooltip,
    IconButton,
    Button,
    Typography,
    Switch,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import { getAllStressAreaType } from '@app/store/stressAreaType/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useStressAreaType } from '@app/hooks/useStressAreaType';

const INITIAL_STATE: StressAreaTypeModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    deletedAt: new Date(),
    deletedBy: '',
    updatedAt: new Date(),
    updatedBy: '',
    name: '',
    description: '',
};

const StressAreaTypeList = () => {
    const dispatch = useAppDispatch();
    const { stressAreaTypes } = useAppSelector((state) => state.stressAreaType);
    useStressAreaType();

    const stressAreaTypesService = useMemo(
        () => new StressAreaTypeService(),
        []
    );
    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingStressAreaType, setEditingStressAreaType] =
        useState<StressAreaTypeModel>({
            ...INITIAL_STATE,
        });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingStressAreaType({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingStressAreaType({
                ...editingStressAreaType,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await stressAreaTypesService.create({
                    ...editingStressAreaType,
                });
                dispatch(getAllStressAreaType());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='New Stress Area Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
            >
                <TextField
                    value={editingStressAreaType?.name}
                    label='Stress Area Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
            </Modal>
        );
    }, [editingStressAreaType, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingStressAreaType({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingStressAreaType({
                ...editingStressAreaType,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await stressAreaTypesService.delete(editingStressAreaType.id);
                dispatch(getAllStressAreaType());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await stressAreaTypesService.update({
                    ...editingStressAreaType,
                });
                dispatch(getAllStressAreaType());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='Edit Stress Area Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
            >
                <TextField
                    value={editingStressAreaType?.name}
                    label='Stress Area Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
            </Modal>
        );
    }, [editingStressAreaType, showModalEditing]);

    const getEditAction = useCallback((stressAreaType: StressAreaTypeModel) => {
        const handleClick = () => {
            setEditingStressAreaType(stressAreaType);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Stress Area Type'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback(
        (stressAreaType: StressAreaTypeModel) => {
            const isChecked = stressAreaType.enabled;

            const handleChange = async () => {
                try {
                    dispatch(setLoading(true));
                    await stressAreaTypesService.update({
                        ...stressAreaType,
                        enabled: !isChecked,
                    });
                    dispatch(getAllStressAreaType());
                    dispatch(
                        setMessage({
                            message: '',
                            title: 'Success',
                            color: 'success',
                            open: true,
                        })
                    );
                } catch (ex) {
                    dispatch(
                        setMessage({
                            message: '',
                            title: 'Error',
                            color: 'error',
                            open: true,
                        })
                    );
                }
                dispatch(setLoading(false));
            };

            return (
                <Grid container alignContent='center'>
                    <Tooltip title='Enabled/Disable Stress Area Type'>
                        <Switch
                            onChange={handleChange}
                            value={isChecked}
                            checked={isChecked}
                        />
                    </Tooltip>
                </Grid>
            );
        },
        []
    );

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW STRESS TYPE
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Area Type', value: 'name' },
            {
                label: 'Created by',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={stressAreaTypes}
                    rowsPerPage={5}
                    searchColumns={['name', 'description', 'createdBy']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(StressAreaTypeList);
