import { TableCell } from '@mui/material';
import { PropsWithChildren } from 'react';

interface TableBodyColumnProps {
    isExpandable?: boolean;
}

const TableBodyColumn = (props: PropsWithChildren<TableBodyColumnProps>) => (
    <TableCell
        variant='body'
        size={props.isExpandable ? 'small' : 'medium'}
        width={props.isExpandable ? '40px' : 'auto'}
    >
        {props.children}
    </TableCell>
);

export default TableBodyColumn;
