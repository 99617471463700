import { memo, useMemo } from 'react';
import { logoutRequest } from '@app/authConfig';
import { useMsal } from '@azure/msal-react';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { colors } from '@app/theme';
import rhimLogo from '@app/rhimlogo.svg';
import clipLogo from '@app/cliplogo.svg';
import { useAppSelector } from '@app/hooks';

interface HeaderProps {
    isMenuOpen: boolean;
    children: Array<JSX.Element> | JSX.Element;
}

const Header = (props: HeaderProps) => {
    const { settings } = useAppSelector((state) => state.userSettings);

    const uom = useMemo(() => {
        
        if (settings !== undefined && settings.length > 0) {
            const uomSettings = settings.find((s) => s.settingsName === 'UOM');
            if (
                uomSettings !== undefined &&
                uomSettings.settingsValue !== 'METRIC'
            )
                return uomSettings.settingsValue;
            else {
                return 'METRIC'
            }
        }
        return 'LOADING...';
    }, [settings]);

    const language = useMemo(() => {
        if (settings !== undefined && settings.length > 0) {
            const languageSettings = settings.find(
                (s) => s.settingsName === 'LANG'
            );
            if (
                languageSettings !== undefined &&
                languageSettings.settingsValue !== 'EN'
            )
                return languageSettings.settingsValue;
        }
        return 'EN';
    }, [settings]);

    const { isMenuOpen, children } = props;
    const drawerWidth = isMenuOpen ? 240 : 60;
    const { accounts, instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect(logoutRequest);
    };

    return (
        <>
            <AppBar
                sx={{
                    left: `${drawerWidth}px`,
                    width: `calc(100% - ${drawerWidth}px)`,
                    height: '64px',
                    background: '#FFFFFF',
                    transition: 'left 0.5s, width 0.5s',
                    boxShadow: 'none',
                    paddingLeft: '8px',
                }}
            >
                <Toolbar>
                    <Grid container>
                        <Grid item justifyContent='flex-start'>
                            <img src={rhimLogo} alt='rhim logo' />
                        </Grid>
                        <Grid item justifyContent='flex-start'>
                            <img src={clipLogo} alt='clip logo' />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='flex-end'>
                        {accounts.length > 0 && (
                            <>
                                <Grid
                                    item
                                    display='flex'
                                    justifyContent='right'
                                    alignItems='center'
                                >
                                    <Typography
                                        variant='body1'
                                        color='secondary'
                                    >
                                        {accounts[0].name}
                                    </Typography>
                                    <Grid item gridTemplateRows='50% 50%'>
                                        <Grid item>
                                            <Typography
                                                variant='body1'
                                                color='secondary'
                                            >
                                                {uom}
                                            </Typography>
                                            <Typography
                                                variant='body1'
                                                color='secondary'
                                            >
                                                {language}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <IconButton
                                        size='medium'
                                        aria-label='logout'
                                        sx={{
                                            border: 'none',
                                            marginLeft: '24px',
                                            color: colors['Blue-RHIM'],
                                            backgroundColor: 'inherit',
                                        }}
                                        onClick={handleLogout}
                                    >
                                        <Logout fontSize='medium' />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Toolbar>
            </AppBar>
            {children}
        </>
    );
};

export default memo(Header);
