import { memo, useCallback } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { colors } from '@app/theme';

type IProps = {
    title: string;
    hasDelete?: boolean;
    hasAdd?: boolean;
    handleDeletion?: Function;
    handleAddition?: Function;
    index: number;
};

const KilnBuilderHeader = (props: IProps) => {
    const {
        title,
        hasDelete = false,
        hasAdd = false,
        handleDeletion,
        handleAddition,
        index,
    } = props;

    const renderDelete = useCallback(
        () =>
            hasDelete ? (
                <IconButton
                    size='large'
                    aria-label='delete section'
                    component='span'
                    key={`delete-${title}`}
                    sx={{ border: 0, borderLeft: hasAdd ? '1px' : 0 }}
                    onClick={() => {
                        if (handleDeletion) handleDeletion(index);
                    }}
                >
                    <Delete
                        fontSize='small'
                        sx={{
                            color: colors['Red-RHIM'],
                        }}
                    />
                </IconButton>
            ) : (
                <></>
            ),
        [hasDelete, hasAdd, index, title, handleDeletion]
    );

    const renderAdd = useCallback(
        () =>
            hasAdd ? (
                <IconButton
                    size='large'
                    aria-label='add section'
                    key={`add-${title}`}
                    onClick={() => {
                        if (handleAddition) handleAddition(index);
                    }}
                    sx={{ borderBottom: 0, borderTop: 0 }}
                >
                    <Add fontSize='small' />
                </IconButton>
            ) : (
                <></>
            ),
        [hasAdd, index, title, handleAddition]
    );

    return (
        <Box
            sx={{
                marginTop: '8px',
                height: '42px',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: `auto ${hasAdd ? '40px' : ''} ${
                    hasDelete ? '40px' : ''
                }`,
                alignItems: 'center',
                border: `1px solid ${colors['Grey20-RHIM']}`,
                borderRadius: '4px',
                background: colors['Grey10-RHIM'],
                paddingLeft: '8px',
            }}
        >
            <Typography variant='subtitle1'>{title}</Typography>
            {renderAdd()}
            {renderDelete()}
        </Box>
    );
};

export default memo(KilnBuilderHeader);
