import MarketInsightsModel from '@app/models/MarketInsightsModel';
import { MarketInsightsRepository } from '@app/repositories/MarketInsightsRepository';
import { ServiceBase } from '@app/services/ServiceBase';
import { getIsMetric } from '@app/utils/ConvertUtils';

export class MarketInsightsService extends ServiceBase<
    MarketInsightsModel,
    MarketInsightsRepository<MarketInsightsModel>
> {
    public constructor() {
        super(new MarketInsightsRepository());
    }

    public async queueReport(filter: {}): Promise<string> {
        return await this.repository.queueReport(filter);
    }

    public async queueReportKilnsWithoutLinings(): Promise<string> {
        const filter = {
            kilnsWithoutLinings: ["true"],
            UOM: [(!getIsMetric()).toString()],
        }
        return await this.repository.queueReport(filter);
    }
}
