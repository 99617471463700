import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import { CoatingAreaTypeService } from '@app/services/CoatingAreaTypeService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { BaseState } from '../BaseState';
import { showMessage } from '../message/reducer';

// Actions
const GET_COATING_AREA_TYPE_REQUEST = `${window.env.__REDUX_PATH__}/coatingAreaTypes/GET_COATING_AREA_TYPE_REQUEST`;
const GET_COATING_AREA_TYPE_SUCCESS = `${window.env.__REDUX_PATH__}/coatingAreaTypes/GET_COATING_AREA_TYPE_SUCCESS`;
const GET_COATING_AREA_TYPE_FAILURE = `${window.env.__REDUX_PATH__}/coatingAreaTypes/GET_COATING_AREA_TYPE_FAILURE`;

export interface CoatingAreaTypesState extends BaseState {
    coatingAreaTypes: CoatingAreaTypeModel[];
}

// Action Types
interface GetCoatingAreaTypesRequestAction {
    type: typeof GET_COATING_AREA_TYPE_REQUEST;
}

interface GetCoatingAreaTypesSuccessAction {
    type: typeof GET_COATING_AREA_TYPE_SUCCESS;
    payload?: CoatingAreaTypeModel[];
}

interface GetCoatingAreaTypesFailureAction {
    type: typeof GET_COATING_AREA_TYPE_FAILURE;
    payload?: string;
}

type CoatingAreaTypesActionTypes =
    | GetCoatingAreaTypesSuccessAction
    | GetCoatingAreaTypesFailureAction
    | GetCoatingAreaTypesRequestAction;

// Reducer
const initialState: CoatingAreaTypesState = {
    coatingAreaTypes: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getCoatingAreaTypesRequestReducer = (
    state: CoatingAreaTypesState
): CoatingAreaTypesState => {
    return {
        ...state,
        coatingAreaTypes: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getCoatingAreaTypesSuccessReducer = (
    state: CoatingAreaTypesState,
    action: GetCoatingAreaTypesSuccessAction
): CoatingAreaTypesState => {
    return {
        ...state,
        coatingAreaTypes: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getCoatingAreaTypesFailureReducer = (
    state: CoatingAreaTypesState,
    action: GetCoatingAreaTypesFailureAction
): CoatingAreaTypesState => {
    return {
        ...state,
        coatingAreaTypes: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: CoatingAreaTypesState = initialState,
    action: CoatingAreaTypesActionTypes
): CoatingAreaTypesState => {
    switch (action.type) {
        case GET_COATING_AREA_TYPE_REQUEST:
            return getCoatingAreaTypesRequestReducer(state);
        case GET_COATING_AREA_TYPE_SUCCESS:
            return getCoatingAreaTypesSuccessReducer(
                state,
                action as GetCoatingAreaTypesSuccessAction
            );
        case GET_COATING_AREA_TYPE_FAILURE:
            return getCoatingAreaTypesFailureReducer(
                state,
                action as GetCoatingAreaTypesFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getCoatingAreaTypesRequest =
    (): GetCoatingAreaTypesRequestAction => {
        return {
            type: GET_COATING_AREA_TYPE_REQUEST,
        };
    };

export const getCoatingAreaTypesSuccess = (
    payload: CoatingAreaTypeModel[]
): GetCoatingAreaTypesSuccessAction => {
    return {
        type: GET_COATING_AREA_TYPE_SUCCESS,
        payload,
    };
};

export const getCoatingAreaTypesFailure = (
    payload: string
): GetCoatingAreaTypesFailureAction => {
    return {
        type: GET_COATING_AREA_TYPE_FAILURE,
        payload,
    };
};

export const getAllCoatingAreaTypes = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getCoatingAreaTypesRequest());

        try {
            const service = new CoatingAreaTypeService();
            const response = await service.getAll();

            dispatch(getCoatingAreaTypesSuccess(response));
            dispatch(
                showMessage({
                    message: 'CoatingAreaTypes load successfully!',
                    title: 'CoatingAreaType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getCoatingAreaTypesFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'CoatingAreaTypes could not be load!',
                        title: 'CoatingAreaType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getCoatingAreaTypes = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getCoatingAreaTypesRequest());

        try {
            const service = new CoatingAreaTypeService();
            const response = await service.get(params);

            dispatch(getCoatingAreaTypesSuccess(response));
            dispatch(
                showMessage({
                    message: 'CoatingAreaTypes load successfully!',
                    title: 'CoatingAreaTypes Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getCoatingAreaTypesFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'CoatingAreaTypes could not be load!',
                        title: 'CoatingAreaTypes Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
