import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import { ServiceBase } from '@app/services/ServiceBase';
import { KilnProfileRepository } from '@app/repositories/KilnProfileRepository';
import {
    ConversionType,
    convertToMeters,
    convertToTon,
} from '@app/utils/ConvertUtils';

export class KilnProfileStructureService extends ServiceBase<
    KilnProfileStructureModel,
    KilnProfileRepository<KilnProfileStructureModel>
> {
    public constructor() {
        super(new KilnProfileRepository());
    }

    private convertModel(
        model: KilnProfileStructureModel,
        conversion: ConversionType
    ): KilnProfileStructureModel {
        model.sections = model.sections.map((section) => {
            section.diameter = convertToMeters(section.diameter, conversion);
            section.diameter2 = convertToMeters(section.diameter2, conversion);
            section.start = convertToMeters(section.start, conversion);
            section.end = convertToMeters(section.end, conversion);

            return section;
        });
        model.diameter = convertToMeters(model.diameter, conversion);
        model.length = convertToMeters(model.length, conversion);
        model.clinkerCapacity = convertToTon(model.clinkerCapacity, conversion);

        if (model.girthGear) {
            model.girthGear = convertToMeters(model.girthGear, conversion);
        }

        if (model.tires?.length > 0) {
            model.tires = model.tires
                .map((tire) => ({
                    ...tire,
                    position: convertToMeters(tire.position, conversion),
                }))
                .sort((t1, t2) => t1.position - t2.position);
        }

        if (model.stressAreas) {
            model.stressAreas = model.stressAreas.map((stressArea) => {
                stressArea.start = convertToMeters(
                    stressArea.start,
                    conversion
                );
                stressArea.end = convertToMeters(stressArea.end, conversion);

                return stressArea;
            });
        }

        if (model.coatingAreas) {
            model.coatingAreas = model.coatingAreas.map((coatingArea) => {
                coatingArea.start = convertToMeters(
                    coatingArea.start,
                    conversion
                );
                coatingArea.end = convertToMeters(coatingArea.end, conversion);

                return coatingArea;
            });
        }

        return model;
    }

    public async getAll(): Promise<KilnProfileStructureModel[]> {
        const kilnProfiles = await this.repository.getAll();
        return kilnProfiles.map((entity) =>
            this.convertModel(entity, ConversionType.Read)
        );
    }

    public async get<P>(params: P): Promise<KilnProfileStructureModel[]> {
        const kilnProfiles = await this.repository.get(params);
        return kilnProfiles.map((profile) =>
            this.convertModel(profile, ConversionType.Read)
        );
    }

    public async getById(id: string): Promise<KilnProfileStructureModel> {
        const kilnProfile = await this.repository.getById(id);
        return this.convertModel(kilnProfile, ConversionType.Read);
    }

    public async createStructure(
        kiln: KilnProfileStructureModel
    ): Promise<KilnProfileStructureModel> {
        return await this.repository.createStructure(
            this.convertModel(kiln, ConversionType.Write)
        );
    }

    public async updateStructure(
        kiln: KilnProfileStructureModel
    ): Promise<KilnProfileStructureModel> {
        return await this.repository.updateStructure(
            this.convertModel(kiln, ConversionType.Write)
        );
    }

    public async getByCustomerId({
        customerId,
    }: {
        customerId: string;
    }): Promise<Array<KilnProfileStructureModel>> {
        const kilnProfile = await this.repository.getByCustomerId(customerId);
        return kilnProfile.map((entity) =>
            this.convertModel(entity, ConversionType.Read)
        );
    }
}
