import { useAppDispatch } from '@app/hooks';
import { ReportModel } from '@app/models/ReportModel';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { ReportsService } from '@app/services/ReportsService';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Grid, Typography, Tooltip } from '@mui/material';
import {
    useCallback,
    useMemo,
    memo,
    useEffect,
    useState,
    ReactElement,
} from 'react';
import { colors } from '@app/theme';
import DateUtils from '@app/utils/DateUtils';
import { useUserSettings } from '@app/hooks/useUserSettings';

const ReportsPage = () => {
    const serviceReports = useMemo(() => new ReportsService(), []);
    const dispatch = useAppDispatch();

    const [data, setData] = useState<ReportModel[]>([]);
    useUserSettings()
    useEffect(() => {
        const fetch = async () => {
            dispatch(setLoading(true));
            try {
                const response = await serviceReports.getAll();
                setData(response);
                dispatch(
                    setMessage({
                        title: 'Success',
                        message: 'Reports loaded successfully',
                        open: true,
                        color: 'success',
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message: 'Could not load reports',
                        open: true,
                        color: 'error',
                    })
                );
            }
            dispatch(setLoading(false));
        };

        fetch();
    }, []);

    const getDownloadAction = useCallback((x: ReportModel) => {
        const handleClick = async () => {
            const link = document.createElement('a');
            link.href = x.link;
            link.setAttribute('download', x.name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        const colorMap: { [key: string]: string } = {
            Completed: colors['Blue-RHIM'],
            Error: colors['Red-RHIM'],
            Processing: colors['Orange-RHIM'],
            Queued: colors['Orange-RHIM'],
        };

        const titleMap: { [key: string]: string } = {
            Completed: 'Download report',
            Error: 'Error on report generation! Please contact the administrators',
            Processing: 'Processing the report',
            Queued: 'The report is queued to be processed',
        };

        const cursorMap: { [key: string]: string } = {
            Completed: 'pointer',
            Error: 'not-allowed',
            Processing: 'wait',
            Queued: 'wait',
        };

        const textMap: { [key: string]: ReactElement } = {
            Completed: (
                <Grid
                    sx={{
                        display: 'flex',
                        columnGap: '8px',
                        alignItems: 'center',
                        alignContent: 'center',
                        textAlign: 'center',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <Typography
                        sx={{
                            color: colorMap[x.status],
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        Ready to download
                    </Typography>
                    <FileDownloadOutlined
                        sx={{
                            cursor: cursorMap[x.status],
                            color: colorMap[x.status],
                        }}
                        onClick={handleClick}
                    />
                </Grid>
            ),
            Error: (
                <Typography
                    sx={{
                        color: colorMap[x.status],
                        margin: 0,
                        padding: 0,
                    }}
                >
                    Error...
                </Typography>
            ),
            Processing: (
                <Typography
                    sx={{
                        color: colorMap[x.status],
                        margin: 0,
                        padding: 0,
                    }}
                >
                    Processing...
                </Typography>
            ),
            Queued: (
                <Typography
                    sx={{
                        color: colorMap[x.status],
                        margin: 0,
                        padding: 0,
                    }}
                >
                    Queued...
                </Typography>
            ),
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title={titleMap[x.status]}>
                    {textMap[x.status]}
                </Tooltip>
            </Grid>
        );
    }, []);

    const columns = useMemo(
        () => [
            {
                label: 'Name',
                value: 'name',
            },
            {
                label: 'Request Date',
                value: 'createdAt',
                handle: (value: string) => DateUtils.formatUTCDateTime(value),
            },
            {
                label: 'Download',
                value: TableBodyRowSystem.ROW,
                handle: getDownloadAction,
            },
        ],
        []
    );

    return (
        <Grid container margin={0}>
            <Typography variant='h5' color='secondary'>
                Reports
            </Typography>
            <TableList
                columns={columns}
                data={data}
                rowsPerPage={5}
                searchColumns={['name', 'status']}
            />
        </Grid>
    );
};

export default memo(ReportsPage);
