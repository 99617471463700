import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import LiningsList from '@app/organisms/Tabs/Kiln/LiningChart/List/LiningsList';
import LiningChart from '@app/organisms/Tabs/Kiln/LiningChart/Table/LiningChart';
import LiningsHistory from '@app/organisms/Tabs/Kiln/LiningChart/History/LiningsHistory';
import KilnProfilesList from '@app/organisms/Tabs/Kiln/KilnProfilesList';
import UriUtils from '@app/utils/UriUtils';
import { setLoading } from '@app/store/loading/reducer';
import { useAppDispatch } from '@app/hooks';
import { setMessage } from '@app/store/message/reducer';
import { KilnProfileStructureService } from '@app/services/KilnProfileStructureService';

enum KilnOverviewTabStep {
    LININGS = 'linings',
    CAMPAIGNS = 'campaigns',
    HISTORY = 'history',
}

const Tab = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { kilnProfileId, view } = UriUtils.parseQueryString(location.search);
    const serviceKiln = useMemo(() => new KilnProfileStructureService(), []);
    const [kiln, setKiln] = useState<KilnProfileStructureModel>();

    useEffect(() => {
        if (kilnProfileId) {
            const fetchInitialData = async () => {
                try {
                    dispatch(setLoading(true));
                    const kiln = await serviceKiln.getById(
                        kilnProfileId as string
                    );
                    setKiln(kiln);
                    dispatch(
                        setMessage({
                            color: 'success',
                            title: 'Success',
                            message: 'Successfully loaded kiln data.',
                            open: true,
                        })
                    );
                } catch (ex) {
                    dispatch(
                        setMessage({
                            color: 'error',
                            title: 'Error',
                            message: 'Could not loaded kiln data.',
                            open: true,
                        })
                    );
                }
                dispatch(setLoading(false));
            };

            fetchInitialData();
        }
    }, [kilnProfileId]);

    const getTabStep = useCallback(() => {
        if (kiln)
            switch (view) {
                case KilnOverviewTabStep.HISTORY:
                    return <LiningsHistory {...kiln} />;
                case KilnOverviewTabStep.CAMPAIGNS:
                    return <LiningsList {...kiln} />;
                case KilnOverviewTabStep.LININGS:
                    return <LiningChart {...kiln} />;
            }

        return <KilnProfilesList />;
    }, [view, kiln]);

    return getTabStep();
};

export default Tab;
