import { CardFilterContextManager } from '@app/contexts/CardFilterContext';
import { FilterModalContextManager } from '@app/contexts/FilterModalContext';
import { useBrandName } from '@app/hooks/useBrandName';
import { useCustomer } from '@app/hooks/useCustomer';
import { useMPG } from '@app/hooks/useMPG';
import { useSalesUnit } from '@app/hooks/useSalesUnit';
import { useShapeStandard } from '@app/hooks/useShapeStandard';
import { useSupplier } from '@app/hooks/useSupplier';
import { useZone } from '@app/hooks/useZone';
import MarketInsightsModel from '@app/models/MarketInsightsModel';
import { KilnProfileModel } from '@app/models/KilnProfileModel';
import OverviewTab from '@app/organisms/MarketInsights/OverviewTab';
import { MarketInsightsService } from '@app/services/MarketInsightsService';
import { KilnProfileService } from '@app/services/KilnProfileService';
import { colors } from '@app/theme';
import {
    useContext,
    useMemo,
    useCallback,
    useState,
    useEffect,
    memo,
} from 'react';
import { Option, OptionMap } from '@app/organisms/CardFilter/FilterModal';
import { SupplierModel } from '@app/models/SupplierModel';
import { useKilnStatus } from '@app/hooks/useKilnStatus';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { convertToTon, ConversionType } from '@app/utils/ConvertUtils';
import { CampaignService } from '@app/services/CampaignService';
import NumberUtils from '@app/utils/NumberUtils';
import { useProcessType } from '@app/hooks/useProcessType';

const OverviewInsightsTab = (props: { minimumFilter: OptionMap }) => {
    const dispatch = useAppDispatch();
    const { selected } = useContext(FilterModalContextManager);
    const { applied, hasFilterApplied } = useContext(CardFilterContextManager);
    const [years, setYears] = useState<string[]>([]);
    const [year, setYear] = useState<string>('All');
    const [data, setData] = useState<MarketInsightsModel[]>([]);
    const [profiles, setProfiles] = useState<KilnProfileModel[]>([]);
    const [countryOptions, setCountryOptions] = useState<Option[]>([]);
    const [cityOptions, setCityOptions] = useState<Option[]>([]);
    const [keyAccountOptions, setKeyAccountOptions] = useState<Option[]>([]);
    const [kilnNameOptions, setKilnNameOptions] = useState<Option[]>([]);

    const columns = useMemo(
        () => [
            'country',
            'city',
            'keyAccount',
            'customer',
            'kilnName',
            'zone',
            'mpg',
            'supplier',
            'shapeStandard',
            'salesUnit',
            'brandName',
            'kilnStatus',
            'processType',
        ],
        []
    );

    const {
        brandName,
        zone,
        mpg,
        supplier,
        shapeStandard,
        salesUnit,
        customer,
        kilnStatus,
        processType,
    } = useAppSelector((state) => state);

    useCustomer();
    useBrandName();
    useZone();
    useMPG();
    useSupplier();
    useShapeStandard();
    useSalesUnit();
    useKilnStatus();
    useProcessType();

    const serviceKiln = useMemo(() => new KilnProfileService(), []);
    const serviceCampaign = useMemo(() => new CampaignService(), []);
    const service = useMemo(() => new MarketInsightsService(), []);

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const customerId = customer.selectedCustomer?.id ?? '';
                const response = await Promise.all([
                    serviceKiln.getByCustomerId({ customerId }),
                    service.get({
                        year:
                            year !== 'All'
                                ? year
                                : new Date().getFullYear().toString(),
                        customer: [customerId],
                    }),
                    customerId !== undefined
                        ? serviceCampaign.getYearsByCustomerId(customerId)
                        : serviceCampaign.getYears(),
                ]);
                setProfiles(response[0]);
                setKilnNameOptions(
                    response[0].map((kp: KilnProfileModel) => ({
                        value: kp.id,
                        label: kp.kilnName,
                    }))
                );
                setCountryOptions([
                    {
                        label: customer.selectedCustomer?.country ?? '',
                        value: customer.selectedCustomer?.country ?? '',
                    },
                ]);
                setCityOptions([
                    {
                        label: customer.selectedCustomer?.city ?? '',
                        value: customer.selectedCustomer?.city ?? '',
                    },
                ]);
                setKeyAccountOptions([
                    {
                        label: customer.selectedCustomer?.keyAccount ?? '',
                        value: customer.selectedCustomer?.keyAccount ?? '',
                    },
                ]);
                setData(
                    response[1].map((mkt) => {
                        const customerMkt = customer.customers.find(
                            (c) => c.id === mkt.idCustomer
                        );
                        return {
                            ...mkt,
                            country: customerMkt?.country ?? '',
                            city: customerMkt?.city ?? '',
                            keyAccount: customerMkt?.keyAccount ?? '',
                        };
                    })
                );
                setYears(response[2]);
                dispatch(
                    setMessage({
                        title: 'Success',
                        message: 'Successefully loaded data',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message: 'Could not load data',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        if (customer.selectedCustomer !== undefined) fetchData();
    }, [year, customer.customers, customer.selectedCustomer]);

    const filteredData = useMemo(() => {
        const compareValues = (val: MarketInsightsModel, column: string) => {
            switch (column) {
                case 'country':
                    return applied[column].some(
                        (option) =>
                            // for future implementations
                            // compareStringsBase(val.country, option.value)
                            val.country === option.value
                    );
                case 'city':
                    return applied[column].some(
                        (option) =>
                            // for future implementations
                            // compareStringsBase(val.city, option.value)
                            val.city === option.value
                    );
                case 'keyAccount':
                    return applied[column].some(
                        (option) => val.keyAccount === option.value
                    );
                case 'customer':
                    return applied[column].some(
                        (option) => val.idCustomer === option.value
                    );
                case 'zone':
                    return applied[column].some(
                        (option) => val.idZone === option.value
                    );
                case 'mpg':
                    return applied[column].some(
                        (option) => val.idmpg === option.value
                    );
                case 'supplier':
                    return applied[column].some(
                        (option) => val.idSupplier === option.value
                    );
                case 'shapeStandard':
                    return applied[column].some(
                        (option) => val.idShapeStandard === option.value
                    );
                case 'salesUnit':
                    return applied[column].some(
                        (option) => val.idSalesUnit === option.value
                    );
                case 'brandName':
                    return applied[column].some(
                        (option) => val.idBrandName === option.value
                    );
                case 'kilnStatus':
                    return applied[column].some(
                        (option) => val.idKilnStatus === option.value
                    );
                case 'kilnName':
                    return applied[column].some(
                        (option) => val.idKilnProfile === option.value
                    );
                case 'processType':
                    return applied[column].some(
                        (option) => val.idProcessType === option.value
                    );
                default:
                    return true;
            }
        };

        if (hasFilterApplied)
            return data.filter((val: MarketInsightsModel) =>
                columns.every((column) =>
                    applied[column] && applied[column].length
                        ? compareValues(val, column)
                        : true
                )
            );

        return data;
    }, [applied, data, columns, hasFilterApplied]);

    useEffect(() => {
        const comparator = (
            value: any,
            filter: Option[],
            expression?: Function
        ): boolean =>
            filter.length > 0
                ? filter.some((c: Option) =>
                    expression !== undefined
                        ? expression(c, value)
                        : c.value === value
                )
                : true;

        setKilnNameOptions(
            profiles
                .map((kp: KilnProfileModel) => ({
                    value: kp.id,
                    label: kp.kilnName,
                }))
        );
        setCountryOptions([
            {
                label: customer.selectedCustomer?.country ?? '',
                value: customer.selectedCustomer?.country ?? '',
            },
        ]);
        setCityOptions([
            {
                label: customer.selectedCustomer?.city ?? '',
                value: customer.selectedCustomer?.city ?? '',
            },
        ]);
        setKeyAccountOptions([
            {
                label: customer.selectedCustomer?.keyAccount ?? '',
                value: customer.selectedCustomer?.keyAccount ?? '',
            },
        ]);
    }, [selected]);

    const optionsMap = useMemo(
        () => ({
            country: countryOptions,
            city: cityOptions,
            keyAccount: keyAccountOptions,
            customer: props.minimumFilter.customer,
            kilnName: kilnNameOptions,
            brandName: brandName.brands.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            supplier: supplier.suppliers.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            shapeStandard: shapeStandard.shapeStandards.map((c) => ({
                value: c.id,
                label: c.shape,
            })),
            salesUnit: salesUnit.salesUnits.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            zone: zone.zones.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            mpg: mpg.MPGs.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            kilnStatus: kilnStatus.kilnStatus.map((c) => ({
                value: c.id,
                label: c.name,
            })),
            processType: processType.processTypes.map((c) => ({
                value: c.id,
                label: c.name,
            })),
        }),
        [
            brandName.brands,
            customer.customers,
            zone.zones,
            mpg.MPGs,
            supplier.suppliers,
            shapeStandard.shapeStandards,
            salesUnit.salesUnits,
            kilnStatus.kilnStatus,
            profiles,
            processType.processTypes,
            countryOptions,
            cityOptions,
            keyAccountOptions,
            kilnNameOptions,
            props.minimumFilter.customer,
        ]
    );

    const total = useMemo(
        () =>
            convertToTon(
                filteredData.reduce(
                    (acc: number, val: MarketInsightsModel) =>
                        acc + val.sliceTonnage,
                    0
                ),
                ConversionType.Read
            ) ?? 0,
        [filteredData]
    );

    const chartData = useMemo(
        () =>
            supplier.suppliers?.map((supplier: SupplierModel) => {
                let value =
                    ((filteredData
                        .filter(
                            (val: MarketInsightsModel) =>
                                val.idSupplier === supplier.id
                        )
                        .reduce(
                            (acc: number, val: MarketInsightsModel) =>
                                acc + val.sliceTonnage,
                            0
                        ) ?? 0) *
                        100) /
                    total;

                value = value > 100 ? 100 : value;
                value = value < 0 ? 0 : value;
                value = NumberUtils.isValidNumber(value) ? value : 0;
                value = Number.isFinite(value) ? value : 0;

                return {
                    name: supplier.name,
                    color: supplier.code,
                    unit: '%',
                    value: +value.toFixed(2),
                };
            }),
        [total, filteredData, supplier.suppliers]
    );

    const getMarkers = useCallback(() => {
        const getSimpleCircle = (radius: number) => (
            <circle
                opacity={0.7}
                fill={colors['Blue-RHIM']}
                stroke={colors['Grey-RHIM']}
                strokeWidth={1}
                r={radius}
            />
        );

        let max = 0;
        const countryMap = filteredData.reduce(
            (acc: { [key: string]: number }, val: MarketInsightsModel) => {
                const customerVal = customer.customers.find(
                    (c) => c.id === val.idCustomer
                );

                if (customerVal === undefined) return acc;

                const value = acc[customerVal.country]
                    ? acc[customerVal.country] + val.sliceTonnage
                    : val.sliceTonnage;

                if (value > max) max = value;

                return {
                    ...acc,
                    [customerVal.country]: value,
                };
            },
            {}
        );

        return Object.keys(countryMap).map((country) => ({
            name: country,
            label: `${(countryMap[country] / 1000).toFixed(2)} kTON`,
            element: getSimpleCircle((countryMap[country] * 20) / max),
        }));
    }, [filteredData, customer.customers]);

    return (
        <OverviewTab
            columns={columns}
            chartData={chartData}
            options={optionsMap}
            countries={getMarkers()}
            total={total}
            years={years}
            year={year}
            setYear={setYear}
            minimumFilter={props.minimumFilter}
            disabledFilters={{
                customer: "This field is preset and can't be changed",
            }}
        />
    );
};

export default memo(OverviewInsightsTab);
