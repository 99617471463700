import { memo, useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from '@app/routes';
import { useMsal } from '@azure/msal-react';

interface MainContentProps {
    isMenuOpen: boolean;
}

const MainContent = (props: MainContentProps) => {
    const { accounts } = useMsal();
    const { isMenuOpen } = props;
    const drawerWidth = isMenuOpen ? 240 : 60;

    const filteredRoutes = useMemo(
        () =>
            routes.filter(
                (route) =>
                    route.roles === undefined ||
                    route.roles?.some(
                        (role) =>
                            accounts.length > 0 &&
                            accounts[0].idTokenClaims !== undefined &&
                            accounts[0].idTokenClaims?.roles?.includes(role)
                    )
            ),
        [routes, accounts]
    );

    return (
        <Box
            sx={{
                left: `${drawerWidth}px`,
                top: '64px',
                width: `calc(100% - ${drawerWidth + 64}px)`,
                height: 'calc(100% - 128px)',
                position: 'relative',
                background: 'transparent',
                transition: 'left 0.5s, width 0.5s',
                margin: '32px',
            }}
        >
            <Paper elevation={0} sx={{ padding: '32px' }}>
                <Routes>
                    {filteredRoutes.length > 0 ? (
                        filteredRoutes.map((route) => {
                            return route.paths.map((path: string) => (
                                <Route
                                    key={`route-${path}`}
                                    path={path}
                                    element={route.element}
                                />
                            ));
                        })
                    ) : (
                        <></>
                    )}
                    <Route path='*' element={<Navigate to='/home' replace />} />
                </Routes>
            </Paper>
        </Box>
    );
};

export default memo(MainContent);
