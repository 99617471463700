import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import customerReducer from '@app/store/customer/reducer';
import mpgReducer from '@app/store/mpg/reducer';
import brandNameReducer from '@app/store/brandName/reducer';
import coatingAreaTypeReducer from '@app/store/coatingAreaType/reducer';
import kilnStatusReducer from '@app/store/kilnStatus/reducer';
import processTypeReducer from '@app/store/processType/reducer';
import shapeStandardReducer from '@app/store/shapeStandard/reducer';
import supplierReducer from '@app/store/supplier/reducer';
import stressAreaTypeReducer from '@app/store/stressAreaType/reducer';
import zoneReducer from '@app/store/zone/reducer';
import messageReducer from '@app/store/message/reducer';
import loadingReducer from '@app/store/loading/reducer';
import salesUnitReducer from '@app/store/salesUnit/reducer';
import sectionTypeReducer from '@app/store/sectionType/reducer';
import userSettingsReducer from '@app/store/userSettings/reducer';

const reducer = {
    customer: customerReducer,
    mpg: mpgReducer,
    brandName: brandNameReducer,
    coatingAreaType: coatingAreaTypeReducer,
    kilnStatus: kilnStatusReducer,
    processType: processTypeReducer,
    shapeStandard: shapeStandardReducer,
    supplier: supplierReducer,
    stressAreaType: stressAreaTypeReducer,
    zone: zoneReducer,
    message: messageReducer,
    loading: loadingReducer,
    salesUnit: salesUnitReducer,
    sectionType: sectionTypeReducer,
    userSettings: userSettingsReducer,
};

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
