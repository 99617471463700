import TabContainer from '@app/organisms/TabContainer';
import BrandsList from '@app/organisms/Tabs/Admin/Lining/BrandsList';
import MPGsList from '@app/organisms/Tabs/Admin/Lining/MPGsList';
import ShapeStandardList from '@app/organisms/Tabs/Admin/Lining/ShapeStandardList';
import SuppliersList from '@app/organisms/Tabs/Admin/Lining/SuppliersList';
import ZonesList from '@app/organisms/Tabs/Admin/Lining/ZonesList';
import { memo } from 'react';

const AdminAreaPage = () => {
    const tabs = [
        {
            component: <ZonesList />,
            label: 'KILN ZONE',
        },
        {
            component: <ShapeStandardList />,
            label: 'SHAPE STANDARD',
        },
        {
            component: <SuppliersList />,
            label: 'SUPPLIER',
        },
        {
            component: <MPGsList />,
            label: 'MPG',
        },
        {
            component: <BrandsList />,
            label: 'BRAND NAME',
        },
    ];
    return <>
         <TabContainer areaTitle={'Admin Area Lining'} tabsList={tabs}  />
    </>;
};

export default memo(AdminAreaPage);
