import { useCallback, useMemo, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Box,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from '@mui/material';
import {
    Menu,
    MenuOpen,
    Home,
    ManageAccounts,
    Timeline,
    AssignmentInd,
    Description,
} from '@mui/icons-material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useMsal } from '@azure/msal-react';
import { colors } from '@app/theme';
import { ReactComponent as liningIcon } from '@app/liningIcon.svg';
import { ReactComponent as kilnIcon } from '@app/kilnIcon.svg';

interface SidebarProps {
    isExpand: boolean;
    onExpand: Function;
    children: Array<JSX.Element> | JSX.Element;
}

interface ChildRouteProps {
    text: string;
    path?: string;
    icon: (color?: string) => JSX.Element;
}

interface RouteProps extends ChildRouteProps {
    roles?: string[];
    children?: ChildRouteProps[];
}

const Sidebar = (props: SidebarProps) => {
    const { isExpand, onExpand, children } = props;

    const location = useLocation();
    const { accounts } = useMsal();

    const routes: RouteProps[] = useMemo(
        () => [
            { text: 'Home', icon: () => <Home />, path: '/home' },
            {
                text: 'Launchpad',
                icon: (_?: string) => <AssignmentInd key='launchpad' />,
                path: '/launchpad',
            },
            {
                text: 'Market Insights',
                icon: (_?: string) => <Timeline key='insights' />,
                path: '/insights',
            },
            {
                text: 'Reports',
                icon: (_?: string) => <Description key='reports' />,
                path: '/reports',
            },
            {
                text: 'Settings',
                icon: (_?: string) => <ManageAccounts key='settings' />,
                path: '/settings',
            },
            {
                text: 'Admin Area',
                icon: (_?: string) => <AdminPanelSettingsIcon key='admin' />,
                roles: ['Admin.ReadWrite'],
                children: [
                    {
                        text: 'Lining',
                        icon: (color?: string) => (
                            <SvgIcon
                                component={liningIcon}
                                sx={{ color }}
                                key='lining'
                            />
                        ),
                        path: '/admin/lining',
                    },
                    {
                        text: 'Kiln Profile',
                        icon: (color?: string) => (
                            <SvgIcon
                                component={kilnIcon}
                                sx={{ color }}
                                key='kiln'
                            />
                        ),
                        path: '/admin/kiln',
                    },
                ],
            },
        ],
        []
    );

    const permissionedRoutes: RouteProps[] = useMemo(
        () =>
            routes.filter(
                (route) =>
                    route.roles === undefined ||
                    route.roles?.some(
                        (role) =>
                            accounts.length > 0 &&
                            accounts[0].idTokenClaims !== undefined &&
                            accounts[0].idTokenClaims?.roles?.includes(role)
                    )
            ),
        [accounts, routes]
    );

    const routesWithoutChildren: RouteProps[] = useMemo(
        () =>
            permissionedRoutes.filter((route) => route.children === undefined),
        [permissionedRoutes]
    );

    const routesWithChildren: RouteProps[] = useMemo(
        () =>
            permissionedRoutes.filter(
                (route) =>
                    route.children !== undefined && route.children?.length > 0
            ),
        [permissionedRoutes]
    );

    const renderMenuItemsWithoutChildren = useCallback(
        () =>
            routesWithoutChildren.map((route) => (
                <Link
                    to={route.path ?? ''}
                    key={`${route.text}-route`}
                    style={{ textDecoration: 'none' }}
                >
                    <ListItemButton
                        key={`${route.text}-route-button`}
                        sx={{
                            alignItems: 'center',
                            height: '60px',
                            padding: 0,
                        }}
                    >
                        <ListItemIcon
                            key={`${route.text}-route-icon`}
                            sx={{
                                justifyContent: 'center',
                                color:
                                    location.pathname === route.path
                                        ? colors['Red-RHIM']
                                        : colors['White-RHIM'],
                            }}
                        >
                            {route.icon()}
                        </ListItemIcon>
                        {isExpand && (
                            <ListItemText
                                key={`${route.text}-route-text`}
                                primary={route.text}
                                sx={{
                                    color: colors['White-RHIM'],
                                }}
                            />
                        )}
                    </ListItemButton>
                </Link>
            )),
        [routesWithoutChildren, isExpand, onExpand, location]
    );

    const renderMenuItemsWithChildren = useCallback(
        () =>
            routesWithChildren.map((route) => (
                <>
                    <ListItemButton
                        key={`${route.text}-route-button`}
                        sx={{
                            alignItems: 'center',
                            height: '60px',
                            padding: 0,
                        }}
                        onClick={() => {
                            onExpand(true);
                        }}
                    >
                        <ListItemIcon
                            key={`${route.text}-route-icon`}
                            sx={{
                                justifyContent: 'center',
                                color: route.children?.some(
                                    (child) => child.path === location.pathname
                                )
                                    ? colors['Red-RHIM']
                                    : colors['White-RHIM'],
                            }}
                        >
                            {route.icon()}
                        </ListItemIcon>
                        {isExpand && (
                            <ListItemText
                                key={`${route.text}-route-text`}
                                primary={route.text}
                                sx={{
                                    color: colors['White-RHIM'],
                                }}
                            />
                        )}
                    </ListItemButton>
                    {isExpand &&
                        route.children?.map((child) => (
                            <Link
                                to={child.path ?? ''}
                                key={`${child.text}-child-route`}
                                style={{ textDecoration: 'none' }}
                            >
                                <ListItemButton
                                    key={`${child.text}-child-route-button`}
                                    sx={{
                                        height: '60px',
                                        padding: 0,
                                        marginLeft: '56px',
                                        backgroundColor:
                                            colors['ChildMenu-RHIM'],
                                    }}
                                    onClick={() => {
                                        onExpand(true);
                                    }}
                                >
                                    <ListItemIcon
                                        key={`${route.text}-route-icon`}
                                        sx={{
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            color:
                                                child.path === location.pathname
                                                    ? colors['Red-RHIM']
                                                    : colors['White-RHIM'],
                                        }}
                                    >
                                        {child.icon(
                                            child.path === location.pathname
                                                ? colors['Red-RHIM']
                                                : colors['White-RHIM']
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        key={`${child.text}-child-route-text`}
                                        primary={child.text}
                                        primaryTypographyProps={{
                                            fontWeight: 500,
                                        }}
                                        sx={{
                                            textAlign: 'left',
                                            color:
                                                child.path === location.pathname
                                                    ? colors['Red-RHIM']
                                                    : colors['White-RHIM'],
                                        }}
                                    />
                                </ListItemButton>
                            </Link>
                        ))}
                </>
            )),
        [routesWithChildren, isExpand, onExpand, location]
    );

    const renderMenuItems = useCallback(
        () => (
            <List
                key='sidebar'
                sx={{
                    backgroundColor: colors['Blue-RHIM'],
                    height: '100%',
                    width: `${isExpand ? 240 : 60}px`,
                    padding: 0,
                    transition: 'width 0.5s',
                }}
            >
                <ListItemButton
                    key='sidebar-opener-button'
                    onClick={() => {
                        onExpand(!isExpand);
                    }}
                    sx={{
                        alignItems: 'center',
                        height: '60px',
                        padding: 0,
                    }}
                >
                    <ListItemIcon
                        key='sidebar-opener-icon'
                        sx={{
                            justifyContent: 'center',
                            color: colors['White-RHIM'],
                        }}
                    >
                        {isExpand ? <MenuOpen /> : <Menu />}
                    </ListItemIcon>
                </ListItemButton>
                {renderMenuItemsWithoutChildren()}
                {renderMenuItemsWithChildren()}
            </List>
        ),
        [isExpand, location, onExpand, routes, accounts]
    );

    return (
        <>
            <Box component='div' position='static'>
                <Drawer
                    variant='permanent'
                    open={isExpand}
                    onClose={() => {
                        onExpand(false);
                    }}
                >
                    {renderMenuItems()}
                </Drawer>
            </Box>
            {children}
        </>
    );
};

export default memo(Sidebar);
