import TableBodyColumn from '@app/atoms/TableBodyColumn';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { IconButton, TableRow, Tooltip } from '@mui/material';
import { PropsWithChildren, ReactElement, memo } from 'react';

export enum TableBodyRowSystem {
    ROW = 'system.value.row',
}

interface TableBodyRowProps {
    columns: Array<
        string | { value: any; label: string; handle?: (value: any) => any }
    >;
    row: {
        [key: string]: string;
    };
    actions?: ReactElement;
    onRowClick?: (value: any) => void;
    expandedColumns?: Array<
        string | { value: any; label: string; handle?: (value: any) => any }
    >;
    handleExpand?: (value: any) => void;
    isExpanded?: boolean;
    expandedTitle?: string;
}

const TableBodyRow = (props: PropsWithChildren<TableBodyRowProps>) => {
    const {
        columns,
        row,
        actions,
        onRowClick,
        expandedColumns,
        handleExpand,
        isExpanded,
        expandedTitle,
    } = props;

    const defaultHandler = (value: any) => value;

    return (
        <TableRow
            onClick={() => {
                if (onRowClick) onRowClick(row);
            }}
        >
            {expandedColumns && (
                <TableBodyColumn isExpandable>
                    <Tooltip title={expandedTitle ?? ''}>
                        <IconButton
                            aria-label='expand row'
                            sx={{ border: 'none', backgroundColor: 'inherit' }}
                            size='small'
                            onClick={() =>
                                (handleExpand ?? defaultHandler)(!isExpanded)
                            }
                        >
                            {isExpanded ? (
                                <KeyboardArrowUp />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </Tooltip>
                </TableBodyColumn>
            )}
            {columns.map((column, index) => {
                const isStringColumn = typeof column === 'string';
                const property = isStringColumn ? column : column.value;
                const handle = isStringColumn
                    ? defaultHandler
                    : column.handle ?? defaultHandler;
                const useRow =
                    !isStringColumn && column.value === TableBodyRowSystem.ROW;
                return (
                    <TableBodyColumn key={`table-body-row-column-${index}`}>
                        {handle(useRow ? row : row[property] ?? property)}
                    </TableBodyColumn>
                );
            })}
            {actions !== undefined && (
                <TableBodyColumn>{actions}</TableBodyColumn>
            )}
        </TableRow>
    );
};

export default memo(TableBodyRow);
