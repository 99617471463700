import { ZoneModel } from '@app/models/ZoneModel';
import { ZoneService } from '@app/services/ZoneService';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { Add, Edit } from '@mui/icons-material';
import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import Modal from '@app/molecules/Modal';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import StringUtils from '@app/utils/StringUtils';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useZone } from '@app/hooks/useZone';
import { getAllZone } from '@app/store/zone/reducer';

const INITIAL_STATE: ZoneModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    name: '',
    description: '',
};

const ZonesList = () => {
    useZone();
    const dispatch = useAppDispatch();
    const { zones } = useAppSelector((state) => state.zone);
    const zonesService = useMemo(() => new ZoneService(), []);
    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingZone, setEditingZone] = useState<ZoneModel>({
        ...INITIAL_STATE,
    });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingZone({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingZone({
                ...editingZone,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingZone({
                ...editingZone,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await zonesService.create({
                    ...editingZone,
                });
                dispatch(getAllZone());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingZone.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingZone.description);

        return (
            <Modal
                title='Create Kiln Zone'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingZone?.name}
                    label='Zone name'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingZone?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingZone, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingZone({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingZone({
                ...editingZone,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingZone({
                ...editingZone,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await zonesService.update({
                    ...editingZone,
                });
                dispatch(getAllZone());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await zonesService.delete(editingZone.id);
                dispatch(getAllZone());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingZone.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingZone.description);

        return (
            <Modal
                title='Edit Kiln Zone'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingZone?.name}
                    label='Zone name'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingZone?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingZone, showModalEditing]);

    const getEditAction = useCallback((zone: ZoneModel) => {
        const handleClick = () => {
            setEditingZone(zone);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Kiln Zone'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((zone: ZoneModel) => {
        const isChecked = zone.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await zonesService.update({
                    ...zone,
                    enabled: !isChecked,
                });
                dispatch(getAllZone());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable Kiln Zone'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW KILN ZONE
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Kiln Zone', value: 'name' },
            {
                label: 'Created By',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
                
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={zones}
                    rowsPerPage={5}
                    searchColumns={['name', 'createdBy']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(ZonesList);
