import { AppThunk } from '@app/store';
import { AlertColor } from '@mui/material';
import { Dispatch } from 'redux';

const SET_MESSAGE = `${window.env.__REDUX_PATH__}/SET_MESSAGE`;

export type MessageState = {
    message?: string;
    title?: string;
    color?: AlertColor;
    open?: boolean;
    duration?: number;
};

const initialState: MessageState = {
    message: '',
    title: '',
    color: 'info',
    open: false,
    duration: 3000,
};

type MessageAction = {
    type: string;
    payload?: MessageState;
};

type MessageActionTypes = MessageAction;

const reducer = (
    state: MessageState = initialState,
    action: MessageActionTypes
): MessageState => {
    switch (action.type) {
        case SET_MESSAGE:
            return action.payload
                ? { ...state, ...action.payload }
                : initialState;
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

export const showMessage = (payload: MessageState): MessageAction => {
    return {
        type: SET_MESSAGE,
        payload,
    };
};

export const setMessage = (message: MessageState): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(showMessage(message));
    };
};
