import { memo, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import KilnBuilderHeader from '../KilnBuilderHeader';
import NumberInput from '@app/atoms/NumberInput';
import { colors } from '@app/theme';
import { StressAreaModel } from '@app/models/StressAreaModel';
import KilnStressAreaTypes from './KilnStressAreaTypes';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import { useUserSettings } from '@app/hooks/useUserSettings';

type IProps = {
    index: number;
    handleChange: Function;
    stressAreas: StressAreaModel[];
    length: number;
    stressAreaTypes: StressAreaTypeModel[];
    handleDeletion: Function;
    handleAddition: Function;
};

const KilnStressArea = (props: IProps) => {
    

    useUserSettings();

    const {
        index,
        handleChange,
        stressAreas,
        length,
        stressAreaTypes,
        handleDeletion,
        handleAddition,
    } = props;

    const errorMapping = useMemo(
        () => ({
            startLower: "The start can't be lower than 0.",
            endLower: "The end can't be lower than or equals 0.",
            endStart: "The end can't be lower than the start.",
            length: "The start/end can't be higher than the kiln length.",
        }),
        []
    );

    const handleChangeStart = (value: number) => {
        stressAreas[index].start = value;
        handleChange(index);
    };

    const handleChangeEnd = (value: number) => {
        stressAreas[index].end = value;
        handleChange(index);
    };

    const isLast = useMemo(
        () => index === stressAreas.length - 1,
        [stressAreas, index]
    );

    return (
        <Box sx={{ margin: '8px 0' }}>
            <KilnBuilderHeader
                title={`Stress Area ${index + 1}`}
                hasAdd={isLast}
                hasDelete
                index={index}
                handleDeletion={handleDeletion}
                handleAddition={handleAddition}
            />
            <Grid
                container
                margin='0'
                sx={{
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    borderTop: 0,
                    borderRadius: '0 0 4px 4px',
                    paddingTop: '8px',
                }}
            >
                <Grid
                    item
                    container
                    display='grid'
                    gap='8px'
                    gridTemplateColumns='calc(50% - 4px) calc(50% - 4px)'
                >
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: Start'
                            value={stressAreas[index].start}
                            onChange={handleChangeStart}
                            error={stressAreas[index].start < 0}
                            helperText={
                                stressAreas[index].start < 0
                                    ? errorMapping.startLower
                                    : stressAreas[index].start > length
                                    ? errorMapping.length
                                    : ''
                            }
                            required
                        />
                    </Grid>
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: End'
                            value={stressAreas[index].end}
                            onChange={handleChangeEnd}
                            error={
                                stressAreas[index].start >
                                    stressAreas[index].end ||
                                stressAreas[index].end <= 0 ||
                                stressAreas[index].end > length
                            }
                            helperText={
                                stressAreas[index].end <= 0
                                    ? errorMapping.endLower
                                    : stressAreas[index].end > length
                                    ? errorMapping.length
                                    : errorMapping.endStart
                            }
                            required
                        />
                    </Grid>
                </Grid>
                <KilnStressAreaTypes
                    stressAreaTypes={stressAreaTypes}
                    stressAreas={stressAreas}
                    handleChange={handleChange}
                    index={index}
                />
            </Grid>
        </Box>
    );
};

export default memo(KilnStressArea);
