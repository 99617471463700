import { ReactElement } from 'react';
import HomePage from '@app/pages/HomePage';
import InsightsPage from '@app/pages/InsightsPage';
import KilnProfilePage from '@app/pages/KilnProfilePage';
import OverviewPage from '@app/pages/OverviewPage';
import AdminLiningPage from './pages/AdminArea/LiningPage';
import AdminKilnPage from './pages/AdminArea/KilnPage';
import LaunchpadPage from './pages/LaunchpadPage';
import UserSettingsPage from './pages/UserSettingsPage';
import ReportsPage from './pages/ReportsPage';

export interface RouteObject {
    paths: string[];
    element: ReactElement;
    roles?: string[];
}

const routes = [
    {
        paths: ['/home'],
        element: <HomePage />,
    },
    {
        paths: ['/insights'],
        element: <InsightsPage />,
    },
    {
        paths: ['/launchpad'],
        element: <LaunchpadPage />,
    },
    {
        paths: ['/kiln', '/kiln'],
        element: <KilnProfilePage />,
    },
    {
        paths: ['/overview'],
        element: <OverviewPage />,
    },
    {
        paths: ['/admin/lining'],
        element: <AdminLiningPage />,
        roles: ['Admin.ReadWrite'],
    },
    {
        paths: ['/admin/kiln'],
        element: <AdminKilnPage />,
        roles: ['Admin.ReadWrite'],
    },
    {
        paths: ['/settings'],
        element: <UserSettingsPage />,
    },
    {
        paths: ['/reports'],
        element: <ReportsPage />,
    },
];

export default routes;
