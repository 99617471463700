import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllUserSettings } from '@app/store/userSettings/reducer';

export const useUserSettings = (): void => {
    const { isFetching, lastUpdated, settings } = useAppSelector(
        (state) => state.userSettings
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && settings.length === 0 && !lastUpdated) {
            dispatch(getAllUserSettings());
        }
    }, []);
};
