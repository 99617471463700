import { ZoneModel } from '@app/models/ZoneModel';
import { ZoneService } from '@app/services/ZoneService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_ZONE_REQUEST = `${window.env.__REDUX_PATH__}/zone/GET_ZONE_REQUEST`;
const GET_ZONE_SUCCESS = `${window.env.__REDUX_PATH__}/zone/GET_ZONE_SUCCESS`;
const GET_ZONE_FAILURE = `${window.env.__REDUX_PATH__}/zone/GET_ZONE_FAILURE`;

export interface ZoneState extends BaseState {
    zones: ZoneModel[];
}

// Action Types
interface GetZoneRequestAction {
    type: typeof GET_ZONE_REQUEST;
}

interface GetZoneSuccessAction {
    type: typeof GET_ZONE_SUCCESS;
    payload?: ZoneModel[];
}

interface GetZoneFailureAction {
    type: typeof GET_ZONE_FAILURE;
    payload?: string;
}

type ZoneActionTypes =
    | GetZoneRequestAction
    | GetZoneSuccessAction
    | GetZoneFailureAction;

// Reducer
const initialState: ZoneState = {
    zones: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getZoneRequestReducer = (state: ZoneState): ZoneState => {
    return {
        ...state,
        zones: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getZoneSuccessReducer = (
    state: ZoneState,
    action: GetZoneSuccessAction
): ZoneState => {
    return {
        ...state,
        zones: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getZoneFailureReducer = (
    state: ZoneState,
    action: GetZoneFailureAction
): ZoneState => {
    return {
        ...state,
        zones: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: ZoneState = initialState,
    action: ZoneActionTypes
): ZoneState => {
    switch (action.type) {
        case GET_ZONE_REQUEST:
            return getZoneRequestReducer(state);
        case GET_ZONE_SUCCESS:
            return getZoneSuccessReducer(state, action as GetZoneSuccessAction);
        case GET_ZONE_FAILURE:
            return getZoneFailureReducer(state, action as GetZoneFailureAction);
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getZoneRequest = (): GetZoneRequestAction => {
    return {
        type: GET_ZONE_REQUEST,
    };
};

export const getZoneSuccess = (payload: ZoneModel[]): GetZoneSuccessAction => {
    return {
        type: GET_ZONE_SUCCESS,
        payload,
    };
};

export const getZoneFailure = (payload: string): GetZoneFailureAction => {
    return {
        type: GET_ZONE_FAILURE,
        payload,
    };
};

export const getAllZone = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getZoneRequest());

        try {
            const service = new ZoneService();
            const response = await service.getAll();

            dispatch(getZoneSuccess(response));
            dispatch(
                showMessage({
                    message: 'Zone load successfully!',
                    title: 'Zone Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getZoneFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Zone could not be load!',
                        title: 'Zone Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getZone = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getZoneRequest());

        try {
            const service = new ZoneService();
            const response = await service.get(params);

            dispatch(getZoneSuccess(response));
            dispatch(
                showMessage({
                    message: 'Zone load successfully!',
                    title: 'Zone Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getZoneFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Zone could not be load!',
                        title: 'Zone Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
