import { ShapeStandardModel } from '@app/models/ShapeStandardModel';
import { ShapeStandardRepository } from '@app/repositories/ShapeStandardRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class ShapeStandardService extends ServiceBase<
    ShapeStandardModel,
    ShapeStandardRepository<ShapeStandardModel>
> {
    public constructor() {
        super(new ShapeStandardRepository());
    }
}
