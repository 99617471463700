import { memo } from 'react';
import KilnBundle from '@app/molecules/KilnBundle';
import { KilnStatusModel } from '@app/models/KilnStatusModel';
import KilnStatus from './KilnStatus';

type IProps = {
    expanded: number;
    setExpanded: Function;
    setIdKilnStatus: Function;
    idKilnStatus: string;
    setKilnName: Function;
    kilnName?: string;
    setSalesPerson: Function;
    salesPerson?: string;
    setSalesOrg: Function;
    salesOrg?: string;
    kilnStatus: KilnStatusModel[];
};

const KilnStatusBundle = (props: IProps) => (
    <KilnBundle
        key='bundle-status'
        name='bundle-status'
        setExpanded={props.setExpanded}
        expanded={props.expanded}
        index={0}
        title='Kiln Status'
    >
        <KilnStatus
            setIdKilnStatus={props.setIdKilnStatus}
            idKilnStatus={props.idKilnStatus}
            setKilnName={props.setKilnName}
            kilnName={props.kilnName}
            setSalesPerson={props.setSalesPerson}
            salesPerson={props.salesPerson}
            setSalesOrg={props.setSalesOrg}
            salesOrg={props.salesOrg}
            kilnStatus={props.kilnStatus}
        />
    </KilnBundle>
);

export default memo(KilnStatusBundle);
