import PizzaChart, { ChartRegistry } from '@app/atoms/PizzaChart';
import { memo } from 'react';
import CardFilter from '@app/organisms/CardFilter/CardFilter';
import {
    OptionMap,
    DisabledFilter,
} from '@app/organisms/CardFilter/FilterModal';
import TotalTonnageInfo from './TotalTonnageInfo';

type MarketShareTabProps = {
    chartData: ChartRegistry[];
    options: OptionMap;
    columns: string[];
    total: number;
    years: string[];
    year?: string;
    setYear: Function;
    disabledFilters?: DisabledFilter;
};

const MarketShareTab = (props: MarketShareTabProps) => (
    <CardFilter
        width='532px'
        height='400px'
        title='Market Share'
        years={props.years}
        setYear={props.setYear}
        year={props.year}
        data={props.options}
        columns={props.columns}
        disabledFilters={props.disabledFilters}
    >
        <PizzaChart data={props.chartData} />
        <TotalTonnageInfo total={props.total} />
    </CardFilter>
);

export default memo(MarketShareTab);
