import { SalesUnitModel } from '@app/models/SalesUnitModel';
import { SalesUnitRepository } from '@app/repositories/SalesUnitRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class SalesUnitService extends ServiceBase<
    SalesUnitModel,
    SalesUnitRepository<SalesUnitModel>
> {
    public constructor() {
        super(new SalesUnitRepository());
    }
}
