import { createContext, memo, useEffect, useState } from 'react';
import { OptionMap } from '@app/organisms/CardFilter/FilterModal';

type IProviderProps = {
    selected: OptionMap;
    setSelected: (value: OptionMap) => void;
    children?: any;
};

export const INITIAL_VALUES: IProviderProps = {
    selected: {},
    setSelected: () => {},
};

export const FilterModalContextManager = createContext(INITIAL_VALUES);

const FilterModalContext = (props: IProviderProps) => {
    const [selected, setSelected] = useState<OptionMap>(
        props.selected ?? INITIAL_VALUES.selected
    );

    useEffect(() => {
        if (selected !== props.selected) {
            setSelected(props.selected);
        }
    }, [props.selected]);

    return (
        <FilterModalContextManager.Provider
            value={{
                selected,
                setSelected,
            }}
        >
            {props.children}
        </FilterModalContextManager.Provider>
    );
};

export default memo(FilterModalContext);
