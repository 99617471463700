import { RepositoryBase } from '@app/repositories/RepositoryBase';
export class CampaignRepository<
    CampaignModel
> extends RepositoryBase<CampaignModel> {
    public constructor() {
        super('campaign');
    }

    public async getByKilnId(id: string): Promise<CampaignModel[]> {
        return this.handleResponse(await this.api.get(`/kiln/${id}`));
    }

    public async getYears(): Promise<string[]> {
        return this.handleResponse(await this.api.get(`/years`));
    }

    public async getYearsByCustomerId(id: string): Promise<string[]> {
        return this.handleResponse(await this.api.get(`/years/${id}`));
    }

    public async getLastByKilnId(id: string): Promise<CampaignModel> {
        return this.handleResponse(await this.api.get(`/kiln/${id}/last`));
    }
}
