import { memo, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { SectionModel } from '@app/models/SectionModel';
import { SectionTypeModel } from '@app/models/SectionTypeModel';
import KilnBuilderHeader from '../KilnBuilderHeader';
import KilnSection from './KilnSection';
import sectionDrawing from '@app/sectionDrawing.png';
import { Option } from '@app/organisms/CardFilter/FilterModal';

type IProps = {
    setSections: Function;
    sections: SectionModel[];
    sectionTypes: SectionTypeModel[];
    length: number;
    diameter: number;
};

const KilnSections = (props: IProps) => {
    const types = useMemo(
        () =>
            props.sections.reduce((acc: Option[], s, i) => {
                const key =
                    s.idSectionType === '0409b6a6-10a5-432a-95d3-2d7564ccad75'
                        ? 'Non-Conic'
                        : 'Conic';
                const value = key === 'Conic' ? 2 : 1;
                const previousValue = i > 0 ? acc[i - 1].value : 0;
                const sum = previousValue + value;

                return [
                    ...acc,
                    {
                        label: key,
                        value: sum,
                    },
                ];
            }, []),
        [props.sections]
    );

    const handleChange = (section: SectionModel, index: number) => {
        props.sections.splice(index, 1, section);
        props.setSections(props.sections.slice());
    };

    const handleAddition = (isConic: boolean) => {
        const last = props.sections[props.sections.length - 1];

        const newSection = {
            ...last,
            start: last?.end ?? 0,
            end: last?.end ? last.end + 1 : props.length,
            diameter: last?.diameter2 ?? props.diameter,
            diameter2: last?.diameter2 ?? props.diameter,
            idSectionType: isConic
                ? 'db71fcd5-bd06-43d8-9d35-c248b5f4af0d'
                : '0409b6a6-10a5-432a-95d3-2d7564ccad75',
        };

        props.sections.push(newSection);
        props.setSections(props.sections.slice());
    };

    const handleDeletion = (index: number) => {
        props.sections.splice(index, 1);
        props.setSections(props.sections.slice());
    };

    const renderEmptyConic = useCallback(
        () =>
            !props.sections.some(
                (section) =>
                    section.idSectionType ===
                    'db71fcd5-bd06-43d8-9d35-c248b5f4af0d'
            ) ? (
                <KilnBuilderHeader
                    title='Conical'
                    hasAdd
                    handleAddition={() => {
                        handleAddition(true);
                    }}
                    index={props.sections.length}
                />
            ) : (
                <></>
            ),
        [props.sections]
    );

    const renderEmptyNonConic = useCallback(
        () =>
            !props.sections.some(
                (section) =>
                    section.idSectionType ===
                    '0409b6a6-10a5-432a-95d3-2d7564ccad75'
            ) ? (
                <KilnBuilderHeader
                    title='Non-Conical'
                    hasAdd
                    handleAddition={() => {
                        handleAddition(false);
                    }}
                    index={props.sections.length}
                />
            ) : (
                <></>
            ),
        [props.sections]
    );

    const renderSections = useCallback(
        () =>
            props.sections.map((section, index) => {
                const sectionType = types[index] ?? {};
                const isConic = sectionType.label === 'Conic';
                const diameterIndex = sectionType.value;

                return (
                    <KilnSection
                        key={`section-${
                            isConic ? 'conic' : 'non-conic'
                        }-${index}`}
                        index={index}
                        diameterIndex={diameterIndex}
                        section={section}
                        length={props.length}
                        diameter={props.diameter}
                        isConic={isConic}
                        handleChange={handleChange}
                        handleDeletion={handleDeletion}
                        handleAddition={handleAddition}
                    />
                );
            }),
        [props.sections, props.length, props.diameter, types]
    );

    const renderDrawing = useCallback(
        () => (
            <Grid container justifyContent='center' alignItems='center'>
                <img
                    src={sectionDrawing}
                    alt='Kiln showing conic and non-conic sections'
                />
            </Grid>
        ),
        []
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            {renderDrawing()}
            {renderEmptyNonConic()}
            {renderEmptyConic()}
            {renderSections()}
        </Box>
    );
};

export default memo(KilnSections);
