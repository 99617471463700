import CardFilterContext, {
    INITIAL_VALUES as initialApplied,
} from '@app/contexts/CardFilterContext';
import FilterModalContext, {
    INITIAL_VALUES as initialSelected,
} from '@app/contexts/FilterModalContext';
import { CustomerModel } from '@app/models/CustomerModel';
import { memo, useMemo } from 'react';
import OverviewInsightsTab from './OverviewInsightsTab';

type ICustomerTab = {
    customer: CustomerModel;
};

const InsightsTab = (props: ICustomerTab) => {
    const initialCustomerFilter = useMemo(
        () => ({
            customer: [
                { value: props.customer.id, label: props.customer.name },
            ],
        }),
        [props.customer]
    );
    return (
        <CardFilterContext {...initialApplied} applied={initialCustomerFilter}>
            <FilterModalContext
                {...initialSelected}
                selected={initialCustomerFilter}
            >
                <OverviewInsightsTab minimumFilter={initialCustomerFilter} />
            </FilterModalContext>
        </CardFilterContext>
    );
};

export default memo(InsightsTab);
