import { RepositoryBase } from '@app/repositories/RepositoryBase';

export class LiningChartRepository<LiningDataModel> extends RepositoryBase<LiningDataModel> {
    public constructor() {
        super('liningChart');
    }

    public async getByCampaignId(id: string): Promise<LiningDataModel[]> {
        return this.handleResponse(await this.api.get(`/campaign/${id}`));
    }
    
    public async createMultiple(linings: LiningDataModel[]): Promise<LiningDataModel[]> {
        return this.handleResponse(await this.api.post(`/createMultiple`, linings));
    }
}
