import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CampaignModel } from '@app/models/CampaignModel';
import { CampaignService } from '@app/services/CampaignService';
import { setMessage } from '@app/store/message/reducer';
import UriUtils from '@app/utils/UriUtils';
import { useMsal } from '@azure/msal-react';
import { Add, Edit, ArrowForward, Delete } from '@mui/icons-material';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '@app/theme';

type IProps = {
    id: string;
    setOpenNew: (value: boolean) => void;
    setOpenDelete: (value: boolean) => void;
    setLastCampaign: (value: CampaignModel) => void;
    setKilnProfileId: (value: string) => void;
};

const KilnProfileListRowActions = (props: IProps) => {
    const { accounts } = useMsal();

    const dispatch = useAppDispatch();
    const { id, setOpenNew, setOpenDelete, setLastCampaign, setKilnProfileId } =
        props;
    const selectedCustomer = useAppSelector(
        (state) => state.customer.selectedCustomer
    );

    const campaignService = useMemo(() => new CampaignService(), []);
    const navigate = useNavigate();

    const handleNewCampaign = async () => {
        try {
            const campaign = await campaignService.getLastByKilnId(id);
            dispatch(
                setMessage({
                    message: '',
                    title: 'Got last Lining Chart!',
                    color: 'success',
                    open: true,
                })
            );
            setLastCampaign(campaign);
            setKilnProfileId(id);
            setOpenNew(true);
        } catch (e) {
            setMessage({
                message: '',
                title: 'Could not get last Lining Chart!',
                color: 'error',
                open: true,
            });
        }
    };

    const handleDeleteProfile = () => {
        setKilnProfileId(id);
        setOpenDelete(true);
    };

    return (
        <Grid container justifyContent='space-evenly'>
            <Tooltip title='Edit Kiln Profile'>
                <IconButton
                    onClick={() =>
                        navigate(
                            UriUtils.stringifyQueryObject('/kiln', {
                                customerId: selectedCustomer?.id,
                                kilnProfileId: id,
                                isEdit: true,
                            })
                        )
                    }
                    sx={{ border: 'none', backgroundColor: 'inherit' }}
                >
                    <Edit />
                </IconButton>
            </Tooltip>
            <Tooltip title='New Lining Chart'>
                <IconButton
                    onClick={handleNewCampaign}
                    sx={{ border: 'none', backgroundColor: 'inherit' }}
                >
                    <Add />
                </IconButton>
            </Tooltip>
            <Tooltip title='View Linings'>
                <IconButton
                    onClick={() =>
                        navigate(
                            UriUtils.stringifyQueryObject('/overview', {
                                customerId: selectedCustomer?.id,
                                kilnProfileId: id,
                                view: 'campaigns',
                            })
                        )
                    }
                    sx={{ border: 'none', backgroundColor: 'inherit' }}
                >
                    <ArrowForward />
                </IconButton>
            </Tooltip>
            {accounts[0].idTokenClaims?.roles?.some(
                (role) => role === 'Admin.ReadWrite'
            ) ? (
                <Tooltip title='Delete Kiln'>
                    <IconButton
                        onClick={handleDeleteProfile}
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                    >
                        <Delete sx={{ color: colors['Red-RHIM'] }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default KilnProfileListRowActions;
