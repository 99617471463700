import { MPGModel } from '@app/models/MPGModel';
import { MPGService } from '@app/services/MPGService';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { Add, Edit } from '@mui/icons-material';
import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import Modal from '@app/molecules/Modal';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import StringUtils from '@app/utils/StringUtils';
import { useMPG } from '@app/hooks/useMPG';
import { getAllMPG } from '@app/store/mpg/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';

const INITIAL_STATE: MPGModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    name: '',
    description: '',
};

const MPGsList = () => {
    const dispatch = useAppDispatch();
    const { MPGs } = useAppSelector((state) => state.mpg);
    useMPG();

    const MPGsService = useMemo(() => new MPGService(), []);

    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingMPG, setEditingMPG] = useState<MPGModel>({
        ...INITIAL_STATE,
    });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingMPG({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingMPG({
                ...editingMPG,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingMPG({
                ...editingMPG,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await MPGsService.create({
                    ...editingMPG,
                });
                dispatch(getAllMPG());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingMPG.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingMPG.description);

        return (
            <Modal
                title='New MPG'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingMPG?.name}
                    label='MPG'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingMPG?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingMPG, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingMPG({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingMPG({
                ...editingMPG,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingMPG({
                ...editingMPG,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await MPGsService.update({
                    ...editingMPG,
                });
                dispatch(getAllMPG());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await MPGsService.delete(editingMPG.id);
                dispatch(getAllMPG());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingMPG.name) ||
            StringUtils.isUndefinedOrWhiteSpace(editingMPG.description);

        return (
            <Modal
                title='Edit MPG'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingMPG?.name}
                    label='MPG'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingMPG?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingMPG, showModalEditing]);

    const getEditAction = useCallback((MPG: MPGModel) => {
        const handleClick = () => {
            setEditingMPG(MPG);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit MPG'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((MPG: MPGModel) => {
        const isChecked = MPG.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await MPGsService.update({
                    ...MPG,
                    enabled: !isChecked,
                });
                dispatch(getAllMPG());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable MPG'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW MPG
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'MPG', value: 'name' },
            {
                label: 'Created By',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            // { label: 'Description', value: 'description' },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={MPGs}
                    rowsPerPage={5}
                    searchColumns={['name']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(MPGsList);
