import { Button, Grid, Typography } from '@mui/material';
import {
    useCallback,
    useContext,
    useMemo,
    useState,
    useEffect,
    memo,
} from 'react';
import { MarketInsightsService } from '@app/services/MarketInsightsService';
import { useBrandName } from '@app/hooks/useBrandName';
import { useSalesUnit } from '@app/hooks/useSalesUnit';
import MarketInsightsModel from '@app/models/MarketInsightsModel';
import TabContainer from '@app/organisms/TabContainer';
import OverviewTab from '@app/organisms/MarketInsights/OverviewTab';
import MarketShareTab from '@app/organisms/MarketInsights/MarketShareTab';
import MarketSizeTab from '@app/organisms/MarketInsights/MarketSizeTab';
import { Download } from '@mui/icons-material';
import { CardFilterContextManager } from '@app/contexts/CardFilterContext';
import { useMPG } from '@app/hooks/useMPG';
import { useShapeStandard } from '@app/hooks/useShapeStandard';
import { useSupplier } from '@app/hooks/useSupplier';
import { useZone } from '@app/hooks/useZone';
import { useCustomer } from '@app/hooks/useCustomer';
import { SupplierModel } from '@app/models/SupplierModel';
import { colors } from '@app/theme';
import { Option } from '@app/organisms/CardFilter/FilterModal';
import { KilnProfileService } from '@app/services/KilnProfileService';
import { KilnProfileModel } from '@app/models/KilnProfileModel';
import { useKilnStatus } from '@app/hooks/useKilnStatus';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { ConversionType, convertToTon } from '@app/utils/ConvertUtils';
import { CampaignService } from '@app/services/CampaignService';
import NumberUtils from '@app/utils/NumberUtils';
import { getIsMetric } from '@app/utils/ConvertUtils';
import { useProcessType } from '@app/hooks/useProcessType';
import { CustomerModel } from '@app/models/CustomerModel';
import StringUtils from '@app/utils/StringUtils';
import { FilterModalContextManager } from '@app/contexts/FilterModalContext';

const MarketInsights = () => {
    const dispatch = useAppDispatch();
    const { applied, hasFilterApplied } = useContext(CardFilterContextManager);
    const { selected } = useContext(FilterModalContextManager);
    const [year, setYear] = useState<string>('All');
    const [years, setYears] = useState<string[]>([]);
    const [data, setData] = useState<MarketInsightsModel[]>([]);
    const [profiles, setProfiles] = useState<KilnProfileModel[]>([]);
    const [countryOptions, setCountryOptions] = useState<Option[]>([]);
    const [cityOptions, setCityOptions] = useState<Option[]>([]);
    const [keyAccountOptions, setKeyAccountOptions] = useState<Option[]>([]);
    const [customerOptions, setCustomerOptions] = useState<Option[]>([]);
    const [kilnNameOptions, setKilnNameOptions] = useState<Option[]>([]);
    const [customerSelected, setCustomerSelected] = useState<boolean>(false);

    // for futurue implementations
    // const compareStringsBase = (first: string, second: string): boolean =>
    //     first.localeCompare(second, undefined, { sensitivity: 'base' }) === 0;

    // const hasKey = (keys: Array<string>, value: string): boolean =>
    //     keys.some(key => compareStringsBase(key, value));

    const hasKey = (map: Map<any, any>, value: any): boolean => map.has(value);

    const getUnique = (options: Option[]) => {
        const result = [];
        const map = new Map();
        for (const option of options) {
            if (
                !hasKey(map, option.value) &&
                !StringUtils.isUndefinedOrWhiteSpace(option.value)
            ) {
                map.set(option.value.toString(), true);
                result.push({
                    value: option.value,
                    label: option.label,
                });
            }
        }
        return result;
    };

    const columns = useMemo(
        () => [
            'country',
            'city',
            'keyAccount',
            'customer',
            'kilnName',
            'zone',
            'mpg',
            'supplier',
            'shapeStandard',
            'salesUnit',
            'brandName',
            'kilnStatus',
            'processType',
        ],
        []
    );

    const {
        brandName,
        zone,
        mpg,
        supplier,
        shapeStandard,
        salesUnit,
        customer,
        kilnStatus,
        processType,
    } = useAppSelector((state) => state);

    useCustomer();
    useBrandName();
    useZone();
    useMPG();
    useSupplier();
    useShapeStandard();
    useSalesUnit();
    useKilnStatus();
    useProcessType();

    const serviceKiln = useMemo(() => new KilnProfileService(), []);
    const serviceCampaign = useMemo(() => new CampaignService(), []);
    const serviceMkt = useMemo(() => new MarketInsightsService(), []);

    useEffect(() => {
        const initialLoad = async () => {
            dispatch(setLoading(true));
            try {
                const response = await Promise.all([
                    serviceKiln.getAll(),
                    serviceCampaign.getYears(),
                ]);
                setProfiles(response[0]);
                setYears(response[1]);
                setKilnNameOptions(
                    response[0].map((kp) => ({
                        value: kp.id,
                        label: kp.kilnName,
                    }))
                );
                dispatch(
                    setMessage({
                        title: 'Success',
                        message: 'Successefully loaded data',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message: 'Could not load data',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        initialLoad();
    }, []);

    useEffect(() => {
        const fetchMktData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await serviceMkt.get({
                    year:
                        year !== 'All'
                            ? year
                            : new Date().getFullYear().toString(),
                });
                setData(
                    response.map((mkt) => {
                        const customerMkt = customer.customers.find(
                            (c) => c.id === mkt.idCustomer
                        );
                        return {
                            ...mkt,
                            country: customerMkt?.country ?? '',
                            city: customerMkt?.city ?? '',
                            keyAccount: customerMkt?.keyAccount ?? '',
                        };
                    })
                );
                setCountryOptions(
                    getUnique(
                        customer.customers.map((c) => ({
                            value: c.country,
                            label: c.country,
                        }))
                    )
                );
                setCityOptions(
                    getUnique(
                        customer.customers.map((c) => ({
                            value: c.city,
                            label: c.city,
                        }))
                    )
                );
                setKeyAccountOptions(
                    getUnique(
                        customer.customers.map((c) => ({
                            value: c.keyAccount,
                            label: c.keyAccount,
                        }))
                    )
                );
                setCustomerOptions(
                    customer.customers.map((c) => ({
                        value: c.id,
                        label: c.name,
                    }))
                );
                dispatch(
                    setMessage({
                        title: 'Success',
                        message: 'Successfully loaded data',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message: 'Could not load data',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        if (customer.customers?.length > 0) fetchMktData();
    }, [year, customer.customers]);

    const filteredData = useMemo(() => {
        const compareValues = (val: MarketInsightsModel, column: string) => {
            switch (column) {
                case 'country':
                    return applied[column].some(
                        (option) =>
                            // for future implementations
                            // compareStringsBase(val.country, option.value)
                            val.country === option.value
                    );
                case 'city':
                    return applied[column].some(
                        (option) =>
                            // for future implementations
                            // compareStringsBase(val.city, option.value)
                            val.city === option.value
                    );
                case 'customer':
                    return applied[column].some(
                        (option) => val.idCustomer === option.value
                    );
                case 'keyAccount':
                    return applied[column].some(
                        (option) => val.keyAccount === option.value
                    );
                case 'zone':
                    return applied[column].some(
                        (option) => val.idZone === option.value
                    );
                case 'mpg':
                    return applied[column].some(
                        (option) => val.idmpg === option.value
                    );
                case 'supplier':
                    return applied[column].some(
                        (option) => val.idSupplier === option.value
                    );
                case 'shapeStandard':
                    return applied[column].some(
                        (option) => val.idShapeStandard === option.value
                    );
                case 'salesUnit':
                    return applied[column].some(
                        (option) => val.idSalesUnit === option.value
                    );
                case 'brandName':
                    return applied[column].some(
                        (option) => val.idBrandName === option.value
                    );
                case 'kilnStatus':
                    return applied[column].some(
                        (option) => val.idKilnStatus === option.value
                    );
                case 'kilnName':
                    return applied[column].some(
                        (option) => val.idKilnProfile === option.value
                    );
                case 'processType':
                    return applied[column].some(
                        (option) => val.idProcessType === option.value
                    );
                default:
                    return true;
            }
        };

        if (hasFilterApplied)
            return data.filter((val: MarketInsightsModel) =>
                columns.every((column) =>
                    applied[column] && applied[column].length
                        ? compareValues(val, column)
                        : true
                )
            );

        return data;
    }, [applied, data, columns, hasFilterApplied]);

    const optionsMap = useMemo(
        () => ({
            country: countryOptions,
            city: cityOptions,
            keyAccount: keyAccountOptions,
            customer: customerOptions,
            kilnName: kilnNameOptions,
            brandName:
                brandName?.brands?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            supplier:
                supplier?.suppliers?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            shapeStandard:
                shapeStandard?.shapeStandards?.map((c) => ({
                    value: c.id,
                    label: c.shape,
                })) ?? [],
            salesUnit:
                salesUnit?.salesUnits?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            zone:
                zone?.zones?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            mpg:
                mpg?.MPGs?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            kilnStatus:
                kilnStatus?.kilnStatus?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
            processType:
                processType?.processTypes?.map((c) => ({
                    value: c.id,
                    label: c.name,
                })) ?? [],
        }),
        [
            brandName.brands,
            customer.customers,
            zone.zones,
            mpg.MPGs,
            supplier.suppliers,
            shapeStandard.shapeStandards,
            salesUnit.salesUnits,
            kilnStatus.kilnStatus,
            profiles,
            processType.processTypes,
            countryOptions,
            cityOptions,
            keyAccountOptions,
            customerOptions,
            kilnNameOptions,
        ]
    );

    const total = useMemo(
        () =>
            convertToTon(
                filteredData?.reduce(
                    (acc: number, val: MarketInsightsModel) =>
                        acc + val.sliceTonnage,
                    0
                ),
                ConversionType.Read
            ) ?? 0,
        [filteredData]
    );

    const chartData = useMemo(
        () =>
            supplier.suppliers?.map((supplier: SupplierModel) => {
                let value =
                    ((filteredData
                        .filter(
                            (val: MarketInsightsModel) =>
                                val.idSupplier === supplier.id
                        )
                        .reduce(
                            (acc: number, val: MarketInsightsModel) =>
                                acc + convertToTon(val.sliceTonnage, ConversionType.Read),
                            0
                        ) ?? 0) *
                        100) /
                    total;

                value = value > 100 ? 100 : value;
                value = value < 0 ? 0 : value;
                value = NumberUtils.isValidNumber(value) ? value : 0;
                value = Number.isFinite(value) ? value : 0;

                return {
                    name: supplier.name,
                    color: supplier.code,
                    unit: '%',
                    value: +value.toFixed(2),
                };
            }),
        [total, filteredData, supplier.suppliers]
    );

    const getMarkers = useCallback(() => {
        const getSimpleCircle = (radius: number) => (
            <circle
                opacity={0.7}
                fill={colors['Blue-RHIM']}
                stroke={colors['Grey-RHIM']}
                strokeWidth={1}
                r={radius}
            />
        );

        let max = 0;
        const countryMap = filteredData.reduce(
            (acc: { [key: string]: number }, val: MarketInsightsModel) => {
                const customerVal = customer.customers.find(
                    (c) => c.id === val.idCustomer
                );

                if (customerVal === undefined) return acc;

                let value = acc[customerVal.country]
                    ? acc[customerVal.country] + val.sliceTonnage
                    : val.sliceTonnage;

                if (value > max) max = value;

                value = value >= 0 ? value : 0;
                value = NumberUtils.isValidNumber(value) ? value : 0;
                value = Number.isFinite(value) ? value : 0;

                return {
                    ...acc,
                    [customerVal.country]: value,
                };
            },
            {}
        );

        return Object.keys(countryMap).map((country) => {
            let radius = (countryMap[country] * 20) / max;
            radius = radius < 2 ? 2 : radius;
            return {
                name: country,
                label: `${(countryMap[country] / 1000).toFixed(2)} kTON`,
                element: getSimpleCircle(radius),
            };
        });
    }, [filteredData, customer]);

    useEffect(() => {
        const countryFilter = selected?.country || [];
        const cityFilter = selected?.city || [];
        const keyAccountFilter = selected?.keyAccount || [];
        const customerFilter = selected?.customer || [];
        const kilnNameFilter = selected?.kilnName || [];

        var hasCustomer = selected?.customer?.length > 0 ?? false;
        setCustomerSelected(hasCustomer);

        const comparator = (
            value: any,
            filter: Option[],
            expression?: Function
        ): boolean =>
            filter.length > 0
                ? filter.some((c: Option) =>
                    expression !== undefined
                        ? expression(c, value)
                        : c.value === value
                )
                : true;

        setCountryOptions(
            getUnique(
                customer.customers
                    .filter(
                        (cm: CustomerModel) =>
                            comparator(cm.id, customerFilter) &&
                            comparator(cm.city, cityFilter) &&
                            comparator(cm.keyAccount, keyAccountFilter) &&
                            comparator(
                                cm.id,
                                kilnNameFilter,
                                (c: Option, value: any) =>
                                    profiles?.some(
                                        (p) =>
                                            p.id === c.value &&
                                            p.idCustomer === value
                                    )
                            )
                    )
                    .map((c) => ({
                        value: c.country,
                        label: c.country,
                    }))
            )
        );
        setCityOptions(
            getUnique(
                customer.customers
                    .filter(
                        (cm: CustomerModel) =>
                            comparator(cm.id, customerFilter) &&
                            comparator(cm.country, countryFilter) &&
                            comparator(cm.keyAccount, keyAccountFilter) &&
                            comparator(
                                cm.id,
                                kilnNameFilter,
                                (c: Option, value: any) =>
                                    profiles?.some(
                                        (p) =>
                                            p.id === c.value &&
                                            p.idCustomer === value
                                    )
                            )
                    )
                    .map((c) => ({
                        value: c.city,
                        label: c.city,
                    }))
            )
        );
        setKeyAccountOptions(
            getUnique(
                customer.customers
                    .filter(
                        (cm: CustomerModel) =>
                            comparator(cm.country, countryFilter) &&
                            comparator(cm.city, cityFilter) &&
                            comparator(cm.id, customerFilter) &&
                            comparator(
                                cm.id,
                                kilnNameFilter,
                                (c: Option, value: any) =>
                                    profiles?.some(
                                        (p) =>
                                            p.id === c.value &&
                                            p.idCustomer === value
                                    )
                            )
                    )
                    .map((c) => ({
                        value: c.keyAccount,
                        label: c.keyAccount,
                    }))
            )
        );
        setCustomerOptions(
            customer.customers
                .filter(
                    (cm: CustomerModel) =>
                        comparator(cm.country, countryFilter) &&
                        comparator(cm.city, cityFilter) &&
                        comparator(cm.keyAccount, keyAccountFilter) &&
                        comparator(
                            cm.id,
                            kilnNameFilter,
                            (c: Option, value: any) =>
                                profiles?.some(
                                    (p) =>
                                        p.id === c.value &&
                                        p.idCustomer === value
                                )
                        )
                )
                .map((c) => ({
                    value: c.id,
                    label: c.name,
                }))
        );
        setKilnNameOptions(
            profiles
                .filter(
                    (kp: KilnProfileModel) =>
                        comparator(kp.idCustomer, customerFilter) &&
                        comparator(
                            kp.idCustomer,
                            countryFilter,
                            (c: Option, value: any) =>
                                customer?.customers?.some(
                                    (p) =>
                                        p.country === c.value && p.id === value
                                )
                        ) &&
                        comparator(
                            kp.idCustomer,
                            cityFilter,
                            (c: Option, value: any) =>
                                customer?.customers?.some(
                                    (p) => p.city === c.value && p.id === value
                                )
                        ) &&
                        comparator(
                            kp.idCustomer,
                            keyAccountFilter,
                            (c: Option, value: any) =>
                                customer?.customers?.some(
                                    (p) =>
                                        p.keyAccount === c.value &&
                                        p.id === value
                                )
                        )
                )
                .map((c: KilnProfileModel) => ({
                    value: c.id,
                    label: c.kilnName,
                }))
        );
    }, [selected]);

    const tabs = useMemo(
        () => [
            {
                component: (
                    <OverviewTab
                        columns={columns}
                        chartData={chartData}
                        options={optionsMap}
                        countries={getMarkers()}
                        total={total}
                        years={years}
                        year={year}
                        setYear={setYear}
                        disabledFilters={
                            selected?.customer?.length === 1
                                ? {}
                                : {
                                    kilnName:
                                        'Select exactly one customer to enable this field',
                                }
                        }
                    />
                ),
                label: 'MARKET OVERVIEW',
            },
            {
                component: (
                    <MarketShareTab
                        columns={columns}
                        chartData={chartData}
                        options={optionsMap}
                        total={total}
                        years={years}
                        year={year}
                        setYear={setYear}
                        disabledFilters={
                            selected?.customer?.length === 1
                                ? {}
                                : {
                                    kilnName:
                                        'Select exactly one customer to enable this field',
                                }
                        }
                    />
                ),
                label: 'MARKET SHARE',
            },
            {
                component: (
                    <MarketSizeTab
                        columns={columns}
                        options={optionsMap}
                        countries={getMarkers()}
                        years={years}
                        year={year}
                        setYear={setYear}
                        disabledFilters={
                            selected?.customer?.length === 1
                                ? {}
                                : {
                                    kilnName:
                                        'Select exactly one customer to enable this field',
                                }
                        }
                    />
                ),
                label: 'MARKET SIZE',
            },
        ],
        [chartData, columns, optionsMap, years, customerSelected]
    );

    return (
        <Grid container margin='0' justifyContent='space-between'>
            <Grid item margin='0'>
                <Typography variant='h3' color='secondary'>
                    Market Insights
                </Typography>
            </Grid>
            <Grid item margin='0'>
                <Button
                    startIcon={<Download />}
                    variant='outlined'
                    color='secondary'
                    onClick={async () => {
                        dispatch(setLoading(true));
                        try {
                            await serviceMkt.queueReport({
                                'Kiln Name': applied['kilnName']?.map(
                                    (val) => val.label
                                ),
                                'Customer Name': applied['customer']?.map(
                                    (val) => val.label
                                ),
                                'Brand Name': applied['brandName']?.map(
                                    (val) => val.label
                                ),
                                Country: applied['country']?.map(
                                    (val) => val.label
                                ),
                                City: applied['city']?.map((val) => val.label),
                                'Kiln Zone': applied['zone']?.map(
                                    (val) => val.label
                                ),
                                'Key Account': applied['keyAccount']?.map(
                                    (val) => val.label
                                ),
                                MPG: applied['mpg']?.map((val) => val.label),
                                'Kiln Status': applied['kilnStatus']?.map(
                                    (val) => val.label
                                ),
                                Supplier: applied['supplier']?.map(
                                    (val) => val.label
                                ),
                                'Shape Standard': applied['shapeStandard']?.map(
                                    (val) => val.label
                                ),
                                'Sales Unit': applied['salesUnit']?.map(
                                    (val) => val.label
                                ),
                                'Process Type': applied['processType']?.map(
                                    (val) => val.label
                                ),
                                Year: [
                                    year !== 'All'
                                        ? year
                                        : new Date()
                                            .getUTCFullYear()
                                            .toString(),
                                ],
                                UOM: [(!getIsMetric()).toString()],
                            });
                            dispatch(
                                setMessage({
                                    title: 'Processing started successfully!',
                                    message:
                                        'We are processing your report and it will be available on the Reports page.',
                                    color: 'success',
                                    open: true,
                                    duration: 7000,
                                })
                            );
                        } catch (ex) {
                            dispatch(
                                setMessage({
                                    title: 'Error',
                                    message:
                                        'There was a problem with your report, please try exporting again later!',
                                    color: 'error',
                                    open: true,
                                })
                            );
                        }
                        dispatch(setLoading(false));
                    }}
                >
                    EXPORT DATA
                </Button>
            </Grid>
            <Grid container margin='0'>
                <TabContainer tabsList={tabs} active={1}></TabContainer>
            </Grid>
        </Grid>
    );
};

export default memo(MarketInsights);
