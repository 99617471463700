import { memo } from 'react';
import { Box, Grid } from '@mui/material';
import { KilnStatusModel } from '@app/models/KilnStatusModel';
import TextInput from '@app/atoms/TextInput';
import SelectInput from '@app/atoms/SelectInput';

type IProps = {
    setIdKilnStatus: Function;
    idKilnStatus: string;
    setKilnName: Function;
    kilnName?: string;
    setSalesPerson: Function;
    salesPerson?: string;
    setSalesOrg: Function;
    salesOrg?: string;
    kilnStatus: KilnStatusModel[];
};

const KilnStatus = (props: IProps) => {
    const handleChangeSalesOrg = (value: string) => {
        props.setSalesOrg(value);
    };

    const handleChangeSalesPerson = (value: string) => {
        props.setSalesPerson(value);
    };
    const handleChangeKilnName = (value: string) => {
        props.setKilnName(value);
    };
    const handleChangeKilnStatus = (value: string) => {
        props.setIdKilnStatus(value);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid container item>
                    <TextInput
                        title='Sales Org'
                        value={props.salesOrg}
                        onChange={handleChangeSalesOrg}
                    />
                </Grid>
                <Grid container item>
                    <TextInput
                        title='Sales Person'
                        value={props.salesPerson}
                        onChange={handleChangeSalesPerson}
                        required
                    />
                </Grid>
                <Grid item container>
                    <TextInput
                        title='Kiln Name'
                        value={props.kilnName}
                        onChange={handleChangeKilnName}
                        required
                    />
                </Grid>
                <Grid item container>
                    <SelectInput
                        title='Rotary Kiln Status'
                        value={props.idKilnStatus}
                        options={props.kilnStatus
                            .filter((x) => x.enabled)
                            .map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                        onChange={handleChangeKilnStatus}
                        required
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(KilnStatus);
