import { RepositoryBase } from '@app/repositories/RepositoryBase';
import { AxiosRequestConfig } from 'axios';

export class MarketInsightsRepository<
    MarketInsightsModel
> extends RepositoryBase<MarketInsightsModel> {
    public constructor() {
        super('marketInsights');
    }

    public async queueReport(filter: {}): Promise<string> {
        return this.handleResponse(await this.api.post(`/queue`, filter));
    }
}
