import StringUtils from './StringUtils';

const isValidNumber = (value?: any): boolean =>
    (typeof value === 'string' || typeof value === 'number') &&
    !Number.isNaN(value) &&
    !isNaN(+value) &&
    !StringUtils.isUndefinedOrWhiteSpace(value.toString());

const getNumber = (value?: any): number => {
    try {
        return isValidNumber(value) ? +value : 0;
    } catch (ex) {
        return value;
    }
};

const getNumberImperial = (value: number, unity?: string) => {
    switch (unity) {
        case 'ton/m³':
        case 'g/cm³':
            return value * 62.427961;
        case 'mm':
            return value * 0.03937008;
        case 'm':
            return value * 3.2808399;
        case 'ton':
            return value * 0.98420653;
        default:
            return value;
    }
};

const getNumberMetric = (value: number, unity?: string) => {
    switch (unity) {
        case 'lb/ft³':
            return value / 62.427961;
        case 'in':
            return value / 0.03937008;
        case 'ft':
            return value / 3.2808399;
        case 'ton':
            return value / 0.98420653;
        default:
            return value;
    }
};

const getUomPlaceholder = (
    metric: boolean,
    unitMetric?: string,
    unitImperial?: string
): string =>
    metric ? `In ${unitMetric ?? 'meters'}` : `In ${unitImperial ?? 'feet'}`;

const NumberUtils = {
    isValidNumber,
    getNumber,
    getNumberImperial,
    getNumberMetric,
    getUomPlaceholder,
};

export default NumberUtils;
