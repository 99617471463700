import TableEmptyRow from '@app/atoms/TableEmptyRow';
import TableBodyRows from '@app/molecules/TableBodyRows';
import TableFooterRow from '@app/molecules/TableFooterRow';
import TableHeaderRow from '@app/molecules/TableHeaderRow';
import { Search } from '@mui/icons-material';
import {
    TableContainer,
    Table,
    Grid,
    TextField,
    InputAdornment,
    SortDirection,
} from '@mui/material';
import {
    PropsWithChildren,
    useState,
    ReactElement,
    memo,
    useMemo,
} from 'react';

interface TableListProps {
    columns: Array<
        | string
        | {
              value: any;
              label: string;
              handle?: (value: any) => any;
              subtitle?: string;
              column?: string;
              disableSort?: boolean;
          }
    >;
    data: any[];
    rowsPerPage?: number;
    rowsPerPageOptions?: Array<number | { label: string; value: number }>;
    actions?: ReactElement;
    searchColumns?: string[];
    searchSibling?: ReactElement;
    onRowClick?: (value: any) => void;
    expandedColumns?: Array<
        | string
        | {
              value: any;
              label: string;
              handle?: (value: any) => any;
              subtitle?: string;
          }
    >;
    expandedTitle?: string;
    disableSort?: boolean;
}

const TableList = (props: PropsWithChildren<TableListProps>) => {
    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(props.rowsPerPage ?? 0);
    const [search, setSearch] = useState<string>('');
    const [order, setOrder] = useState<SortDirection>(false);
    const [orderBy, setOrderBy] = useState<string>();

    const compare = (a: any, b: any): number => {

       

        if (orderBy !== undefined && order !== false) {
            
           
            if(orderBy == 'updatedAt'){
                const date1 = new Date(a[orderBy])
                const date2 = new Date(b[orderBy])
              
                if(order === 'asc') return date1.getTime() - date2.getTime()
                if(order === 'desc') return date2.getTime() - date1.getTime()
               
            } else {
                if(a[orderBy] === null) a[orderBy] = ""
                if(b[orderBy] === null) b[orderBy] = ""

                if (order === 'asc') {
                    return a[orderBy]
                        .toLocaleString()
                        .localeCompare(b[orderBy].toLocaleString());
                } else if (order === 'desc') {
                    return b[orderBy]
                        .toLocaleString()
                        .localeCompare(a[orderBy].toLocaleString());
                }
            }
        }

        return 0;
    };

    const handleRequestSort = (property?: string) => {
        if (property) {
            const newOrder =
                order === 'asc' ? 'desc' : order === 'desc' ? false : 'asc';
            setOrder(newOrder);
            setOrderBy(property);
        } else {
            setOrder(false);
            setOrderBy(undefined);
        }
    };

    const filteredData = useMemo(
        () =>
            props.searchColumns?.length && props.data?.length
                ? props.data.filter((row) =>
                      props.searchColumns?.some((searchColumn) => {
                          if (row.hasOwnProperty(searchColumn))
                              return (row[searchColumn] ?? '')
                                  .toString()
                                  .toLowerCase()
                                  .includes(search.toLowerCase());

                          return row;
                      })
                  )
                : props.data,
        [props.searchColumns, props.data, search, page]
    );

    const paginatedData = useMemo(() => {
        const showAll = size === -1 || size > filteredData.length;
        const end =
            props.rowsPerPage && !showAll
                ? size * (page + 1)
                : filteredData.length;
        const start = props.rowsPerPage && !showAll ? end - size : 0;
        const sortedData =
            props.disableSort || !order
                ? filteredData
                : filteredData.slice().sort(compare);
        return sortedData.slice(start, end);
    }, [filteredData, order, orderBy, size]);

    const handleSearch = ({
        target: { value },
    }: {
        target: { value: string };
    }) => {
        if (value !== search) setSearch(value);
    };

    return (
        <Grid container margin={0}>
            {props.searchColumns !== undefined &&
                props.searchColumns.length > 0 && (
                    <Grid
                        container
                        item
                        margin='8px 0'
                        display='grid'
                        gap={props.searchSibling ? '8px' : '0'}
                        gridTemplateColumns={`auto ${
                            props.searchSibling ? 'max-content' : ''
                        }`}
                    >
                        <TextField
                            value={search}
                            placeholder='Search'
                            size='small'
                            onChange={handleSearch}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <Search color='disabled' />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {props.searchSibling !== undefined &&
                            props.searchSibling}
                    </Grid>
                )}
            <Grid container item margin={0}>
                <TableContainer>
                    <Table>
                        <TableHeaderRow
                            columns={props.columns}
                            hasActions={props.actions !== undefined}
                            onSort={handleRequestSort}
                            sorted={order}
                            sortedBy={orderBy}
                            isExpandable={
                                props.expandedColumns !== undefined &&
                                props.expandedColumns.length > 0
                            }
                            disableSort={props.disableSort}
                        />
                        {Boolean(props.data?.length) ? (
                            <TableBodyRows
                                expandedTitle={props.expandedTitle}
                                expandedColumns={props.expandedColumns}
                                columns={props.columns}
                                data={paginatedData}
                                actions={props.actions}
                                onRowClick={props.onRowClick}
                            />
                        ) : (
                            <TableEmptyRow colSpan={props.columns.length} />
                        )}
                        {props.rowsPerPage && (
                            <TableFooterRow
                                colSpan={props.columns.length}
                                paginationControl={{
                                    rowsPerPageOptions:
                                        props.rowsPerPageOptions,
                                    rowsPerPage: size,
                                    setRowsPerPage: setSize,
                                    page,
                                    setPage,
                                    count: filteredData.length,
                                }}
                            />
                        )}
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};

export default memo(TableList);
