import { ServiceBase } from '@app/services/ServiceBase';
import { KilnProfileRepository } from '@app/repositories/KilnProfileRepository';
import { KilnProfileModel } from '@app/models/KilnProfileModel';
import {
    ConversionType,
    convertToMeters,
    convertToTon,
} from '@app/utils/ConvertUtils';

export class KilnProfileService extends ServiceBase<
    KilnProfileModel,
    KilnProfileRepository<KilnProfileModel>
> {
    public constructor() {
        super(new KilnProfileRepository());
    }

    private convertModel(
        model: KilnProfileModel,
        conversion: ConversionType
    ): KilnProfileModel {
        model.diameter = convertToMeters(model.diameter, conversion);
        model.length = convertToMeters(model.length, conversion);
        model.clinkerCapacity = convertToTon(model.clinkerCapacity, conversion);

        if (model.girthGear) {
            model.girthGear = convertToMeters(model.girthGear, conversion);
        }

        return model;
    }

    public async getAll(): Promise<KilnProfileModel[]> {
        const profiles = await this.repository.getAll();
        return profiles.map((profile) =>
            this.convertModel(profile, ConversionType.Read)
        );
    }

    public async get<P>(params: P): Promise<KilnProfileModel[]> {
        const profiles = await this.repository.get(params);
        return profiles.map((entity) =>
            this.convertModel(entity, ConversionType.Read)
        );
    }

    public async getByCustomerId({
        customerId,
    }: {
        customerId: string;
    }): Promise<Array<KilnProfileModel>> {
        const kilnProfile = await this.repository.getByCustomerId(customerId);
        return kilnProfile.map((entity) =>
            this.convertModel(entity, ConversionType.Read)
        );
    }

    public async getById(id: string): Promise<KilnProfileModel> {
        const profile = await this.repository.getById(id);
        return this.convertModel(profile, ConversionType.Read);
    }

    public async update(entity: KilnProfileModel): Promise<KilnProfileModel> {
        return await this.repository.update(
            this.convertModel(entity, ConversionType.Write)
        );
    }

    public async create(entity: KilnProfileModel): Promise<KilnProfileModel> {
        return await this.repository.create(
            this.convertModel(entity, ConversionType.Write)
        );
    }
}
