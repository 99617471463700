import { CampaignModel } from '@app/models/CampaignModel';
import { CampaignRepository } from '@app/repositories/CampaignRepository';
import { ServiceBase } from '@app/services/ServiceBase';
import {
    ConversionType,
    convertToMeters,
    convertToMm,
} from '@app/utils/ConvertUtils';

export class CampaignService extends ServiceBase<
    CampaignModel,
    CampaignRepository<CampaignModel>
> {
    public constructor() {
        super(new CampaignRepository());
    }

    private convertModel(
        campaignModel: CampaignModel,
        conversion: ConversionType
    ): CampaignModel {
        if (campaignModel.liningChart) {
            campaignModel.liningChart.forEach((lining) => {
                lining.start = convertToMeters(lining.start, conversion);
                lining.end = convertToMeters(lining.end, conversion);
                lining.brickThickness = convertToMm(
                    lining.brickThickness,
                    conversion
                );
            });
        }

        return campaignModel;
    }
    public async getById(id: string): Promise<CampaignModel> {
        const campaignModel = await this.repository.getById(id);
        return this.convertModel(campaignModel, ConversionType.Read);
    }

    public async getByKilnId(id: string): Promise<CampaignModel[]> {
        const campaigns = await this.repository.getByKilnId(id);
        return campaigns.map((campaignModel) =>
            this.convertModel(campaignModel, ConversionType.Read)
        );
    }

    public async getYears(): Promise<string[]> {
        return await this.repository.getYears();
    }

    public async getYearsByCustomerId(id: string): Promise<string[]> {
        return await this.repository.getYearsByCustomerId(id);
    }

    public async getLastByKilnId(id: string): Promise<CampaignModel> {
        return await this.repository.getLastByKilnId(id);
    }
}
