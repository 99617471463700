import { CampaignModel } from '@app/models/CampaignModel';
import UriUtils from '@app/utils/UriUtils';
import { Button, Typography } from '@mui/material';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '@app/molecules/Modal';
import StringUtils from '@app/utils/StringUtils';

type IProps = {
    kilnProfileId?: string;
    customerId: string;
    open: boolean;
    setOpen: (value: boolean) => void;
    lastCampaign?: CampaignModel;
};

const NewCampaignModal = (props: IProps) => {
    const { kilnProfileId, customerId, open, setOpen, lastCampaign } = props;
    const navigate = useNavigate();

    const handleConfirm = async (copyLast: boolean = false) => {
        navigate(
            UriUtils.stringifyQueryObject('/overview', {
                view: 'linings',
                customerId,
                kilnProfileId,
                isCopy: copyLast,
                campaignId: copyLast ? lastCampaign?.id : undefined,
                isNew: true,
            })
        );
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCancel = () => {
        handleClose();
    };

    return (
        <Modal
            title='Do you want to copy the last campaign?'
            handleClose={handleClose}
            handleConfirm={async () => {
                await handleConfirm();
            }}
            handleCancel={handleCancel}
            confirmTitle='BLANK TABLE'
            showModal={open}
            extraButton={
                <Button
                    variant='contained'
                    color='secondary'
                    size='large'
                    onClick={async () => {
                        await handleConfirm(true);
                    }}
                    disabled={
                        !lastCampaign ||
                        StringUtils.isUndefinedOrWhiteSpace(kilnProfileId)
                    }
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        COPY
                    </Typography>
                </Button>
            }
        >
            You can start with the field filled in with the previous information
            and make the necessary changes or start with the blank table.
        </Modal>
    );
};

export default memo(NewCampaignModal);
