import { memo, useCallback, useMemo, useState } from 'react';
import TableList from '@app/organisms/TableList';
import LiningsListRowActions from './LiningsListRowActions';
import useRequest from '@app/hooks/useRequest';
import { CampaignService } from '@app/services/CampaignService';
import { CampaignModel } from '@app/models/CampaignModel';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import LiningChartDrawing, {
    LiningDrawingSlice,
} from '@app/molecules/LiningChartDrawing';
import { useBrandName } from '@app/hooks/useBrandName';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { LiningDataModel } from '@app/models/LiningDataModel';
import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import UriUtils from '@app/utils/UriUtils';
import { Add, NavigateNext } from '@mui/icons-material';
import { colors } from '@app/theme';
import DateUtils from '@app/utils/DateUtils';
import NewCampaignModal from '../NewCampaignModal';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import Modal from '@app/molecules/Modal';
import NumberUtils from '@app/utils/NumberUtils';
import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';

type BrandMap = {
    [key: string]: { name: string; code: string };
};

interface LiningDataModelExtended extends LiningDataModel {
    installDate: Date;
}

const LiningsList = (kiln: KilnProfileStructureModel) => {
    const dispatch = useAppDispatch();
    const [campaignId, setCampaignId] = useState<string>();

    useUserSettings();

    const [openNew, setOpenNew] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { customerId, kilnProfileId } = UriUtils.parseQueryString(
        location.search
    );

    const brandName = useAppSelector((state) => state.brandName);
    useBrandName();

    const serviceCampaign = useMemo(() => new CampaignService(), []);
    const data: CampaignModel[] = useRequest({
        service: serviceCampaign,
        method: serviceCampaign.getByKilnId,
        params: kilnProfileId,
        default: [],
    });

    const handleDelete = async () => {
        dispatch(setLoading(true));
        if (campaignId !== undefined) {
            try {
                await serviceCampaign.delete(campaignId);

                const index = data.findIndex((row) => row.id === campaignId);
                data.splice(index, 1);

                dispatch(
                    setMessage({
                        message: 'Successfully deleted the lining.',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: 'Could not delete the lining.',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
        setOpenDelete(false);
        dispatch(setLoading(false));
    };

    const columns = useMemo(
        () => [
            {
                label: 'Installation Date',
                value: 'installDate',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Last Updated By',
                value: TableBodyRowSystem.ROW,
                handle: (value: CampaignModel) =>
                    value.updatedBy ?? value.createdBy,
                column: 'updatedBy',
            },
            {
                label: 'Lining Chart Updated On',
                value: TableBodyRowSystem.ROW,
                handle: (value: CampaignModel) =>
                    value.updatedAt
                        ? DateUtils.formatUTCDate(value.updatedAt)
                        : DateUtils.formatUTCDate(value.createdAt),
                column: 'updatedAt',
            },
            {
                label: '',
                value: 'id',
                handle: (value: string) => (
                    <LiningsListRowActions
                        campaignId={value}
                        handleDelete={setCampaignId}
                        setOpenDelete={setOpenDelete}
                        kilnProfileId={kilnProfileId as string}
                    />
                ),
                disableSort: true,
            },
        ],
        [data, kilnProfileId]
    );

    const breadcrumbs = useMemo(
        () => [
            <Link
                key='kilns-breadcrumb-piece'
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', { customerId })}
            >
                Kiln Overview
            </Link>,
            <Link
                key='campaigns-breadcrumb-piece'
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', {
                    view: 'campaigns',
                    customerId,
                    kilnProfileId,
                })}
            >
                {kiln?.kilnName}
            </Link>,
        ],
        [kiln, customerId]
    );

    const sorted: LiningDataModelExtended[] = useMemo(
        () =>
            data
                .slice()
                .sort(
                    (a: CampaignModel, b: CampaignModel) =>
                        new Date(a.installDate).getTime() -
                        new Date(b.installDate).getTime()
                )
                .flatMap(
                    (campaign: CampaignModel) =>
                        campaign.liningChart?.map((lining) => ({
                            ...lining,
                            installDate: campaign.installDate,
                        })) ?? []
                ) ?? [],
        [data, kiln]
    );

    const brandMap: BrandMap = useMemo(
        () =>
            brandName.brands?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: { code: j.code, name: j.name },
                }),
                {}
            ),
        [brandName]
    );

    const renderDrawing = useCallback(() => {
        const kilnLength = Math.ceil(kiln?.length);
        const arrayLength = kilnLength * 5;
        const current: LiningDrawingSlice[] = new Array(arrayLength).fill({
            code: colors['Grey5-RHIM'],
            brandName: 'unknown',
        });

        if (sorted && sorted.length > 0) {
            sorted.forEach((lining) => {
                const brand = brandMap[lining.idBrandName] ?? {};
                const length = Math.abs(lining.end - lining.start);
                const meter = Math.ceil(length * 5);
                const newSlices: LiningDrawingSlice[] = new Array(meter).fill({
                    code: brand.code ?? '',
                    brandName: brand.name ?? '',
                    start: lining.start.toFixed(2),
                    end: lining.end.toFixed(2),
                    installDate: DateUtils.formatCampaignMonthYear(
                        lining.installDate
                    ),
                });
                const start = Math.ceil(lining.start * 5);
                current.splice(start, meter, ...newSlices);
            });
        }

        if (
            kiln.girthGear !== undefined &&
            NumberUtils.isValidNumber(kiln.girthGear)
        ) {
            const arrayIndex = Math.ceil(kiln.girthGear * 5) - 1;
            current[arrayIndex] = {
                ...current[arrayIndex],
                girthGear: kiln.girthGear.toFixed(2),
            };
        }

        if (kiln.tires !== undefined && kiln.tires.length > 0) {
            kiln.tires.forEach((tire) => {
                const arrayIndex = Math.ceil(tire.position * 5) - 1;
                current[arrayIndex] = {
                    ...current[arrayIndex],
                    tire: tire.position.toFixed(2),
                };
            });
        }

        return <LiningChartDrawing linings={current} kilnLength={kilnLength} />;
    }, [data, kiln, sorted, brandMap, kilnProfileId, customerId]);

    const renderTableList = useCallback(
        () => (
            <TableList
                columns={columns}
                data={data}
                rowsPerPage={5}
                searchColumns={['updatedBy']}
            />
        ),
        [data, columns]
    );

    const renderBreadcrumbs = useCallback(
        () => (
            <Grid item margin='0'>
                <Breadcrumbs
                    separator={<NavigateNext />}
                    aria-label='breadcrumb'
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
        ),
        [breadcrumbs]
    );

    const renderButtons = useCallback(
        () => (
            <Grid
                item
                margin='0'
                justifyContent='flex-end'
                display='flex'
                columnGap='8px'
                flexWrap='nowrap'
            >
                <Button
                    variant='outlined'
                    color='secondary'
                    size='large'
                    onClick={() => {
                        navigate(
                            UriUtils.stringifyQueryObject('/overview', {
                                view: 'history',
                                customerId,
                                kilnProfileId,
                            })
                        );
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        VIEW LINING HISTORY
                    </Typography>
                </Button>
                <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<Add fontSize='small' />}
                    size='large'
                    onClick={() => {
                        setOpenNew(true);
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        NEW LINING CHART
                    </Typography>
                </Button>
            </Grid>
        ),
        []
    );

    const lastCampaign = useMemo(() => data[0], [data]);

    const renderNewCampaignModal = useCallback(
        () => (
            <NewCampaignModal
                open={openNew}
                setOpen={setOpenNew}
                kilnProfileId={kilnProfileId as string}
                customerId={customerId as string}
                lastCampaign={lastCampaign}
            />
        ),
        [lastCampaign, openNew, kilnProfileId, customerId]
    );

    const renderDeleteCampaignModal = useCallback(
        () => (
            <Modal
                title='You are deleting a Lining Chart, do you want to confirm this action?'
                showModal={openDelete}
                handleClose={() => {
                    setOpenDelete(false);
                }}
                handleCancel={() => {
                    setOpenDelete(false);
                }}
                handleConfirm={async () => {
                    await handleDelete();
                }}
                confirmTitle='CONFIRM'
            />
        ),
        [kilnProfileId, openDelete, customerId, campaignId]
    );

    return (
        <Grid container margin='0' rowGap='8px'>
            <Grid
                container
                margin='0'
                justifyContent='space-between'
                alignItems='center'
            >
                {renderBreadcrumbs()}
                {renderButtons()}
            </Grid>
            {renderDrawing()}
            {renderTableList()}
            {renderNewCampaignModal()}
            {renderDeleteCampaignModal()}
        </Grid>
    );
};

export default memo(LiningsList);
