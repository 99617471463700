import {
    parse,
    stringifyUrl,
    ParseOptions,
    ParsedQuery,
    StringifiableRecord,
    StringifyOptions,
} from 'query-string';

const parseQueryString = (
    text: string = '-',
    options?: ParseOptions
): ParsedQuery<string> =>
    parse(
        text,
        options ?? {
            parseNumbers: true,
            parseBooleans: true,
        }
    );

const stringifyQueryObject = (
    path: string,
    params: StringifiableRecord,
    options?: StringifyOptions
): string =>
    stringifyUrl(
        {
            url: path,
            query: params,
        },
        options ?? {
            encode: false,
            skipNull: true,
            skipEmptyString: true,
        }
    );

const UriUtils = {
    parseQueryString,
    stringifyQueryObject,
};

export default UriUtils;
