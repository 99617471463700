import {
    Grid,
    FormControl,
    InputLabel,
    SvgIcon,
    Typography,
} from '@mui/material';
import { colors } from '@app/theme';
import { ReactComponent as weightIcon } from '@app/weight.svg';
import { useUserSettings } from '@app/hooks/useUserSettings';

type IProps = {
    total: number;
};

const TotalTonnageInfo = (props: IProps) => {
    useUserSettings();

    return (
        <Grid item margin='0' display='flex' justifyContent='flex-end'>
            <FormControl
                sx={{
                    border: `1px solid ${colors['Red-RHIM']}`,
                    borderRadius: '4px',
                    width: '200px',
                    height: '60px',
                }}
            >
                <InputLabel
                    shrink
                    sx={{
                        backgroundColor: colors['White-RHIM'],
                        color: colors['Red-RHIM'],
                        margin: '0 4px',
                    }}
                >
                    Total Tonnage
                </InputLabel>
                <Grid
                    container
                    display='grid'
                    gridTemplateColumns='auto auto auto'
                    justifyContent='space-evenly'
                    alignItems='center'
                    margin='0'
                    height='60px'
                >
                    <SvgIcon
                        component={weightIcon}
                        sx={{
                            color: colors['Blue-RHIM'],
                            width: '50px',
                            height: '50px',
                            display: 'block',
                            marginTop: '8px',
                        }}
                    />
                    <Typography variant='h5' color='secondary'>
                        {`${(props.total / 1000).toFixed(2)} kTON`}
                    </Typography>
                </Grid>
            </FormControl>
        </Grid>
    );
};

export default TotalTonnageInfo;
