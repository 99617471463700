import { store } from '@app/store';
import NumberUtils from './NumberUtils';

export enum ConversionType {
    Read,
    Write,
}

export const getIsMetric = (): boolean => {
    // Perform metric conversions if and only if there is a setting with the
    // property 'settingsName' equal to 'UOM', with an associated property
    // 'settingsValue' equal to 'METRIC'. If no such setting is found, use
    // 'METRIC' by default
    const settings = store.getState().userSettings.settings;
    const uomSettings = settings.find((s: any) => s.settingsName === 'UOM');
    return uomSettings === undefined || uomSettings.settingsValue === 'METRIC';
};

export const convertToMeters = (
    num: number,
    conversion: ConversionType
): number => {
    const isMetric = getIsMetric();

    if (conversion === ConversionType.Read) {
        return isMetric
            ? +num.toFixed(2)
            : +NumberUtils.getNumberImperial(num, 'm').toFixed(2);
    }

    return isMetric ? num : NumberUtils.getNumberMetric(num, 'ft');
};

export const convertToTon = (
    num: number,
    conversion: ConversionType
): number => {
    const isMetric = getIsMetric();

    if (conversion === ConversionType.Read) {
        return isMetric
            ? +num.toFixed(2)
            : +NumberUtils.getNumberImperial(num, 'ton').toFixed(2);
    }

    return isMetric ? num : NumberUtils.getNumberMetric(num, 'ton');
};

export const convertToMm = (
    num: number,
    conversion: ConversionType
): number => {
    const isMetric = getIsMetric();

    if (conversion === ConversionType.Read) {
        return isMetric
            ? +num.toFixed()
            : +NumberUtils.getNumberImperial(num, 'mm').toFixed(2);
    }

    return isMetric ? num : NumberUtils.getNumberMetric(num, 'in');
};
