import { BrandModel } from '@app/models/BrandModel';
import { CampaignModel } from '@app/models/CampaignModel';
import { LiningDataModel } from '@app/models/LiningDataModel';
import { MPGModel } from '@app/models/MPGModel';
import { ShapeStandardModel } from '@app/models/ShapeStandardModel';
import { SupplierModel } from '@app/models/SupplierModel';
import { ZoneModel } from '@app/models/ZoneModel';
import StringUtils from '@app/utils/StringUtils';
import NumberUtils from '@app/utils/NumberUtils';
import {
    TableRow,
    TableCell,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { Dispatch, SetStateAction, useMemo, memo, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { colors } from '@app/theme';
import { SectionModel } from '@app/models/SectionModel';
import NumberInput from '@app/atoms/NumberInput';
import SelectInput from '@app/atoms/SelectInput';
import { useUserSettings } from '@app/hooks/useUserSettings';
import SearcheableSelectInput, {
    Option,
} from '@app/atoms/SearcheableSelectInput';
import {
    ConversionType,
    convertToMeters,
    convertToMm,
    convertToTon,
    getIsMetric,
} from '@app/utils/ConvertUtils';
import { useLocation, useRoutes } from 'react-router-dom';

interface LiningRowProps {
    liningData: {
        linings: LiningDataModel[];
        setLinings: Dispatch<SetStateAction<LiningDataModel[]>>;
    };
    index: number;
    isEdit: boolean;
    campaign: CampaignModel;
    suppliers: SupplierModel[];
    MPGs: MPGModel[];
    brands: BrandModel[];
    zones: ZoneModel[];
    shapeStandards: ShapeStandardModel[];
    lining: LiningDataModel;
    kilnLength: number;
    sections: SectionModel[];
}

const LiningChartTableRow = (props: LiningRowProps) => {
    useUserSettings();

    const {
        index,
        isEdit,
        campaign,
        liningData: { linings, setLinings },
        MPGs,
        brands,
        suppliers,
        zones,
        shapeStandards,
        lining,
        sections,      
    } = props;

    const density = useMemo(
        () =>
            brands.find((brand) => brand.id === lining.idBrandName)?.density ??
            0,
        [lining.idBrandName, brands]
    );

    const sectionsFiltered =  sections.filter(
        (section) => (section.start <= lining.start &&
            section.end > lining.start) ||
            (section.start < lining.end &&
                section.end >= lining.end)
    )

    const [isValidBrickThickness, setIsValidBrickthickness] = useState(true)
    
    const handleDelete = () => {
        linings.splice(index, 1);
        setLinings([...linings]);
    };

    const calculateReplaceDate = () => {
        const replaceDate = new Date(campaign.installDate);
        // Integer part refers to months regardless of passing days.
        // Decimal part refers to days on a fraction of a 30-day month
        const expectedLifeMonths = Math.floor(+lining.expectedLife);
        const expectedLifeDays = Math.floor((+lining.expectedLife % 1) * 30);

        const installMonth = replaceDate.getMonth();
        replaceDate.setMonth(+installMonth + +expectedLifeMonths);
        replaceDate.setDate(replaceDate.getDate() + +expectedLifeDays);

        return replaceDate;
    };

    const calculteConicalCilinderTonnage = (section: SectionModel) => {
        // Vol = 1/3*pi*h*[(R^2)+(R)*(r)+(r^2)]
        // where h = conical cilinder height
        //       R = biggest radius
        //       r = smallest radius
        const calculateVolume = (h: number, R: number, r: number): number =>
            (Math.PI / 3.0) * h * (R * R + R * r + r * r);

        const start = convertToMeters(section.start, ConversionType.Write);
        const end = convertToMeters(section.end, ConversionType.Write);

        const sectionHeight = Math.abs(start - end);
        const firstRadius =
            convertToMeters(section.diameter, ConversionType.Write) / 2;
        const secondRadius =
            convertToMeters(section.diameter2, ConversionType.Write) / 2;

        // Adjust thickness from mm to meters
        const thickness =
            convertToMm(lining.brickThickness, ConversionType.Write) / 1000;

        const firstInnerRadius = firstRadius - thickness;
        const secondInnerRadius = secondRadius - thickness;

        // Volume is difference between inner and outer volumes
        const volume = Math.abs(
            calculateVolume(sectionHeight, firstRadius, secondRadius) -
                calculateVolume(
                    sectionHeight,
                    firstInnerRadius,
                    secondInnerRadius
                )
        );

        // Tonnage is the multiplication of volume per density (brandname)
        return volume * density;
    };

    const calculateCilinderTonnage = (section: SectionModel) => {
        // Vol=[(Re^2)-(Ri^2)]*pi*L
        // where Re = cilinder outer radius
        //       Ri = cilinder inner radius (hollow part)
        //       L  = cilinder length

        const calculateVolume = (Re: number, Ri: number, L: number): number =>
            (Re * Re - Ri * Ri) * Math.PI * L;

        // Calculate radius
        const outerRadius =
            convertToMeters(section.diameter, ConversionType.Write) / 2;
        // Adjust thickness from mm to meters
        const thickness =
            convertToMm(lining.brickThickness, ConversionType.Write) / 1000;
        // Get the inner radius
        const innerRadius = outerRadius - thickness;

        // Takes into consideration the fact that a lining may end after the
        // current kiln, but it may also have been started before the current
        // kiln.
        // It could be also the case that the kiln contains the lining too.
        // Anyway, the volume takes this in consideration.
        const maxStart = Math.max(section.start, lining.start);
        const start = convertToMeters(maxStart, ConversionType.Write);
        const minEnd = Math.min(section.end, lining.end);
        const end = convertToMeters(minEnd, ConversionType.Write);

        const length = Math.abs(start - end);

        const volume = calculateVolume(outerRadius, innerRadius, length);

        // Tonnage is the multiplication of volume per density (brandname)
        return volume * density;
    };

    const calculateTonnage = () => {
        if (lining.start !== undefined && lining.end !== undefined) {
            const intersects = (
                StartA: number,
                EndA: number,
                StartB: number,
                EndB: number
            ) => {
                const halfWidthA = Math.abs(EndA - StartA) / 2;
                const mediumPointA = (StartA + EndA) / 2;

                const halfWidthB = Math.abs(EndB - StartB) / 2;
                const mediumPointB = (StartB + EndB) / 2;

                const length = Math.abs(mediumPointB - mediumPointA);

                // Lines intersect if the distance between their middle points
                // is smaller or equals than the sum of their half widths
                return (
                    length <= halfWidthA + halfWidthB &&
                    StartA !== EndB &&
                    StartB !== EndA
                );
            };

            // 2. For each section found, calculate tonnage with respect to lining.
            //    Only consider tonnages greater than zero
            const totalTonnage = sections
                .filter((section) =>
                    intersects(
                        lining.start,
                        lining.end,
                        section.start,
                        section.end
                    )
                )
                .reduce((acc: number, section: SectionModel) => {
                    const tonnage =
                        section?.diameter === section?.diameter2
                            ? calculateCilinderTonnage(section)
                            : calculteConicalCilinderTonnage(section);

                    return acc + tonnage;
                }, 0);

            return convertToTon(totalTonnage, ConversionType.Read).toFixed(2);
        }

        return 0;
    };

    const getSelected = () => {
        if (StringUtils.isUndefinedOrWhiteSpace(lining.idBrandName)) return [];

        const brand = brands.find((b) => b.id === lining.idBrandName);

        return { value: lining.idBrandName, label: brand?.name };
    };


    const handleError = (): void => {
        const valueToConvertInMorFt = getIsMetric() ? 1000 : 12;
        const brickThicknessFT = lining.brickThickness / valueToConvertInMorFt;
        sectionsFiltered.forEach((section) => {
            const radius = Math.min(section.diameter, section.diameter2) / 2;
            const brickThicknessIsLowerThanRadius = brickThicknessFT < radius;
            setIsValidBrickthickness(brickThicknessIsLowerThanRadius)
        })
    }

    return (
        <TableRow
            key={index}
            sx={{
                color: 'secondary',
            }}
        >
            <TableCell
                sx={{
                    fontWeight: '700',
                    color: 'secondary',
                }}
            >
                <NumberInput
                    disabled={!isEdit}
                    placeholder={NumberUtils.getUomPlaceholder(getIsMetric())}
                    value={lining.start}
                    onChange={(value: number) => {
                        if (value >= 0 || value === undefined) {
                            linings[index].start = value;

                            if (lining.end <= value)
                                linings[index].end =
                                    linings[index].start +
                                    (getIsMetric()
                                        ? 0.2
                                        : NumberUtils.getNumberImperial(
                                              0.2,
                                              'm'
                                          ));

                            setLinings(linings.slice());
                        }
                    }}
                    error={
                        (index > 0 && lining.start < linings[index - 1].end) ||
                        lining.start < 0
                    }
                />
            </TableCell>
            <TableCell
                sx={{
                    fontWeight: '700',
                }}
            >
                <NumberInput
                    disabled={!isEdit}
                    placeholder={NumberUtils.getUomPlaceholder(getIsMetric())}
                    value={lining.end}
                    onChange={(value: number) => {
                        linings[index].end = value;

                        setLinings(linings.slice());
                    }}
                    error={lining.start >= lining.end}
                />
            </TableCell>
            <TableCell>
                <SelectInput
                    options={zones
                        .filter((x) => x.enabled)
                        .map((zone) => ({
                            value: zone.id,
                            label: zone.name,
                        }))}
                    value={lining?.idZone}
                    onChange={(value: string) => {
                        linings[index].idZone = value;
                        setLinings(linings.slice());
                    }}
                    error={StringUtils.isUndefinedOrWhiteSpace(lining.idZone)}
                />
            </TableCell>
            <TableCell>
                <SearcheableSelectInput
                    options={brands
                        .filter((x) => x.enabled)
                        .map((brand) => ({
                            value: brand.id,
                            label: brand.name,
                        }))}
                    selected={getSelected() as Option[]}
                    onChange={({ value }: { label: string; value: string }) => {
                        linings[index].idBrandName = value;
                        setLinings(linings.slice());
                    }}
                    error={StringUtils.isUndefinedOrWhiteSpace(
                        lining.idBrandName
                    )}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={
                        MPGs?.find(
                            (mpg) =>
                                mpg.id ===
                                brands?.find(
                                    (brand) => brand.id === lining?.idBrandName
                                )?.idmpg
                        )?.name
                    }
                    InputProps={{ readOnly: true }}
                />
            </TableCell>
            <TableCell>
                <TextField
                    value={
                        suppliers?.find(
                            (supplier) =>
                                supplier.id ===
                                brands?.find(
                                    (brand) => brand.id === lining?.idBrandName
                                )?.idSupplier
                        )?.name
                    }
                    InputProps={{ readOnly: true }}
                />
            </TableCell>
            <TableCell sx={{ fontWeight: '700' }}>
                <NumberInput
                    disabled={!isEdit}
                    value={lining.brickThickness}
                    
                    onChange={(value: number) => {
                        linings[index].brickThickness = value;
                        handleError()
                        setLinings(linings.slice());
                    }}
                
                    error={lining.brickThickness <= 0 || !isValidBrickThickness}
                />
            </TableCell>
            <TableCell>
                <SelectInput
                    options={shapeStandards
                        .filter((x) => x.enabled)
                        .map((shapeStandard) => ({
                            value: shapeStandard.id,
                            label: shapeStandard.shape,
                        }))}
                    value={lining?.idShapeStandard}
                    onChange={(value: string) => {
                        linings[index].idShapeStandard = value;
                        setLinings(linings.slice());
                    }}
                    error={StringUtils.isUndefinedOrWhiteSpace(
                        lining.idShapeStandard
                    )}
                />
            </TableCell>
            <TableCell
                sx={{
                    fontWeight: '700',
                    minWidth: '90px',
                }}
            >
                <NumberInput
                    placeholder='months'
                    disabled={!isEdit}
                    value={lining.expectedLife}
                    onChange={(value: number) => {
                        linings[index].expectedLife = value;
                        setLinings(linings.slice());
                    }}
                    step={0.1}
                    error={lining.expectedLife <= 0}
                />
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {DateUtils.formatUTCDate(campaign.installDate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {DateUtils.formatUTCDate(calculateReplaceDate())}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant='body2'>
                    {DateUtils.formatUTCDate(
                        lining.updatedAt ?? lining.createdAt
                    )}
                </Typography>
            </TableCell>
            <TableCell>{calculateTonnage()}</TableCell>
            <TableCell>
                <Button onClick={handleDelete}>
                    <Delete
                        fontSize='small'
                        sx={{ color: colors['Red-RHIM'] }}
                    />
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo(LiningChartTableRow);
