import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from '@app/reportWebVitals';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@app/authConfig';
import App from '@app/App';
import { theme } from '@app/theme';
import { Provider } from 'react-redux';
import { store } from '@app/store';
import '@app/index.css';

declare global {
    interface Window {
        env: {
            __CLIP_API__: string;
            __REDIRECT_URI__: string;
            __POSTLOGOUT_URI__: string;
            __REDUX_PATH__: string;
            __CLIENT_ID__: string;
            __CLIENT_SECRET__: string;
        };
    }
}

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </MsalProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);