import { memo } from 'react';
import KilnBundle from '@app/molecules/KilnBundle';
import { StressAreaModel } from '@app/models/StressAreaModel';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import KilnStress from './KilnStress';

interface IProps {
    expanded: number;
    setExpanded: Function;
    setStressAreas: Function;
    stressAreas: StressAreaModel[];
    stressAreaTypes: StressAreaTypeModel[];
    length: number;
}

const KilnStressArea = (props: IProps) => (
    <KilnBundle
        key='bundle-stress-area'
        name='bundle-stress-area'
        setExpanded={props.setExpanded}
        expanded={props.expanded}
        index={3}
        title='Kiln Stress Area'
    >
        <KilnStress
            setStressAreas={props.setStressAreas}
            stressAreas={props.stressAreas}
            stressAreaTypes={props.stressAreaTypes}
            length={props.length}
        />
    </KilnBundle>
);

export default memo(KilnStressArea);
