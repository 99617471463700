import { CoatingAreaModel } from '@app/models/CoatingAreaModel';
import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import { Box } from '@mui/material';
import { memo, useCallback } from 'react';
import KilnBuilderHeader from '../KilnBuilderHeader';
import KilnCoatingArea from './KilnCoatingArea';

type IProps = {
    setCoatingAreas: Function;
    coatingAreas: CoatingAreaModel[];
    coatingAreaTypes: CoatingAreaTypeModel[];
    length: number;
};

const KilnCoating = (props: IProps) => {
    const handleChange = () => {
        props.setCoatingAreas(props.coatingAreas.slice());
    };

    const handleAddition = (index: number) => {
        const last = props.coatingAreas[index] ?? {};
        const newCoatingArea = {
            ...last,
            start: last.end ?? 0,
            end: last.end ? last.end + 1 : props.length,
        };
        props.setCoatingAreas([...props.coatingAreas, newCoatingArea]);
    };

    const handleDeletion = (index: number) => {
        props.coatingAreas.splice(index, 1);
        props.setCoatingAreas(props.coatingAreas.slice());
    };

    const renderCoatingAreas = useCallback(
        () =>
            props.coatingAreas.map((_, index) => {
                const isLast = props.coatingAreas.length - 1 === index;

                return (
                    <KilnCoatingArea
                        key={`coating-area-${index}`}
                        isLast={isLast}
                        index={index}
                        coatingAreas={props.coatingAreas}
                        length={props.length}
                        handleChange={handleChange}
                        handleDeletion={handleDeletion}
                        handleAddition={handleAddition}
                        coatingAreaTypes={props.coatingAreaTypes}
                    />
                );
            }),
        [props.coatingAreas, props.length]
    );

    const renderCoatingAreaEmpty = useCallback(
        () =>
            props.coatingAreas.length === 0 ? (
                <KilnBuilderHeader
                    hasAdd
                    handleAddition={handleAddition}
                    index={props.coatingAreas.length}
                    title='Coating Area'
                />
            ) : (
                <></>
            ),
        [props.coatingAreas]
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            {renderCoatingAreaEmpty()}
            {renderCoatingAreas()}
        </Box>
    );
};

export default memo(KilnCoating);
