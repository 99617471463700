import { AppThunk } from '@app/store';
import { Dispatch } from 'redux';

const SET_LOADING = `${window.env.__REDUX_PATH__}/SET_LOADING`;

type LoadingState = boolean | undefined;

type LoadingAction = {
    type: string;
    payload?: LoadingState;
};

type LoadingActionTypes = LoadingAction;

const reducer = (
    state: LoadingState = false,
    action: LoadingActionTypes
): LoadingState => {
    if (action.type === SET_LOADING && action.payload !== state)
        return action.payload ?? false;

    if (action.type === 'CLEAR_STORE') return false;

    return state;
};

export default reducer;

export const showLoading = (payload: LoadingState): LoadingAction => {
    return {
        type: SET_LOADING,
        payload,
    };
};

export const setLoading = (loading: LoadingState): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(showLoading(loading));
    };
};
