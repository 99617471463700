import { SectionTypeModel } from '@app/models/SectionTypeModel';
import { SectionTypeService } from '@app/services/SectionTypeService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_SECTION_TYPE__REQUEST = `${window.env.__REDUX_PATH__}/SectionType/GET_SECTION_TYPE__REQUEST`;
const GET_SECTION_TYPE__SUCCESS = `${window.env.__REDUX_PATH__}/SectionType/GET_SECTION_TYPE__SUCCESS`;
const GET_SECTION_TYPE__FAILURE = `${window.env.__REDUX_PATH__}/SectionType/GET_SECTION_TYPE__FAILURE`;

export interface SectionTypeState extends BaseState {
    sectionTypes: SectionTypeModel[];
}

// Action Types
interface GetSectionTypeRequestAction {
    type: typeof GET_SECTION_TYPE__REQUEST;
}

interface GetSectionTypeSuccessAction {
    type: typeof GET_SECTION_TYPE__SUCCESS;
    payload?: SectionTypeModel[];
}

interface GetSectionTypeFailureAction {
    type: typeof GET_SECTION_TYPE__FAILURE;
    payload?: string;
}

type SectionTypeActionTypes =
    | GetSectionTypeRequestAction
    | GetSectionTypeSuccessAction
    | GetSectionTypeFailureAction;

// Reducer
const initialState: SectionTypeState = {
    sectionTypes: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getSectionTypeRequestReducer = (
    state: SectionTypeState
): SectionTypeState => {
    return {
        ...state,
        sectionTypes: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getSectionTypeSuccessReducer = (
    state: SectionTypeState,
    action: GetSectionTypeSuccessAction
): SectionTypeState => {
    return {
        ...state,
        sectionTypes: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getSectionTypeFailureReducer = (
    state: SectionTypeState,
    action: GetSectionTypeFailureAction
): SectionTypeState => {
    return {
        ...state,
        sectionTypes: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: SectionTypeState = initialState,
    action: SectionTypeActionTypes
): SectionTypeState => {
    switch (action.type) {
        case GET_SECTION_TYPE__REQUEST:
            return getSectionTypeRequestReducer(state);
        case GET_SECTION_TYPE__SUCCESS:
            return getSectionTypeSuccessReducer(
                state,
                action as GetSectionTypeSuccessAction
            );
        case GET_SECTION_TYPE__FAILURE:
            return getSectionTypeFailureReducer(
                state,
                action as GetSectionTypeFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getSectionTypeRequest = (): GetSectionTypeRequestAction => {
    return {
        type: GET_SECTION_TYPE__REQUEST,
    };
};

export const getSectionTypeSuccess = (
    payload: SectionTypeModel[]
): GetSectionTypeSuccessAction => {
    return {
        type: GET_SECTION_TYPE__SUCCESS,
        payload,
    };
};

export const getSectionTypeFailure = (
    payload: string
): GetSectionTypeFailureAction => {
    return {
        type: GET_SECTION_TYPE__FAILURE,
        payload,
    };
};

export const getAllSectionType = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getSectionTypeRequest());

        try {
            const service = new SectionTypeService();
            const response = await service.getAll();

            dispatch(getSectionTypeSuccess(response));
            dispatch(
                showMessage({
                    message: 'SectionType load successfully!',
                    title: 'SectionType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getSectionTypeFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'SectionType could not be load!',
                        title: 'SectionType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getSectionType = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getSectionTypeRequest());

        try {
            const service = new SectionTypeService();
            const response = await service.get(params);

            dispatch(getSectionTypeSuccess(response));
            dispatch(
                showMessage({
                    message: 'SectionType load successfully!',
                    title: 'SectionType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getSectionTypeFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'SectionType could not be load!',
                        title: 'SectionType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
