import { TableBody, TableRow, TableCell } from '@mui/material';

interface TableEmptyRow {
    colSpan: number;
}

export const TableEmptyRow = (props: TableEmptyRow) => (
    <TableBody>
        <TableRow>
            <TableCell colSpan={props.colSpan} align='center'>
                No data to show.
            </TableCell>
        </TableRow>
    </TableBody>
);

export default TableEmptyRow;
