import { TablePagination } from '@mui/material';
import { memo } from 'react';
import TablePaginationActions from './TablePaginationActions';

interface TablePaginatorProps {
    paginationControl: {
        rowsPerPageOptions?: Array<number | { label: string; value: number }>;
        count: number;
        rowsPerPage: number;
        setRowsPerPage: (value: number) => void;
        page: number;
        setPage: (value: number) => void;
    };
    colSpan?: number;
}

const DEFAULT_PAGE_OPTIONS = [5, 10, 25, { label: 'All', value: -1 }];

const TablePaginator = (props: TablePaginatorProps) => {
    const {
        paginationControl: {
            page,
            rowsPerPageOptions,
            count,
            rowsPerPage,
            setPage,
            setRowsPerPage,
        },
        colSpan,
    } = props;

    const handleChangePage = (_: any, value: number) => {
        if (value !== page) setPage(value);
    };

    const handleChangeRowsPerPage = ({ target: { value } }: any) => {
        if (value !== rowsPerPage) setRowsPerPage(+value);
        setPage(0);
    };

    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions || DEFAULT_PAGE_OPTIONS}
            colSpan={colSpan ?? 3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
                inputProps: {
                    'aria-label': 'rows per page',
                },
                native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );
};

export default memo(TablePaginator);
