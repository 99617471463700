import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { ProcessTypeModel } from '@app/models/ProcessTypeModel';
import Modal from '@app/molecules/Modal';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { ProcessTypeService } from '@app/services/ProcessTypeService';
import { Add, Edit } from '@mui/icons-material';
import {
    TextField,
    Grid,
    Tooltip,
    IconButton,
    Button,
    Typography,
    Switch,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback, memo } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useProcessType } from '@app/hooks/useProcessType';
import { getAllProcessTypes } from '@app/store/processType/reducer';

const INITIAL_STATE: ProcessTypeModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    deletedAt: new Date(),
    deletedBy: '',
    updatedAt: new Date(),
    updatedBy: '',
    name: '',
    description: '',
};

const ProcessTypesList = () => {
    const dispatch = useAppDispatch();
    const { processTypes } = useAppSelector((state) => state.processType);
    useProcessType();

    const processTypesService = useMemo(
        () => new ProcessTypeService(),
        []
    );
    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingProcessType, setEditingProcessType] =
        useState<ProcessTypeModel>({
            ...INITIAL_STATE,
        });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingProcessType({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingProcessType({
                ...editingProcessType,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingProcessType({
                ...editingProcessType,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await processTypesService.create({
                    ...editingProcessType,
                });
                dispatch(getAllProcessTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='New Process Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
            >
                <TextField
                    value={editingProcessType?.name}
                    label='Process Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />{' '}
                <TextField
                    value={editingProcessType?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingProcessType, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingProcessType({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingProcessType({
                ...editingProcessType,
                name: value,
            });
        };

        const handleDescriptionChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingProcessType({
                ...editingProcessType,
                description: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await processTypesService.update({
                    ...editingProcessType,
                });
                dispatch(getAllProcessTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await processTypesService.delete(editingProcessType.id);
                dispatch(getAllProcessTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='Edit Process Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
            >
                <TextField
                    value={editingProcessType?.name}
                    label='Process Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
                <TextField
                    value={editingProcessType?.description}
                    label='Description'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleDescriptionChange}
                />
            </Modal>
        );
    }, [editingProcessType, showModalEditing]);

    const getEditAction = useCallback((processType: ProcessTypeModel) => {
        const handleClick = () => {
            setEditingProcessType(processType);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit Process Type'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((processType: ProcessTypeModel) => {
        const isChecked = processType.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await processTypesService.update({
                    ...processType,
                    enabled: !isChecked,
                });
                dispatch(getAllProcessTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable Process Type'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW PROCESS TYPE
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Process Type', value: 'name' },
            {
                label: 'Created by',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Description',
                value: 'description',
                handle: (value: string) =>
                    value !== 'description' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={processTypes}
                    rowsPerPage={5}
                    searchColumns={['name']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(ProcessTypesList);
