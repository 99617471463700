import Modal from '@app/molecules/Modal';
import {
    Autocomplete,
    Badge,
    TextField,
    createTheme,
    Button,
    Typography,
    Tooltip,
} from '@mui/material';
import { useMemo, memo, useCallback, useEffect, useContext } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CardFilterContextManager } from '@app/contexts/CardFilterContext';
import { FilterModalContextManager } from '@app/contexts/FilterModalContext';

export type Option = {
    value: any;
    label: string;
};

export type OptionMap = { [key: string]: Option[] };

export type DisabledFilter = { [key: string]: string };

type FilterModalProps = {
    columns: string[];
    open: boolean;
    setOpen: (status: boolean) => void;
    data: OptionMap;
    width?: string;
    height?: string;
    minimumFilter?: OptionMap;
    disabledFilters?: DisabledFilter;
};

const FilterModal = (props: FilterModalProps) => {
    const { applied, setApplied } = useContext(CardFilterContextManager);
    const { selected, setSelected } = useContext(FilterModalContextManager);

    const appliedCount = useMemo(
        () =>
            Object.values(applied).reduce(
                (acc, item) => acc + (item.length > 0 ? 1 : 0),
                0
            ),
        [applied]
    );

    useEffect(() => {
        if (selected !== applied && !props.open) {
            setSelected(applied);
        }
    }, [applied, props.open]);

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleCancel = () => {
        setApplied(props.minimumFilter ?? {});
        handleClose();
    };

    const handleConfirm = () => {
        setApplied(selected);
        handleClose();
    };

    const optionsMap = useMemo(
        () =>
            props.columns.reduce((acc: {}, a: string) => {
                if (!props.data[a]) return { ...acc, [a]: [] };

                return {
                    ...acc,
                    [a]: props.data[a]?.reduce((bcc: Option[], b: Option) => {
                        if (bcc.some((e: Option) => e.value === b.value))
                            return bcc;

                        return [...bcc, b];
                    }, []),
                };
            }, {} as OptionMap),
        [props.columns, props.data]
    );

    const renderFilters = useCallback(
        () => (
            <div
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    paddingRight: '8px',
                    width: '100%',
                }}
            >
                <ThemeProvider theme={createTheme({})}>
                    {props.columns?.map((column, index) => (
                        <Tooltip
                            key={`filter-modal--autocomplete-multiple-${index}--tooltip`}
                            title={
                                props.disabledFilters !== undefined
                                    ? props.disabledFilters[column] ?? column
                                    : column
                            }
                        >
                            <Autocomplete
                                key={`filter-modal--autocomplete-multiple-${index}--input`}
                                sx={{
                                    width: '100%',
                                    margin: '8px',
                                }}
                                value={selected[column] ?? []}
                                isOptionEqualToValue={(item, option) =>
                                    item.value === option.value
                                }
                                onChange={(
                                    _1: any,
                                    values: Option[],
                                    _2: any
                                ) => {
                                    const newSelected = {
                                        ...selected,
                                        [column]: values,
                                    };

                                    if (
                                        column === 'customer' &&
                                        values.length === 0
                                    )
                                        delete newSelected.kilnName;

                                    setSelected(newSelected);
                                }}
                                multiple
                                disabled={
                                    props.disabledFilters !== undefined
                                        ? props.disabledFilters[column] !==
                                          undefined
                                        : false
                                }
                                options={optionsMap[column]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={column}
                                        key={`filter-modal--autocomplete-multiple-${index}--input-item-${column}-${params.id}`}
                                    />
                                )}
                                renderOption={(props, option, state) => (
                                    <Typography
                                        {...props}
                                        key={`filter-modal--autocomplete-multiple-${index}-${option.value}-option`}
                                    >
                                        {option.label}
                                    </Typography>
                                )}
                            />
                        </Tooltip>
                    ))}
                </ThemeProvider>
            </div>
        ),
        [props.columns, selected, optionsMap]
    );

    return (
        <Modal
            title={
                appliedCount > 0 ? (
                    <Badge badgeContent={appliedCount} color='primary'>
                        Filter by
                    </Badge>
                ) : (
                    'Filter by'
                )
            }
            handleClose={handleClose}
            handleConfirm={handleCancel}
            handleCancel={handleClose}
            confirmTitle='RESET'
            confirmVariant='outlined'
            cancelVariant='text'
            extraButton={
                <Button
                    color='secondary'
                    variant='contained'
                    onClick={handleConfirm}
                >
                    SAVE
                </Button>
            }
            showModal={props.open}
        >
            {renderFilters()}
        </Modal>
    );
};

export default memo(FilterModal);
