import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import {
    getAllSectionType,
    getSectionType,
} from '@app/store/sectionType/reducer';

export const useSectionType = (getAll?: boolean): void => {
    const { isFetching, lastUpdated, sectionTypes } = useAppSelector(
        (state) => state.sectionType
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && sectionTypes.length === 0 && !lastUpdated) {
            dispatch(
                !getAll
                    ? getSectionType({
                          enabled: true,
                      })
                    : getAllSectionType()
            );
        }
    }, []);
};
