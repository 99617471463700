import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import LiningChartDrawing, {
    LiningDrawingSlice,
} from '@app/molecules/LiningChartDrawing';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Link,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Download } from '@mui/icons-material';
import { useBrandName } from '@app/hooks/useBrandName';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CampaignModel } from '@app/models/CampaignModel';
import { CampaignService } from '@app/services/CampaignService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LiningDataModel } from '@app/models/LiningDataModel';
import { ArrowForward, NavigateNext } from '@mui/icons-material';
import UriUtils from '@app/utils/UriUtils';
import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import DateUtils from '@app/utils/DateUtils';
import { colors } from '@app/theme';
import { useMPG } from '@app/hooks/useMPG';
import { useShapeStandard } from '@app/hooks/useShapeStandard';
import { useSupplier } from '@app/hooks/useSupplier';
import { useZone } from '@app/hooks/useZone';
import { useCustomer } from '@app/hooks/useCustomer';
import { useSalesUnit } from '@app/hooks/useSalesUnit';
import { useUserSettings } from '@app/hooks/useUserSettings';
import NumberUtils from '@app/utils/NumberUtils';
import { MarketInsightsService } from '@app/services/MarketInsightsService';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { getIsMetric } from '@app/utils/ConvertUtils';

type BrandMap = {
    [key: string]: {
        name: string;
        code: string;
        idmpg: string;
        idSupplier: string;
    };
};

type BasicMap = {
    [key: string]: string;
};

interface LiningDataModelExtended extends LiningDataModel {
    installDate: Date;
}

const LiningsHistory = (kiln: KilnProfileStructureModel) => {
    useUserSettings();

    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const params = UriUtils.parseQueryString(location.search);

    const { customerId, kilnProfileId } = params;

    const brandName = useAppSelector((state) => state.brandName);
    const zone = useAppSelector((state) => state.zone);
    const mpg = useAppSelector((state) => state.mpg);
    const supplier = useAppSelector((state) => state.supplier);
    const shapeStandard = useAppSelector((state) => state.shapeStandard);
    const salesUnit = useAppSelector((state) => state.salesUnit);

    const customer = useCustomer(customerId as string);
    useBrandName();
    useZone();
    useMPG();
    useSupplier();
    useShapeStandard();
    useSalesUnit();

    const serviceCampaign = useMemo(() => new CampaignService(), []);
    const serviceMkt = useMemo(() => new MarketInsightsService(), []);
    const [data, setData] = useState<CampaignModel[]>([]);

    useEffect(() => {
        const initialLoad = async () => {
            dispatch(setLoading(true));
            try {
                const response = await serviceCampaign.getByKilnId(
                    kilnProfileId as string
                );
                setData(response);
                dispatch(
                    setMessage({
                        title: 'Success',
                        message: 'Successefully loaded data',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message: 'Could not load data',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        initialLoad();
    }, []);

    const brandMap: BrandMap = useMemo(
        () =>
            brandName.brands?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: {
                        code: j.code,
                        name: j.name,
                        idmpg: j.idmpg,
                        idSupplier: j.idSupplier,
                    },
                }),
                {}
            ),
        [brandName]
    );

    const zoneMap: BasicMap = useMemo(
        () =>
            zone.zones?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: j.name,
                }),
                {}
            ),
        [zone]
    );

    const shapeStandardMap: BasicMap = useMemo(
        () =>
            shapeStandard.shapeStandards?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: j.shape,
                }),
                {}
            ),
        [shapeStandard]
    );

    const mpgMap: BasicMap = useMemo(
        () =>
            mpg.MPGs?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: j.name,
                }),
                {}
            ),
        [mpg]
    );

    const supplierMap: BasicMap = useMemo(
        () =>
            supplier.suppliers?.reduce(
                (i, j) => ({
                    ...i,
                    [j.id]: j.name,
                }),
                {}
            ),
        [supplier]
    );

    const salesUnitMemo = useMemo(
        () =>
            salesUnit.salesUnits?.find((su) => su.id === customer?.idSalesUnit),
        [customer, salesUnit]
    );

    const breadcrumbs = useMemo(
        () => [
            <Link
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', { customerId })}
            >
                Kiln Overview
            </Link>,
            <Link
                component={RouterLink}
                to={UriUtils.stringifyQueryObject('/overview', {
                    view: 'campaigns',
                    customerId,
                    kilnProfileId,
                })}
            >
                {kiln?.kilnName}
            </Link>,
        ],
        [kiln, customerId, kilnProfileId]
    );

    const renderDrawingAndAction = useCallback(
        (campaign: CampaignModel) => {
            const kilnLength = Math.ceil(kiln?.length);
            const arrayLength = kilnLength * 5;
            const current: LiningDrawingSlice[] = new Array(arrayLength).fill({
                code: colors['Grey5-RHIM'],
                brandName: 'unknown',
            });

            if (campaign.liningChart) {
                campaign.liningChart.forEach((lining: LiningDataModel) => {
                    const brand = brandMap[lining.idBrandName] ?? {};
                    const length = Math.abs(lining.end - lining.start);
                    const meter = Math.ceil(length * 5);

                    if (NumberUtils.isValidNumber(meter)) {
                        const newSlices: LiningDrawingSlice[] = new Array(
                            meter
                        ).fill({
                            code: brand.code ?? '',
                            brandName: brand.name ?? '',
                            start: lining.start.toFixed(2),
                            end: lining.end.toFixed(2),
                            installDate: DateUtils.formatCampaignMonthYear(
                                campaign.installDate
                            ),
                        });
                        const start = Math.ceil(lining.start * 5);
                        current.splice(start, meter, ...newSlices);
                    }
                });
            }

            return (
                <Grid
                    container
                    margin='0'
                    rowGap='8px'
                    width='calc(100% - 56px)'
                >
                    <Typography variant='body2' color={colors['Black-RHIM']}>
                        {DateUtils.formatCampaignMonthYear(
                            campaign?.installDate
                        )}
                    </Typography>
                    <Grid
                        container
                        margin='0'
                        flexWrap='nowrap'
                        columnGap='16px'
                        alignItems='center'
                    >
                        <Grid
                            item
                            container
                            margin='0'
                            sx={{
                                borderBottom: `1px solid ${colors['Grey40-RHIM']}`,
                                borderRadius: '0',
                                paddingBottom: '24px',
                            }}
                        >
                            <LiningChartDrawing
                                linings={current}
                                kilnLength={kilnLength}
                            />
                        </Grid>
                        <Grid item margin='0'>
                            <Tooltip title='Lining Chart Table'>
                                <IconButton
                                    onClick={() =>
                                        navigate(
                                            UriUtils.stringifyQueryObject(
                                                '/overview',
                                                {
                                                    customerId,
                                                    kilnProfileId,
                                                    campaignId: campaign.id,
                                                    view: 'linings',
                                                }
                                            )
                                        )
                                    }
                                    sx={{
                                        border: 'none',
                                        color: colors['Blue-RHIM'],
                                        backgroundColor: 'inherit',
                                    }}
                                >
                                    <ArrowForward />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            );
        },
        [kiln, customerId, kilnProfileId, brandMap]
    );

    const renderBreadcrumbs = useCallback(
        () => (
            <Grid item container margin='0'>
                <Breadcrumbs
                    separator={<NavigateNext />}
                    aria-label='breadcrumb'
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
        ),
        [breadcrumbs]
    );

    const renderButtons = useCallback(() => {
        return (
            <Grid
                item
                sx={{
                    width: '100%',
                    margin: '0',
                    justifyContent: 'flex-end',
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    gridGap: '8px',
                }}
            >
                <Button
                    startIcon={<Download />}
                    variant='outlined'
                    color='secondary'
                    onClick={async () => {
                        dispatch(setLoading(true));
                        try {
                            await serviceMkt.queueReport({
                                'Kiln Name': [kiln.kilnName],
                                'Customer Name': [customer?.name],
                                UOM: [(!getIsMetric()).toString()],
                                Year: [new Date().getUTCFullYear().toString()],
                            });
                            dispatch(
                                setMessage({
                                    title: 'Processing started successfully!',
                                    message:
                                        'We are processing your report and it will be available on the Reports page.',
                                    color: 'success',
                                    open: true,
                                    duration: 70000,
                                })
                            );
                        } catch (ex) {
                            dispatch(
                                setMessage({
                                    title: 'Error',
                                    message:
                                        'There was a problem with your report, please try exporting again later!',
                                    color: 'error',
                                    open: true,
                                })
                            );
                        }
                        dispatch(setLoading(false));
                    }}
                >
                    EXPORT DATA
                </Button>
                <Button
                    variant='outlined'
                    color='secondary'
                    size='large'
                    onClick={() => {
                        navigate(
                            UriUtils.stringifyQueryObject('/overview', {
                                view: 'campaigns',
                                customerId,
                                kilnProfileId,
                            })
                        );
                    }}
                >
                    <Typography>BACK</Typography>
                </Button>
            </Grid>
        );
    }, [
        data,
        brandMap,
        customer,
        salesUnitMemo,
        zoneMap,
        supplierMap,
        customerId,
        kilnProfileId,
        shapeStandardMap,
        mpgMap,
        kiln,
    ]);

    const sorted: LiningDataModelExtended[] = useMemo(
        () =>
            data
                .slice()
                .sort(
                    (a: CampaignModel, b: CampaignModel) =>
                        new Date(a.installDate).getTime() -
                        new Date(b.installDate).getTime()
                )
                .flatMap(
                    (campaign: CampaignModel) =>
                        campaign.liningChart?.map((lining) => ({
                            ...lining,
                            installDate: campaign.installDate,
                        })) ?? []
                ),
        [data]
    );

    const renderCurrentDrawing = useCallback(() => {
        const kilnLength = Math.ceil(kiln?.length);
        const arrayLength = kilnLength * 5;
        const current: LiningDrawingSlice[] = new Array(arrayLength).fill({
            code: colors['Grey5-RHIM'],
            brandName: 'unknown',
        });

        if (sorted && sorted.length > 0) {
            sorted.forEach((lining) => {
                const brand = brandMap[lining.idBrandName] ?? {};
                const length = Math.abs(lining.end - lining.start);
                const meter = Math.ceil(length * 5);
                const newSlices: LiningDrawingSlice[] = new Array(meter).fill({
                    code: brand.code ?? '',
                    brandName: brand.name ?? '',
                    start: lining.start.toFixed(2),
                    end: lining.end.toFixed(2),
                    installDate: DateUtils.formatCampaignMonthYear(
                        lining.installDate
                    ),
                });
                const start = Math.ceil(lining.start * 5);
                current.splice(start, meter, ...newSlices);
            });
        }

        if (
            kiln.girthGear !== undefined &&
            NumberUtils.isValidNumber(kiln.girthGear)
        ) {
            const arrayIndex = Math.ceil(kiln.girthGear * 5) - 1;
            current[arrayIndex] = {
                ...current[arrayIndex],
                girthGear: kiln.girthGear.toFixed(2),
            };
        }

        if (kiln.tires !== undefined && kiln.tires.length > 0) {
            kiln.tires.forEach((tire) => {
                const arrayIndex = Math.ceil(tire.position * 5) - 1;
                current[arrayIndex] = {
                    ...current[arrayIndex],
                    tire: tire.position.toFixed(2),
                };
            });
        }

        return (
            <Grid container margin='0' rowGap='8px' width='calc(100% - 56px)'>
                <Typography variant='body2' color={colors['Black-RHIM']}>
                    Current Kiln
                </Typography>
                <Grid
                    container
                    margin='0'
                    flexWrap='nowrap'
                    columnGap='16px'
                    alignItems='center'
                >
                    <Grid
                        item
                        container
                        margin='0'
                        sx={{
                            borderBottom: `1px solid ${colors['Grey40-RHIM']}`,
                            borderRadius: '0',
                            paddingBottom: '24px',
                        }}
                    >
                        <LiningChartDrawing
                            linings={current}
                            kilnLength={kilnLength}
                        />
                    </Grid>
                    <Grid item margin='0'>
                        <Tooltip title='Lining Chart Table'>
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        UriUtils.stringifyQueryObject(
                                            '/overview',
                                            {
                                                customerId,
                                                kilnProfileId,
                                                view: 'campaigns',
                                            }
                                        )
                                    )
                                }
                                sx={{
                                    border: 'none',
                                    color: colors['Blue-RHIM'],
                                    backgroundColor: 'inherit',
                                }}
                            >
                                <ArrowForward />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [data, kiln, sorted, brandMap, customerId, kilnProfileId]);

    return (
        <Grid container margin='0' rowGap='16px'>
            <Grid
                container
                margin='0'
                justifyContent='space-between'
                alignItems='center'
                flexWrap='nowrap'
            >
                {renderBreadcrumbs()}
                {renderButtons()}
            </Grid>
            {renderCurrentDrawing()}
            {data.map((campaign: CampaignModel) =>
                renderDrawingAndAction(campaign)
            )}
        </Grid>
    );
};

export default LiningsHistory;
