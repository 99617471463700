import MapChart, { Country } from '@app/atoms/MapChart';
import { memo } from 'react';
import CardFilter from '../CardFilter/CardFilter';
import { OptionMap, Option, DisabledFilter } from '../CardFilter/FilterModal';

type MarketSizeTabProps = {
    options: OptionMap;
    columns: string[];
    countries: Country[];
    years: string[];
    year?: string;
    setYear: Function;
    disabledFilters?: DisabledFilter;
};

const MarketSizeTab = (props: MarketSizeTabProps) => (
    <CardFilter
        width='100%'
        height='400px'
        title='Market Size'
        years={props.years}
        setYear={props.setYear}
        year={props.year}
        data={props.options}
        columns={props.columns}
        disabledFilters={props.disabledFilters}
    >
        <MapChart markers={[]} countries={props.countries} />
    </CardFilter>
);

export default memo(MarketSizeTab);
