import { useAppDispatch, useAppSelector } from '@app/hooks';
import { CustomerModel } from '@app/models/CustomerModel';
import { getCustomers, selectCustomer } from '@app/store/customer/reducer';
import StringUtils from '@app/utils/StringUtils';
import { useEffect, useMemo } from 'react';
import _ from 'lodash';

export const useCustomer = (customerId?: string): CustomerModel | undefined => {
    const { selectedCustomer, isFetching, lastUpdated, customers } =
        useAppSelector((state) => state.customer);
    const dispatch = useAppDispatch();

    const customerData = useMemo(() => {
        const isUndefined = StringUtils.isUndefinedOrWhiteSpace(customerId);

        if (isUndefined || customers.length === 0) return;

        const customer = customers.find((c) => c.id === customerId);
        return !_.isUndefined(customer) && _.isEqual(customer, selectedCustomer)
            ? selectedCustomer
            : customer;
    }, [customerId, customers, selectedCustomer]);

    useEffect(() => {
        if (!isFetching && customers.length === 0 && !lastUpdated) {
            dispatch(getCustomers());
        }

        if (
            !_.isUndefined(customerData) &&
            !_.isEqual(customerData, selectedCustomer)
        ) {
            dispatch(selectCustomer(customerData));
        }
    }, [customerData, selectedCustomer]);

    return selectedCustomer;
};
