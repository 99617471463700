import NumberInput from '@app/atoms/NumberInput';
import SelectInput from '@app/atoms/SelectInput';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { CoatingAreaModel } from '@app/models/CoatingAreaModel';
import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import { colors } from '@app/theme';
import StringUtils from '@app/utils/StringUtils';
import { Box, Grid } from '@mui/material';
import { memo, useMemo } from 'react';
import KilnBuilderHeader from '../KilnBuilderHeader';

type IProps = {
    isLast: boolean;
    index: number;
    handleChange: Function;
    coatingAreas: CoatingAreaModel[];
    coatingAreaTypes: CoatingAreaTypeModel[];
    length: number;
    handleDeletion: Function;
    handleAddition: Function;
};

const KilnCoatingArea = (props: IProps) => {
    useUserSettings();
    const {
        isLast,
        index,
        handleChange,
        coatingAreas,
        coatingAreaTypes,
        length,
        handleDeletion,
        handleAddition,
    } = props;

    const errorMapping = useMemo(
        () => ({
            startLower: "The start can't be lower than 0.",
            endLower: "The end can't be lower than or equals 0.",
            endStart: "The end can't be lower than the start.",
            length: "The start/end can't be higher than the kiln length.",
            type: 'The coating area must have a coating area type.',
        }),
        []
    );

    const handleChangeStart = (value: number) => {
        coatingAreas[index].start = value;
        handleChange(index);
    };

    const handleChangeEnd = (value: number) => {
        coatingAreas[index].end = value;
        handleChange(index);
    };

    const handleChangeCoatingAreaType = (value: string) => {
        coatingAreas[index].idCoatingAreaType = value;
        handleChange(index);
    };

    return (
        <Box sx={{ margin: '8px 0' }}>
            <KilnBuilderHeader
                title={`Coating Area ${index + 1}`}
                hasAdd={isLast}
                hasDelete
                index={index}
                handleDeletion={handleDeletion}
                handleAddition={handleAddition}
            />
            <Grid
                container
                margin='0'
                sx={{
                    border: `1px solid ${colors['Grey20-RHIM']}`,
                    borderTop: 0,
                    borderRadius: '0 0 4px 4px',
                    paddingTop: '8px',
                }}
            >
                <Grid
                    item
                    container
                    display='grid'
                    gap='8px'
                    gridTemplateColumns='calc(50% - 4px) calc(50% - 4px)'
                >
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: Start'
                            value={coatingAreas[index].start}
                            onChange={handleChangeStart}
                            error={coatingAreas[index].start < 0}
                            helperText={
                                coatingAreas[index].start < 0
                                    ? errorMapping.startLower
                                    : coatingAreas[index].start > length
                                    ? errorMapping.length
                                    : ''
                            }
                            required
                        />
                    </Grid>
                    <Grid item margin='0'>
                        <NumberInput
                            label='Length: End'
                            value={coatingAreas[index].end}
                            onChange={handleChangeEnd}
                            error={
                                coatingAreas[index].start >
                                    coatingAreas[index].end ||
                                coatingAreas[index].end <= 0 ||
                                coatingAreas[index].end > length
                            }
                            helperText={
                                coatingAreas[index].end <= 0
                                    ? errorMapping.endLower
                                    : coatingAreas[index].end > length
                                    ? errorMapping.length
                                    : errorMapping.endStart
                            }
                            required
                        />
                    </Grid>
                </Grid>
                <Grid item container>
                    <SelectInput
                        title='Coating Area Type'
                        value={coatingAreas[index].idCoatingAreaType}
                        onChange={handleChangeCoatingAreaType}
                        options={coatingAreaTypes
                            .filter((x) => x.enabled)
                            .map((sat) => ({
                                value: sat.id,
                                label: sat.name,
                            }))}
                        error={StringUtils.isUndefinedOrWhiteSpace(
                            coatingAreas[index].idCoatingAreaType
                        )}
                        helperText={errorMapping.type}
                        required
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(KilnCoatingArea);
