import { memo, useCallback, useMemo, useState } from 'react';

import { KilnProfileModel } from '@app/models/KilnProfileModel';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import TableList from '@app/organisms/TableList';
import KilnProfilesListRowActions from './KilnProfilesListRowActions';
import useRequest from '@app/hooks/useRequest';
import { useKilnStatus } from '@app/hooks/useKilnStatus';
import { useProcessType } from '@app/hooks/useProcessType';
import { Grid, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import UriUtils from '@app/utils/UriUtils';
import { useCustomer } from '@app/hooks/useCustomer';
import DateUtils from '@app/utils/DateUtils';
import { CampaignModel } from '@app/models/CampaignModel';
import NewCampaignModal from './LiningChart/NewCampaignModal';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import { useUserSettings } from '@app/hooks/useUserSettings';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import Modal from '@app/molecules/Modal';
import { KilnProfileStructureService } from '@app/services/KilnProfileStructureService';
import { getIsMetric } from '@app/utils/ConvertUtils';

const KilnProfilesList = () => {
    useUserSettings();
    const navigate = useNavigate();
    const location = useLocation();
    const { customerId } = UriUtils.parseQueryString(location.search);
    const [openNew, setOpenNew] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [lastCampaign, setLastCampaign] = useState<CampaignModel>();
    const [kilnProfileId, setKilnProfileId] = useState<string>();
    const dispatch = useAppDispatch();
    useCustomer(customerId as string);

    const service = useMemo(() => new KilnProfileStructureService(), []);
    const { kilnStatus, processType } = useAppSelector((state: any) => state);

    useKilnStatus();
    useProcessType();

    const data: KilnProfileModel[] = useRequest({
        service,
        method: service.getByCustomerId,
        params: { customerId: customerId },
        default: [],
    });

    const handleDelete = async () => {
        dispatch(setLoading(true));
        if (kilnProfileId !== undefined) {
            try {
                await service.delete(kilnProfileId);

                const index = data.findIndex((row) => row.id === kilnProfileId);
                data.splice(index, 1);

                dispatch(
                    setMessage({
                        message: 'Successfully deleted the lining.',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: 'Could not delete the lining.',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
        setOpenDelete(false);
        dispatch(setLoading(false));
    };

    const getStatus = (id: string) => {
        const profileStatus = kilnStatus.kilnStatus.find(
            (stat: any) => stat.id === id
        );

        if (profileStatus !== undefined)
            return (
                <span style={{ color: profileStatus.code }}>
                    {profileStatus.name}
                </span>
            );

        return '-';
    };

    const columns = useMemo(
        () => [
            { label: 'Kiln Name', value: 'kilnName' },
            {
                label: 'Last Updated',
                value: TableBodyRowSystem.ROW,
                handle: (value: KilnProfileModel) =>
                    value.updatedAt
                        ? DateUtils.formatUTCDate(value.updatedAt)
                        : DateUtils.formatUTCDate(value.createdAt),
                column: 'updatedAt',
            },
            {
                label: 'Status',
                value: 'idKilnStatus',
                handle: (value: string) => getStatus(value),
            },
            {
                label: '',
                value: 'id',
                handle: (value: string) => (
                    <KilnProfilesListRowActions
                        id={value}
                        setOpenNew={setOpenNew}
                        setOpenDelete={setOpenDelete}
                        setKilnProfileId={setKilnProfileId}
                        setLastCampaign={setLastCampaign}
                    />
                ),
                disableSort: true,
            },
        ],
        [kilnStatus, data]
    );

    const expandedColumns = useMemo(
        () => [
            {
                label: 'Type',
                value: 'idProcessType',
                handle: (value: string) =>
                    processType.processTypes.find((pt: any) => pt.id === value)
                        ?.name,
                disableSort: true,
            },
            {
                label: `Diameter ${getIsMetric() ? '(m)' : '(ft)'}`,
                value: 'diameter',
                handle: (value: number) => +value.toFixed(2),
                disableSort: true,
            },
            {
                label: `Length ${getIsMetric() ? '(m)' : '(ft)'}`,
                value: 'length',
                handle: (value: number) => +value.toFixed(2),
                disableSort: true,
            },
            {
                label: 'Clinker Capacity (ton/day)',
                value: 'clinkerCapacity',
                handle: (value: number) => +value.toFixed(2),
                disableSort: true,
            },
        ],
        [processType, data]
    );

    const renderNewCampaignModal = useCallback(
        () => (
            <NewCampaignModal
                open={openNew}
                setOpen={setOpenNew}
                customerId={customerId as string}
                lastCampaign={lastCampaign}
                kilnProfileId={kilnProfileId}
            />
        ),
        [lastCampaign, kilnProfileId, openNew, customerId]
    );

    const renderDeleteProfileModal = useCallback(
        () => (
            <Modal
                title='You are deleting a Kiln Profile, do you want to confirm this action?'
                showModal={openDelete}
                handleClose={() => {
                    setOpenDelete(false);
                }}
                handleCancel={() => {
                    setOpenDelete(false);
                }}
                handleConfirm={async () => {
                    await handleDelete();
                }}
                confirmTitle='CONFIRM'
            />
        ),
        [kilnProfileId, openDelete, customerId]
    );

    return (
        <Grid container margin='0' rowGap='8px'>
            <Grid container justifyContent='flex-end' margin='0'>
                <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<Add fontSize='small' />}
                    size='large'
                    onClick={() => {
                        navigate(
                            UriUtils.stringifyQueryObject('/kiln', {
                                customerId,
                                isNew: true,
                            })
                        );
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        ADD KILN PROFILE
                    </Typography>
                </Button>
            </Grid>
            <TableList
                columns={columns}
                expandedColumns={expandedColumns}
                data={data}
                rowsPerPage={5}
                expandedTitle='Kiln details'
                searchColumns={['kilnName']}
            />
            {renderNewCampaignModal()}
            {renderDeleteProfileModal()}
        </Grid>
    );
};

export default memo(KilnProfilesList);
