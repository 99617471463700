import { SalesUnitModel } from '@app/models/SalesUnitModel';
import { SalesUnitService } from '@app/services/SalesUnitService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_SALES_UNIT_REQUEST = `${window.env.__REDUX_PATH__}/salesUnit/GET_SALES_UNIT_REQUEST`;
const GET_SALES_UNIT_SUCCESS = `${window.env.__REDUX_PATH__}/salesUnit/GET_SALES_UNIT_SUCCESS`;
const GET_SALES_UNIT_FAILURE = `${window.env.__REDUX_PATH__}/salesUnit/GET_SALES_UNIT_FAILURE`;

export interface SalesUnitState extends BaseState {
    salesUnits: SalesUnitModel[];
}

// Action Types
interface GetSalesUnitRequestAction {
    type: typeof GET_SALES_UNIT_REQUEST;
}

interface GetSalesUnitSuccessAction {
    type: typeof GET_SALES_UNIT_SUCCESS;
    payload?: SalesUnitModel[];
}

interface GetSalesUnitFailureAction {
    type: typeof GET_SALES_UNIT_FAILURE;
    payload?: string;
}

type SalesUnitActionTypes =
    | GetSalesUnitSuccessAction
    | GetSalesUnitFailureAction
    | GetSalesUnitRequestAction;

// Reducer
const initialState: SalesUnitState = {
    salesUnits: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getSalesUnitRequestReducer = (state: SalesUnitState): SalesUnitState => {
    return {
        ...state,
        salesUnits: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getSalesUnitSuccessReducer = (
    state: SalesUnitState,
    action: GetSalesUnitSuccessAction
): SalesUnitState => {
    return {
        ...state,
        salesUnits: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getSalesUnitFailureReducer = (
    state: SalesUnitState,
    action: GetSalesUnitFailureAction
): SalesUnitState => {
    return {
        ...state,
        salesUnits: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: SalesUnitState = initialState,
    action: SalesUnitActionTypes
): SalesUnitState => {
    switch (action.type) {
        case GET_SALES_UNIT_REQUEST:
            return getSalesUnitRequestReducer(state);
        case GET_SALES_UNIT_SUCCESS:
            return getSalesUnitSuccessReducer(
                state,
                action as GetSalesUnitSuccessAction
            );
        case GET_SALES_UNIT_FAILURE:
            return getSalesUnitFailureReducer(
                state,
                action as GetSalesUnitFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getSalesUnitRequest = (): GetSalesUnitRequestAction => {
    return {
        type: GET_SALES_UNIT_REQUEST,
    };
};

export const getSalesUnitSuccess = (
    payload: SalesUnitModel[]
): GetSalesUnitSuccessAction => {
    return {
        type: GET_SALES_UNIT_SUCCESS,
        payload,
    };
};

export const getSalesUnitFailure = (
    payload: string
): GetSalesUnitFailureAction => {
    return {
        type: GET_SALES_UNIT_FAILURE,
        payload,
    };
};

export const getAllSalesUnit = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getSalesUnitRequest());

        try {
            const service = new SalesUnitService();
            const response = await service.getAll();

            dispatch(getSalesUnitSuccess(response));
            dispatch(
                showMessage({
                    message: 'SalesUnit load successfully!',
                    title: 'SalesUnit Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getSalesUnitFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'SalesUnit could not be load!',
                        title: 'SalesUnit Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
