import { memo, useCallback } from 'react';
import { Grid, IconButton } from '@mui/material';
import { colors } from '@app/theme';
import SelectInput from '@app/atoms/SelectInput';
import { Add, Delete } from '@mui/icons-material';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import StringUtils from '@app/utils/StringUtils';

type IProps = {
    handleDeletion: Function;
    handleAddition: Function;
    hasAdd: boolean;
    hasDelete: boolean;
    handleChange: Function;
    stressAreaTypes: StressAreaTypeModel[];
    index: number;
    idStressAreaType: string;
};

const KilnStressAreaType = (props: IProps) => {
    const {
        hasAdd,
        hasDelete,
        handleDeletion,
        handleAddition,
        index,
        stressAreaTypes,
        handleChange,
        idStressAreaType,
    } = props;

    const renderDelete = useCallback(
        () =>
            hasDelete ? (
                <IconButton
                    size='large'
                    aria-label='delete section'
                    component='span'
                    sx={{
                        borderLeft: hasAdd ? '1px' : 0,
                        marginTop: '20px',
                    }}
                    onClick={() => {
                        handleDeletion(index);
                    }}
                >
                    <Delete
                        fontSize='small'
                        sx={{
                            color: colors['Red-RHIM'],
                        }}
                    />
                </IconButton>
            ) : (
                <></>
            ),
        [hasDelete, hasAdd]
    );

    const renderAdd = useCallback(
        () =>
            hasAdd ? (
                <IconButton
                    size='large'
                    aria-label='add section'
                    onClick={() => {
                        handleAddition(index);
                    }}
                    sx={{ marginTop: '20px' }}
                >
                    <Add fontSize='small' />
                </IconButton>
            ) : (
                <></>
            ),
        [hasAdd]
    );

    return (
        <Grid
            sx={{
                marginTop: '8px',
                width: '100%',
                display: 'grid',
                gridTemplateColumns: `auto ${hasAdd ? '40px' : ''} ${
                    hasDelete ? '40px' : ''
                }`,
            }}
        >
            <SelectInput
                title={`Stress Area Type ${index + 1}`}
                value={idStressAreaType}
                options={stressAreaTypes
                    .filter((x) => x.enabled)
                    .map((sat) => ({
                        value: sat.id,
                        label: sat.name,
                    }))}
                onChange={(value: string) => handleChange(value, index)}
                error={StringUtils.isUndefinedOrWhiteSpace(idStressAreaType)}
                helperText={'The stress area must have a stress area type.'}
                required
            />
            {renderAdd()}
            {renderDelete()}
        </Grid>
    );
};

export default memo(KilnStressAreaType);
