import { ProcessTypeModel } from '@app/models/ProcessTypeModel';
import { ProcessTypeService } from '@app/services/ProcessTypeService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_PROCESS_TYPE_REQUEST = `${window.env.__REDUX_PATH__}/processTypes/GET_PROCESS_TYPE_REQUEST`;
const GET_PROCESS_TYPE_SUCCESS = `${window.env.__REDUX_PATH__}/processTypes/GET_PROCESS_TYPE_SUCCESS`;
const GET_PROCESS_TYPE_FAILURE = `${window.env.__REDUX_PATH__}/processTypes/GET_PROCESS_TYPE_FAILURE`;

export interface ProcessTypesState extends BaseState {
    processTypes: ProcessTypeModel[];
}

// Action Types
interface GetProcessTypesRequestAction {
    type: typeof GET_PROCESS_TYPE_REQUEST;
}

interface GetProcessTypesSuccessAction {
    type: typeof GET_PROCESS_TYPE_SUCCESS;
    payload?: ProcessTypeModel[];
}

interface GetProcessTypesFailureAction {
    type: typeof GET_PROCESS_TYPE_FAILURE;
    payload?: string;
}

type ProcessTypesActionTypes =
    | GetProcessTypesSuccessAction
    | GetProcessTypesFailureAction
    | GetProcessTypesRequestAction;

// Reducer
const initialState: ProcessTypesState = {
    processTypes: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getProcessTypesRequestReducer = (
    state: ProcessTypesState
): ProcessTypesState => {
    return {
        ...state,
        processTypes: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getProcessTypesSuccessReducer = (
    state: ProcessTypesState,
    action: GetProcessTypesSuccessAction
): ProcessTypesState => {
    return {
        ...state,
        processTypes: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getProcessTypesFailureReducer = (
    state: ProcessTypesState,
    action: GetProcessTypesFailureAction
): ProcessTypesState => {
    return {
        ...state,
        processTypes: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: ProcessTypesState = initialState,
    action: ProcessTypesActionTypes
): ProcessTypesState => {
    switch (action.type) {
        case GET_PROCESS_TYPE_REQUEST:
            return getProcessTypesRequestReducer(state);
        case GET_PROCESS_TYPE_SUCCESS:
            return getProcessTypesSuccessReducer(
                state,
                action as GetProcessTypesSuccessAction
            );
        case GET_PROCESS_TYPE_FAILURE:
            return getProcessTypesFailureReducer(
                state,
                action as GetProcessTypesFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getProcessTypesRequest = (): GetProcessTypesRequestAction => {
    return {
        type: GET_PROCESS_TYPE_REQUEST,
    };
};

export const getProcessTypesSuccess = (
    payload: ProcessTypeModel[]
): GetProcessTypesSuccessAction => {
    return {
        type: GET_PROCESS_TYPE_SUCCESS,
        payload,
    };
};

export const getProcessTypesFailure = (
    payload: string
): GetProcessTypesFailureAction => {
    return {
        type: GET_PROCESS_TYPE_FAILURE,
        payload,
    };
};

export const getAllProcessTypes = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getProcessTypesRequest());

        try {
            const service = new ProcessTypeService();
            const response = await service.getAll();

            dispatch(getProcessTypesSuccess(response));
            dispatch(
                showMessage({
                    message: 'ProcessTypes load successfully!',
                    title: 'ProcessType Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getProcessTypesFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'ProcessTypes could not be load!',
                        title: 'ProcessType Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getProcessTypes = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getProcessTypesRequest());

        try {
            const service = new ProcessTypeService();
            const response = await service.get(params);

            dispatch(getProcessTypesSuccess(response));
            dispatch(
                showMessage({
                    message: 'ProcessTypes load successfully!',
                    title: 'ProcessTypes Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getProcessTypesFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'ProcessTypes could not be load!',
                        title: 'ProcessTypes Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
