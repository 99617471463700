import { TableBody } from '@mui/material';
import { PropsWithChildren, ReactElement, useState, memo } from 'react';
import TableBodyRow from './TableBodyRow';
import TableRowExpanded from './TableRowExpanded';

interface TableBodyRowsProps {
    columns: Array<
        string | { value: any; label: string; handle?: (value: any) => any }
    >;
    data: any[];
    actions?: ReactElement;
    onRowClick?: (value: any) => void;
    expandedColumns?: Array<
        string | { value: any; label: string; handle?: (value: any) => any }
    >;
    expandedTitle?: string;
}

const TableRowContainer = ({
    props,
    row,
    index,
}: {
    props: TableBodyRowsProps;
    row: any;
    index: number;
}) => {
    const [isExpanded, setExpanded] = useState<boolean>(false);

    return (
        <>
            <TableBodyRow
                expandedTitle={props.expandedTitle}
                onRowClick={props.onRowClick}
                columns={props.columns}
                expandedColumns={props.expandedColumns}
                handleExpand={setExpanded}
                isExpanded={isExpanded}
                row={row}
                actions={props.actions}
                key={`table-body-row-${index}`}
            />
            {props.expandedColumns && (
                <TableRowExpanded
                    expandedColumns={props.expandedColumns}
                    row={row}
                    isExpanded={isExpanded}
                    key={`table-body-row-expanded-${index}`}
                />
            )}
        </>
    );
};

const TableBodyRows = (props: PropsWithChildren<TableBodyRowsProps>) => {
    return (
        <TableBody>
            {props.data.map((row, index) => (
                <TableRowContainer
                    props={props}
                    row={row}
                    index={index}
                    key={`table-body-row-container-${index}`}
                />
            ))}
        </TableBody>
    );
};

export default memo(TableBodyRows);
