import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllKilnStatus } from '@app/store/kilnStatus/reducer';

export const useKilnStatus = (): void => {
    const { isFetching, lastUpdated, kilnStatus } = useAppSelector(
        (state) => state.kilnStatus
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && kilnStatus.length === 0 && !lastUpdated) {
            dispatch(getAllKilnStatus());
        }
    }, []);
};
