import { useAppSelector } from '@app/hooks';
import UriUtils from '@app/utils/UriUtils';
import { useMsal } from '@azure/msal-react';
import { ArrowForward, Delete } from '@mui/icons-material';
import { Grid, Tooltip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '@app/theme';

const LiningsListRowActions = (props: {
    campaignId: string;
    kilnProfileId: string;
    handleDelete: Function;
    setOpenDelete: Function;
}) => {
    const selectedCustomer = useAppSelector(
        (state) => state.customer.selectedCustomer
    );
    const navigate = useNavigate();
    const { accounts } = useMsal();

    return (
        <Grid container justifyContent='space-evenly'>
            <Tooltip title='Lining Chart Table'>
                <IconButton
                    onClick={() =>
                        navigate(
                            UriUtils.stringifyQueryObject('/overview', {
                                customerId: selectedCustomer?.id,
                                kilnProfileId: props.kilnProfileId,
                                campaignId: props.campaignId,
                                view: 'linings',
                            })
                        )
                    }
                    sx={{ border: 'none', backgroundColor: 'inherit' }}
                >
                    <ArrowForward />
                </IconButton>
            </Tooltip>
            {accounts[0].idTokenClaims?.roles?.some(
                (role) => role === 'Admin.ReadWrite'
            ) ? (
                <Tooltip title='Delete Lining'>
                    <IconButton
                        onClick={() => {
                            props.handleDelete(props.campaignId);
                            props.setOpenDelete(true);
                        }}
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                    >
                        <Delete sx={{ color: colors['Red-RHIM'] }} />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </Grid>
    );
};

export default LiningsListRowActions;
