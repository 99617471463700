import { memo } from 'react';
import KilnBundle from '@app/molecules/KilnBundle';
import { SectionModel } from '@app/models/SectionModel';
import { SectionTypeModel } from '@app/models/SectionTypeModel';
import KilnSections from './KilnSections';

interface IProps {
    expanded: number;
    setExpanded: Function;
    setSections: Function;
    sections: SectionModel[];
    sectionTypes: SectionTypeModel[];
    diameter: number;
    length: number;
}

const KilnSectionsBundle = (props: IProps) => {
    return (
        <KilnBundle
            key='bundle-sections'
            name='bundle-sections'
            setExpanded={props.setExpanded}
            expanded={props.expanded}
            index={2}
            title='Kiln Sections'
        >
            <KilnSections
                setSections={props.setSections}
                sections={props.sections}
                sectionTypes={props.sectionTypes}
                length={props.length}
                diameter={props.diameter}
            />
        </KilnBundle>
    );
};

export default memo(KilnSectionsBundle);
