import { CustomerModel } from '@app/models/CustomerModel';
import { CustomerService } from '@app/services/CustomerService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { BaseState } from '../BaseState';
import { showMessage } from '../message/reducer';

// Actions
const GET_CUSTOMERS_REQUEST = `${window.env.__REDUX_PATH__}/customers/GET_CUSTOMERS_REQUEST`;
const GET_CUSTOMERS_SUCCESS = `${window.env.__REDUX_PATH__}/customers/GET_CUSTOMERS_SUCCESS`;
const GET_CUSTOMERS_FAILURE = `${window.env.__REDUX_PATH__}/customers/GET_CUSTOMERS_FAILURE`;
const SELECT_CUSTOMER = `${window.env.__REDUX_PATH__}/customers/SELECT_CUSTOMER`;

export interface CustomersState extends BaseState {
    customers: CustomerModel[];
    selectedCustomer?: CustomerModel;
}

// Action Types
interface GetCustomersRequestAction {
    type: typeof GET_CUSTOMERS_REQUEST;
}

interface GetCustomersSuccessAction {
    type: typeof GET_CUSTOMERS_SUCCESS;
    payload?: CustomerModel[];
}

interface GetCustomersFailureAction {
    type: typeof GET_CUSTOMERS_FAILURE;
    payload?: string;
}

interface SelectCustomerAction {
    type: typeof SELECT_CUSTOMER;
    payload?: CustomerModel;
}

type CustomersActionTypes =
    | GetCustomersRequestAction
    | GetCustomersSuccessAction
    | GetCustomersFailureAction
    | SelectCustomerAction;

// Reducer
const initialState: CustomersState = {
    customers: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
    selectedCustomer: undefined,
};

const getCustomersRequestReducer = (state: CustomersState): CustomersState => {
    return {
        ...state,
        customers: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getCustomersSuccessReducer = (
    state: CustomersState,
    action: GetCustomersSuccessAction
): CustomersState => {
    return {
        ...state,
        customers: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getCustomersFailureReducer = (
    state: CustomersState,
    action: GetCustomersFailureAction
): CustomersState => {
    return {
        ...state,
        customers: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const selectCustomerReducer = (
    state: CustomersState,
    action: SelectCustomerAction
): CustomersState => {
    return {
        ...state,
        selectedCustomer: action.payload,
    };
};

const reducer = (
    state: CustomersState = initialState,
    action: CustomersActionTypes
): CustomersState => {
    switch (action.type) {
        case GET_CUSTOMERS_REQUEST:
            return getCustomersRequestReducer(state);
        case GET_CUSTOMERS_SUCCESS:
            return getCustomersSuccessReducer(
                state,
                action as GetCustomersSuccessAction
            );
        case GET_CUSTOMERS_FAILURE:
            return getCustomersFailureReducer(
                state,
                action as GetCustomersFailureAction
            );
        case SELECT_CUSTOMER:
            return selectCustomerReducer(state, action as SelectCustomerAction);
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getCustomersRequest = (): GetCustomersRequestAction => {
    return {
        type: GET_CUSTOMERS_REQUEST,
    };
};

export const getCustomersSuccess = (
    payload: CustomerModel[]
): GetCustomersSuccessAction => {
    return {
        type: GET_CUSTOMERS_SUCCESS,
        payload,
    };
};

export const getCustomersFailure = (
    payload: string
): GetCustomersFailureAction => {
    return {
        type: GET_CUSTOMERS_FAILURE,
        payload,
    };
};

export const selectCustomer = (
    payload: CustomerModel
): SelectCustomerAction => {
    return {
        type: SELECT_CUSTOMER,
        payload,
    };
};

export const getCustomers = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getCustomersRequest());

        try {
            const service = new CustomerService();
            const response = await service.getAll();

            dispatch(getCustomersSuccess(response));
            dispatch(
                showMessage({
                    message: 'Customers load successfully!',
                    title: 'Customer Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getCustomersFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Customers could not be load!',
                        title: 'Customer Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const chooseCustomer = (
    customer: CustomerModel
): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(selectCustomer(customer));
    };
};
