import { BrandModel } from '@app/models/BrandModel';
import { BrandService } from '@app/services/BrandService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { BaseManageableState } from '../BaseManageableState';
import { showMessage } from '../message/reducer';

// Actions
const GET_BRAND_NAME_REQUEST = `${window.env.__REDUX_PATH__}/brand/GET_BRAND_NAME_REQUEST`;
const GET_BRAND_NAME_SUCCESS = `${window.env.__REDUX_PATH__}/brand/GET_BRAND_NAME_SUCCESS`;
const GET_BRAND_NAME_FAILURE = `${window.env.__REDUX_PATH__}/brand/GET_BRAND_NAME_FAILURE`;

export interface BrandsState extends BaseManageableState {
    brands: BrandModel[];
}

// Action Types
interface GetBrandsRequestAction {
    type: typeof GET_BRAND_NAME_REQUEST;
}

interface GetBrandsSuccessAction {
    type: typeof GET_BRAND_NAME_SUCCESS;
    data?: BrandModel[];
    enabled?: boolean;
}

interface GetBrandsFailureAction {
    type: typeof GET_BRAND_NAME_FAILURE;
    payload?: string;
}

type BrandsActionTypes =
    | GetBrandsSuccessAction
    | GetBrandsFailureAction
    | GetBrandsRequestAction;

// Reducer
const initialState: BrandsState = {
    brands: [],
    enabled: false,
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getBrandsRequestReducer = (state: BrandsState): BrandsState => {
    return {
        ...state,
        brands: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getBrandsSuccessReducer = (
    state: BrandsState,
    action: GetBrandsSuccessAction
): BrandsState => {
    return {
        ...state,
        brands: action.data ?? [],
        enabled: action.enabled ?? false,
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getBrandsFailureReducer = (
    state: BrandsState,
    action: GetBrandsFailureAction
): BrandsState => {
    return {
        ...state,
        brands: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: BrandsState = initialState,
    action: BrandsActionTypes
): BrandsState => {
    switch (action.type) {
        case GET_BRAND_NAME_REQUEST:
            return getBrandsRequestReducer(state);
        case GET_BRAND_NAME_SUCCESS:
            return getBrandsSuccessReducer(
                state,
                action as GetBrandsSuccessAction
            );
        case GET_BRAND_NAME_FAILURE:
            return getBrandsFailureReducer(
                state,
                action as GetBrandsFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getBrandsRequest = (): GetBrandsRequestAction => {
    return {
        type: GET_BRAND_NAME_REQUEST,
    };
};

export const getBrandsSuccess = (
    data: BrandModel[],
    enabled: boolean
): GetBrandsSuccessAction => {
    return {
        type: GET_BRAND_NAME_SUCCESS,
        data,
        enabled,
    };
};

export const getBrandsFailure = (payload: string): GetBrandsFailureAction => {
    return {
        type: GET_BRAND_NAME_FAILURE,
        payload,
    };
};

export const getAllBrands = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getBrandsRequest());

        try {
            const service = new BrandService();
            const response = await service.getAll();

            dispatch(getBrandsSuccess(response, false));
            dispatch(
                showMessage({
                    message: 'Brands load successfully!',
                    title: 'Brand Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getBrandsFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Brands could not be load!',
                        title: 'Brand Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getBrands = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getBrandsRequest());

        try {
            const service = new BrandService();
            const response = await service.get(params);

            dispatch(getBrandsSuccess(response, params.enabled));
            dispatch(
                showMessage({
                    message: 'Brands load successfully!',
                    title: 'Brands Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getBrandsFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'Brands could not be load!',
                        title: 'Brands Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
