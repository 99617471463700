import { useUserSettings } from '@app/hooks/useUserSettings';
import { colors } from '@app/theme';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();
    useUserSettings();
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h3' color='secondary'>
                    Welcome to CLIP
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='body2' color={colors['DarkGrey-RHIM']}>
                    Cement and Lime Intelligence Platform for sales
                    representative to input material application information
                    inside the customers. <br></br>We allow transparent material
                    consumption tracking, and provide market and process
                    insights for the Cement Business Unit.
                </Typography>
            </Grid>
            <Grid item container justifyContent='flex-start' sx={{ gap: 4 }}>
                <Grid
                    item
                    sx={{
                        minHeight: '150px',
                        border: `1px solid ${colors['Blue-RHIM']}`,
                        width: '259px',
                        borderRadius: '4px',
                        display: 'flex',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        navigate('/launchpad');
                    }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <Typography variant='h2' textAlign='center'>
                        Launchpad
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        minHeight: '150px',
                        border: `1px solid ${colors['Blue-RHIM']}`,
                        borderRadius: '4px',
                        width: '259px',
                        display: 'flex',
                        cursor: 'pointer'
                    }}
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => {
                        navigate('/insights');
                    }}
                >
                    <Typography variant='h2' textAlign='center'>
                        Market Insights
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={{
                        minHeight: '150px',
                        border: `1px solid ${colors['Blue-RHIM']}`,
                        borderRadius: '4px',
                        width: '259px',
                        display: 'flex',
                        cursor: 'pointer'
                    }}
                    alignItems='center'
                    justifyContent='center'
                    onClick={() => {
                        navigate('/settings');
                    }}
                >
                    <Typography variant='h2' textAlign='center'>
                        Settings
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HomePage;
