import { memo, useCallback } from 'react';
import { Box } from '@mui/material';
import { StressAreaTypeModel } from '@app/models/StressAreaTypeModel';
import { StressAreaModel } from '@app/models/StressAreaModel';
import KilnStressArea from './KilnStressArea';
import KilnBuilderHeader from '../KilnBuilderHeader';

type IProps = {
    setStressAreas: Function;
    stressAreas: StressAreaModel[];
    stressAreaTypes: StressAreaTypeModel[];
    length: number;
};

const KilnStress = (props: IProps) => {
    const handleChange = () => {
        props.setStressAreas([...props.stressAreas]);
    };

    const handleAddition = (index: number) => {
        const last = props.stressAreas[index] ?? {};
        const newStressArea = {
            ...last,
            stressAreaTypes: [
                {
                    id: '',
                    idStressArea: '',
                    idStressAreaType: '',
                    createdAt: new Date(),
                    createdBy: '',
                },
            ],
            start: last.end ?? 0,
            end: last.end ? last.end + 1 : props.length,
        };
        props.stressAreas.push(newStressArea);

        handleChange();
    };

    const handleDeletion = (index: number) => {
        props.stressAreas.splice(index, 1);

        handleChange();
    };

    const renderStressAreas = useCallback(
        () =>
            props.stressAreas.map((_, index) => (
                <KilnStressArea
                    key={`stress-area-${index}`}
                    index={index}
                    stressAreas={props.stressAreas}
                    length={props.length}
                    handleChange={handleChange}
                    handleDeletion={handleDeletion}
                    handleAddition={handleAddition}
                    stressAreaTypes={props.stressAreaTypes}
                />
            )),
        [props.stressAreas, props.length]
    );

    const renderStressAreaEmpty = useCallback(
        () =>
            props.stressAreas.length === 0 ? (
                <KilnBuilderHeader
                    hasAdd
                    handleAddition={handleAddition}
                    index={props.stressAreas.length}
                    title='Stress Area'
                />
            ) : (
                <></>
            ),
        [props.stressAreas]
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            {renderStressAreaEmpty()}
            {renderStressAreas()}
        </Box>
    );
};

export default memo(KilnStress);
