import { ReactElement, memo } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material';
import NumberUtils from '@app/utils/NumberUtils';

export interface KilnBundleProps {
    name: string;
    title: string | undefined;
    children: Array<ReactElement> | ReactElement | null;
    expanded: number | string;
    setExpanded: Function;
    index: number | string;
}

const KilnBundle = (props: KilnBundleProps) => {
    const { name, setExpanded, expanded, title, index, children } = props;

    return (
        <Accordion
            key={`kiln-bundle-accordion-${props.name}`}
            disableGutters
            elevation={0}
            square
            expanded={expanded === index}
            sx={{ margin: '4px 0' }}
            onChange={() => {
                if (expanded !== index) setExpanded(index);
                else setExpanded(NumberUtils.isValidNumber(expanded) ? -1 : '');
            }}
        >
            <AccordionSummary
                aria-controls={`${name}-content`}
                id={`${name}-header`}
                expandIcon={<KeyboardArrowDown sx={{ fontSize: 'large' }} />}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export default memo(KilnBundle);
