import { UserSettingsModel } from '@app/models/UserSettingsModel';
import { UserSettingsService } from '@app/services/UserSettingsService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_USER_SETTINGS_REQUEST = `${window.env.__REDUX_PATH__}/userSettings/GET_USER_SETTINGS_REQUEST`;
const GET_USER_SETTINGS_SUCCESS = `${window.env.__REDUX_PATH__}/userSettings/GET_USER_SETTINGS_SUCCESS`;
const GET_USER_SETTINGS_FAILURE = `${window.env.__REDUX_PATH__}/userSettings/GET_USER_SETTINGS_FAILURE`;

export interface UserSettingsState extends BaseState {
    settings: UserSettingsModel[];
}

// Action Types
interface GetUserSettingsRequestAction {
    type: typeof GET_USER_SETTINGS_REQUEST;
}

interface GetUserSettingsSuccessAction {
    type: typeof GET_USER_SETTINGS_SUCCESS;
    payload?: UserSettingsModel[];
}

interface GetUserSettingsFailureAction {
    type: typeof GET_USER_SETTINGS_FAILURE;
    payload?: string;
}

type UserSettingsActionTypes =
    | GetUserSettingsRequestAction
    | GetUserSettingsSuccessAction
    | GetUserSettingsFailureAction;

// Reducer
const initialState: UserSettingsState = {
    settings: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getUserSettingsRequestReducer = (
    state: UserSettingsState
): UserSettingsState => {
    return {
        ...state,
        settings: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getUserSettingsSuccessReducer = (
    state: UserSettingsState,
    action: GetUserSettingsSuccessAction
): UserSettingsState => {
    return {
        ...state,
        settings: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getUserSettingsFailureReducer = (
    state: UserSettingsState,
    action: GetUserSettingsFailureAction
): UserSettingsState => {
    return {
        ...state,
        settings: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: UserSettingsState = initialState,
    action: UserSettingsActionTypes
): UserSettingsState => {
    switch (action.type) {
        case GET_USER_SETTINGS_REQUEST:
            return getUserSettingsRequestReducer(state);
        case GET_USER_SETTINGS_SUCCESS:
            return getUserSettingsSuccessReducer(
                state,
                action as GetUserSettingsSuccessAction
            );
        case GET_USER_SETTINGS_FAILURE:
            return getUserSettingsFailureReducer(
                state,
                action as GetUserSettingsFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getUserSettingsRequest = (): GetUserSettingsRequestAction => {
    return {
        type: GET_USER_SETTINGS_REQUEST,
    };
};

export const getUserSettingsSuccess = (
    payload: UserSettingsModel[]
): GetUserSettingsSuccessAction => {
    return {
        type: GET_USER_SETTINGS_SUCCESS,
        payload,
    };
};

export const getUserSettingsFailure = (
    payload: string
): GetUserSettingsFailureAction => {
    return {
        type: GET_USER_SETTINGS_FAILURE,
        payload,
    };
};

export const getAllUserSettings = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getUserSettingsRequest());

        try {
            const service = new UserSettingsService();
            const response = await service.getAll();

            dispatch(getUserSettingsSuccess(response));
            dispatch(
                showMessage({
                    message: 'UserSettings load successfully!',
                    title: 'UserSettings Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getUserSettingsFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'UserSettings could not be load!',
                        title: 'UserSettings Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
