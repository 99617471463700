import { CoatingAreaModel } from '@app/models/CoatingAreaModel';
import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import KilnBundle from '@app/molecules/KilnBundle';
import { memo } from 'react';
import KilnCoating from './KilnCoating';

interface IProps {
    expanded: number;
    setExpanded: Function;
    setCoatingAreas: Function;
    coatingAreas: CoatingAreaModel[];
    coatingAreaTypes: CoatingAreaTypeModel[];
    length: number;
}

const KilnCoatingArea = (props: IProps) => (
    <KilnBundle
        key='bundle-coating'
        name='bundle-coating'
        setExpanded={props.setExpanded}
        expanded={props.expanded}
        index={4}
        title='Kiln Coating Area'
    >
        <KilnCoating
            setCoatingAreas={props.setCoatingAreas}
            coatingAreas={props.coatingAreas}
            coatingAreaTypes={props.coatingAreaTypes}
            length={props.length}
        />
    </KilnBundle>
);

export default memo(KilnCoatingArea);
