import { format } from 'date-fns';

export enum DateFormats {
    UTC_DATE = 'yyyy-MM-dd',
    UTC_TIME = 'HH:ii:ss',
    UTC_DATETIME = 'yyyy-MM-dd HH:ii:ss',
    CAMPAIGN_MONTH_YEAR = 'MMMM yyyy',
}

const formatUTCDate = (value: string | number | Date = new Date()): string =>
    genericFormat(value, DateFormats.UTC_DATE);

const formatUTCTime = (value: string | number | Date = new Date()): string =>
    genericFormat(value, DateFormats.UTC_TIME);

const formatUTCDateTime = (
    value: string | number | Date = new Date()
): string => genericFormat(value, DateFormats.UTC_DATETIME);

const formatCampaignMonthYear = (
    value: string | number | Date = new Date()
): string => genericFormat(value, DateFormats.CAMPAIGN_MONTH_YEAR);

const genericFormat = (
    value: string | number | Date = new Date(),
    pattern: string
): string => {
    const date =
        typeof value !== 'object' || value === null ? new Date(value) : value;
    return Number.isNaN(date.getTime()) ? '-' : format(date, pattern);
};

const DateUtils = {
    formatUTCDate,
    formatUTCDateTime,
    formatUTCTime,
    formatCampaignMonthYear,
    genericFormat,
};

export default DateUtils;
