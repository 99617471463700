import { memo } from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { Warning, CheckCircle, Info } from '@mui/icons-material';
import { MessageState, setMessage } from '@app/store/message/reducer';
import { useAppDispatch } from '@app/hooks';

type IProviderProps = {
    children?: any;
    message?: MessageState;
};

const SnackbarContext = (props: IProviderProps) => {
    const dispatch = useAppDispatch();

    const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
        if (reason !== 'clickaway') dispatch(setMessage({ open: false }));
    };

    return (
        <>
            <Snackbar
                open={props.message?.open}
                onClose={handleClose}
                autoHideDuration={props.message?.duration}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Alert
                    variant='filled'
                    severity={props.message?.color}
                    iconMapping={{
                        error: <Warning color='error' />,
                        warning: <Warning color='warning' />,
                        info: <Info color='info' />,
                        success: <CheckCircle color='success' />,
                    }}
                >
                    <AlertTitle>{props.message?.title}</AlertTitle>
                    {props.message?.message}
                </Alert>
            </Snackbar>
            {props.children}
        </>
    );
};

export default memo(SnackbarContext);
