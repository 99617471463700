import { ShapeStandardModel } from '@app/models/ShapeStandardModel';
import { ShapeStandardService } from '@app/services/ShapeStandardService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_SHAPE_STANDARD_REQUEST = `${window.env.__REDUX_PATH__}/shapeStandard/GET_SHAPE_STANDARD_REQUEST`;
const GET_SHAPE_STANDARD_SUCCESS = `${window.env.__REDUX_PATH__}/shapeStandard/GET_SHAPE_STANDARD_SUCCESS`;
const GET_SHAPE_STANDARD_FAILURE = `${window.env.__REDUX_PATH__}/shapeStandard/GET_SHAPE_STANDARD_FAILURE`;

export interface ShapeStandardState extends BaseState {
    shapeStandards: ShapeStandardModel[];
}

// Action Types
interface GetShapeStandardRequestAction {
    type: typeof GET_SHAPE_STANDARD_REQUEST;
}

interface GetShapeStandardSuccessAction {
    type: typeof GET_SHAPE_STANDARD_SUCCESS;
    payload?: ShapeStandardModel[];
}

interface GetShapeStandardFailureAction {
    type: typeof GET_SHAPE_STANDARD_FAILURE;
    payload?: string;
}

type ShapeStandardActionTypes =
    | GetShapeStandardRequestAction
    | GetShapeStandardSuccessAction
    | GetShapeStandardFailureAction;

// Reducer
const initialState: ShapeStandardState = {
    shapeStandards: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getShapeStandardRequestReducer = (
    state: ShapeStandardState
): ShapeStandardState => {
    return {
        ...state,
        shapeStandards: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getShapeStandardSuccessReducer = (
    state: ShapeStandardState,
    action: GetShapeStandardSuccessAction
): ShapeStandardState => {
    return {
        ...state,
        shapeStandards: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getShapeStandardFailureReducer = (
    state: ShapeStandardState,
    action: GetShapeStandardFailureAction
): ShapeStandardState => {
    return {
        ...state,
        shapeStandards: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: ShapeStandardState = initialState,
    action: ShapeStandardActionTypes
): ShapeStandardState => {
    switch (action.type) {
        case GET_SHAPE_STANDARD_REQUEST:
            return getShapeStandardRequestReducer(state);
        case GET_SHAPE_STANDARD_SUCCESS:
            return getShapeStandardSuccessReducer(
                state,
                action as GetShapeStandardSuccessAction
            );
        case GET_SHAPE_STANDARD_FAILURE:
            return getShapeStandardFailureReducer(
                state,
                action as GetShapeStandardFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getShapeStandardRequest = (): GetShapeStandardRequestAction => {
    return {
        type: GET_SHAPE_STANDARD_REQUEST,
    };
};

export const getShapeStandardSuccess = (
    payload: ShapeStandardModel[]
): GetShapeStandardSuccessAction => {
    return {
        type: GET_SHAPE_STANDARD_SUCCESS,
        payload,
    };
};

export const getShapeStandardFailure = (
    payload: string
): GetShapeStandardFailureAction => {
    return {
        type: GET_SHAPE_STANDARD_FAILURE,
        payload,
    };
};

export const getAllShapeStandard = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getShapeStandardRequest());

        try {
            const service = new ShapeStandardService();
            const response = await service.getAll();

            dispatch(getShapeStandardSuccess(response));
            dispatch(
                showMessage({
                    message: 'ShapeStandard load successfully!',
                    title: 'ShapeStandard Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getShapeStandardFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'ShapeStandard could not be load!',
                        title: 'ShapeStandard Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getShapeStandard = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getShapeStandardRequest());

        try {
            const service = new ShapeStandardService();
            const response = await service.get(params);

            dispatch(getShapeStandardSuccess(response));
            dispatch(
                showMessage({
                    message: 'ShapeStandard load successfully!',
                    title: 'ShapeStandard Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getShapeStandardFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'ShapeStandard could not be load!',
                        title: 'ShapeStandard Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
