import { useAppSelector } from '@app/hooks';
import { useSalesUnit } from '@app/hooks/useSalesUnit';
import { CustomerModel } from '@app/models/CustomerModel';
import { SalesUnitModel } from '@app/models/SalesUnitModel';
import { Grid, Paper, Typography } from '@mui/material';
import { useMemo } from 'react';

type ICustomerTab = {
    customer?: CustomerModel;
};

const CustomerTab = (props: ICustomerTab) => {
    const { salesUnits } = useAppSelector((state) => state.salesUnit);
    useSalesUnit();
    const salesUnit = useMemo(
        () =>
            salesUnits.find(
                (su: SalesUnitModel) => su.id === props.customer?.idSalesUnit
            ),
        [props.customer]
    );

    return (
        <Paper>
            <Grid container display='flex' justifyContent='space-evenly'>
                <Grid container item>
                    <Typography variant='subtitle1'>
                        In case of any missing or wrong information, contact the
                        CRM team.
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Name: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.name ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Customer Id: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.customerId ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Customer SAP Id: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.customerSapId ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Sales Person: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.salesPerson ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Sales Unit: </Typography>
                    <Typography variant='body2'>
                        {salesUnit?.name ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Country: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.country ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>City: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.city ?? ''}
                    </Typography>
                </Grid>
                <Grid item justifyContent='space-between' flexDirection='row'>
                    <Typography variant='body1'>Key Account: </Typography>
                    <Typography variant='body2'>
                        {props.customer?.keyAccount ?? ''}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomerTab;
