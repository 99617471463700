import { memo, useMemo } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { colors } from '@app/theme';
import { useAppSelector } from '@app/hooks';

type IProviderProps = {
    loading?: boolean;
    children?: any;
};

const BackdropContext = (props: IProviderProps) => {
    const store = useAppSelector((state) => state);

    const isFetching = useMemo(
        () =>
            store.brandName.isFetching ||
            store.coatingAreaType.isFetching ||
            store.customer.isFetching ||
            store.kilnStatus.isFetching ||
            store.mpg.isFetching ||
            store.processType.isFetching ||
            store.salesUnit.isFetching ||
            store.sectionType.isFetching ||
            store.shapeStandard.isFetching ||
            store.stressAreaType.isFetching ||
            store.supplier.isFetching ||
            store.userSettings.isFetching ||
            store.zone.isFetching,
        [store]
    );

    return (
        <>
            <Backdrop
                sx={{
                    color: colors['White-RHIM'],
                    zIndex: 9999,
                }}
                open={isFetching || (props.loading ?? false)}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
            {props.children}
        </>
    );
};

export default memo(BackdropContext);
