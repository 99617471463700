import NumberUtils from '@app/utils/NumberUtils';
import { ReactElement, memo } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

export type ChartRegistry = {
    name: string;
    value: number;
    color: string;
    unit?: string;
};

const PizzaChart = (props: {
    data: ChartRegistry[];
    width?: number;
    height?: number;
    radius?: number;
    children?: ReactElement;
}) => {
    const {
        data,
        width = 500,
        height = 400,
        radius = 125,
        children = <></>,
    } = props;

    return (
        <PieChart width={width} height={height}>
            {Boolean(
                data.length &&
                    data.some((registry) =>
                        NumberUtils.isValidNumber(registry.value)
                    )
            ) ? (
                <>
                    <Tooltip formatter={(label: any) => `${label}%`} />
                    <Pie
                        data={data}
                        cx='50%'
                        cy='50%'
                        innerRadius={radius / 2}
                        outerRadius={radius}
                        dataKey='value'
                    >
                        {data.map((entry: ChartRegistry, index: number) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                </>
            ) : (
                <>
                    <Tooltip content={<span>No data provided.</span>} />
                    <Pie
                        data={[{ name: 'undefined', value: 1 }]}
                        cx='50%'
                        cy='50%'
                        innerRadius={radius / 2}
                        outerRadius={radius}
                        dataKey='value'
                    >
                        <Cell key='empty-cell' fill='gray' />
                    </Pie>
                </>
            )}
            {children}
        </PieChart>
    );
};

export default memo(PizzaChart);
