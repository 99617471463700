import StringUtils from '@app/utils/StringUtils';
import { Grid, SortDirection, TableCell, Typography } from '@mui/material';
import { PropsWithChildren, memo } from 'react';

interface TableHeaderColumnProps {
    subtitle?: string;
    isExpandable?: boolean;
    column?: string;
    sorted?: SortDirection;
    onSort?: (property?: string) => void;
    disableSort?: boolean;
}

const TableHeaderColumn = (
    props: PropsWithChildren<TableHeaderColumnProps>
) => (
    <TableCell
        variant='head'
        size={props.isExpandable ? 'small' : 'medium'}
        width={props.isExpandable ? '40px' : 'auto'}
        sortDirection={props.sorted ?? false}
        onClick={
            props.disableSort || props.column === undefined
                ? () => {}
                : (event: React.MouseEvent<unknown>) => {
                      if (props.onSort !== undefined)
                          props.onSort(props.column);
                  }
        }
    >
        <Grid container margin={0}>
            <Grid container item margin={0}>
                {props.children}
            </Grid>
            {!StringUtils.isUndefinedOrWhiteSpace(props.subtitle) && (
                <Grid container item margin={0}>
                    <Typography
                        variant='overline'
                        fontSize='10px'
                        textTransform='lowercase'
                    >
                        {props.subtitle}
                    </Typography>
                </Grid>
            )}
        </Grid>
    </TableCell>
);

export default memo(TableHeaderColumn);
