import { ReportsRepository } from '@app/repositories/ReportsRepository';
import { ServiceBase } from '@app/services/ServiceBase';
import { ReportModel } from '@app/models/ReportModel';

export class ReportsService extends ServiceBase<
    ReportModel,
    ReportsRepository<ReportModel>
> {
    public constructor() {
        super(new ReportsRepository());
    }
}
