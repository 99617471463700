import { LiningDataModel } from '@app/models/LiningDataModel';
import { LiningChartRepository } from '@app/repositories/LiningChartRepository';
import { ServiceBase } from '@app/services/ServiceBase';
import {
    ConversionType,
    convertToMeters,
    convertToMm,
} from '@app/utils/ConvertUtils';
export class LiningChartService extends ServiceBase<
    LiningDataModel,
    LiningChartRepository<LiningDataModel>
> {
    public constructor() {
        super(new LiningChartRepository());
    }

    private convertModel(
        lining: LiningDataModel,
        conversion: ConversionType
    ): LiningDataModel {
        lining.start = convertToMeters(lining.start, conversion);
        lining.end = convertToMeters(lining.end, conversion);
        lining.brickThickness = convertToMm(lining.brickThickness, conversion);

        return lining;
    }

    public async createMultiple(
        linings: LiningDataModel[]
    ): Promise<LiningDataModel[]> {
        return await this.repository.createMultiple(
            linings.map((lining) =>
                this.convertModel(lining, ConversionType.Write)
            )
        );
    }
}
