import { CustomerModel } from '@app/models/CustomerModel';
import { CustomerRepository } from '@app/repositories/CustomerRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class CustomerService extends ServiceBase<
    CustomerModel,
    CustomerRepository<CustomerModel>
> {
    public constructor() {
        super(new CustomerRepository());
    }
}
