import { MPGModel } from '@app/models/MPGModel';
import { MPGService } from '@app/services/MPGService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_MPG_REQUEST = `${window.env.__REDUX_PATH__}/MPG/GET_MPG_REQUEST`;
const GET_MPG_SUCCESS = `${window.env.__REDUX_PATH__}/MPG/GET_MPG_SUCCESS`;
const GET_MPG_FAILURE = `${window.env.__REDUX_PATH__}/MPG/GET_MPG_FAILURE`;

export interface MPGState extends BaseState {
    MPGs: MPGModel[];
}

// Action Types
interface GetMPGRequestAction {
    type: typeof GET_MPG_REQUEST;
}

interface GetMPGSuccessAction {
    type: typeof GET_MPG_SUCCESS;
    payload?: MPGModel[];
}

interface GetMPGFailureAction {
    type: typeof GET_MPG_FAILURE;
    payload?: string;
}

type MPGActionTypes =
    | GetMPGRequestAction
    | GetMPGSuccessAction
    | GetMPGFailureAction;

// Reducer
const initialState: MPGState = {
    MPGs: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getMPGRequestReducer = (state: MPGState): MPGState => {
    return {
        ...state,
        MPGs: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getMPGSuccessReducer = (
    state: MPGState,
    action: GetMPGSuccessAction
): MPGState => {
    return {
        ...state,
        MPGs: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getMPGFailureReducer = (
    state: MPGState,
    action: GetMPGFailureAction
): MPGState => {
    return {
        ...state,
        MPGs: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: MPGState = initialState,
    action: MPGActionTypes
): MPGState => {
    switch (action.type) {
        case GET_MPG_REQUEST:
            return getMPGRequestReducer(state);
        case GET_MPG_SUCCESS:
            return getMPGSuccessReducer(state, action as GetMPGSuccessAction);
        case GET_MPG_FAILURE:
            return getMPGFailureReducer(state, action as GetMPGFailureAction);
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getMPGRequest = (): GetMPGRequestAction => {
    return {
        type: GET_MPG_REQUEST,
    };
};

export const getMPGSuccess = (payload: MPGModel[]): GetMPGSuccessAction => {
    return {
        type: GET_MPG_SUCCESS,
        payload,
    };
};

export const getMPGFailure = (payload: string): GetMPGFailureAction => {
    return {
        type: GET_MPG_FAILURE,
        payload,
    };
};

export const getAllMPG = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getMPGRequest());

        try {
            const service = new MPGService();
            const response = await service.getAll();

            dispatch(getMPGSuccess(response));
            dispatch(
                showMessage({
                    message: 'MPG load successfully!',
                    title: 'MPG Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getMPGFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'MPG could not be load!',
                        title: 'MPG Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getMPG = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getMPGRequest());

        try {
            const service = new MPGService();
            const response = await service.get(params);

            dispatch(getMPGSuccess(response));
            dispatch(
                showMessage({
                    message: 'MPG load successfully!',
                    title: 'MPG Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getMPGFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'MPG could not be load!',
                        title: 'MPG Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
