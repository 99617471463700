import { BrandModel } from '@app/models/BrandModel';
import { CampaignModel } from '@app/models/CampaignModel';
import { LiningDataModel } from '@app/models/LiningDataModel';
import { MPGModel } from '@app/models/MPGModel';
import { ShapeStandardModel } from '@app/models/ShapeStandardModel';
import { SupplierModel } from '@app/models/SupplierModel';
import { ZoneModel } from '@app/models/ZoneModel';
import { ThemeProvider } from '@emotion/react';
import {
    TableRow,
    TableCell,
    createTheme,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Button,
    Typography,
    SvgIcon,
    FormHelperText,
    FormControl,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { colors } from '@app/theme';
import LiningChartTableRow from './LiningChartTableRow';
import { ReactComponent as weightIcon } from '@app/weight.svg';
import { SectionModel } from '@app/models/SectionModel';
import { useUserSettings } from '@app/hooks/useUserSettings';
import {
    ConversionType,
    getIsMetric,
    convertToMeters,
} from '@app/utils/ConvertUtils';

const INITIAL_STATE_SLICE: LiningDataModel = {
    id: '',
    start: 0,
    end: 1,
    idCampaign: '',
    idZone: '',
    idBrandName: '',
    idShapeStandard: '',
    expectedLife: 0,
    createdAt: new Date(),
    createdBy: '',
    brickThickness: 0,
};

interface LiningChartTableProps {
    isValid: boolean;
    isEdit: boolean;
    campaign: CampaignModel;
    kilnLength: number;
    zones: ZoneModel[];
    MPGs: MPGModel[];
    suppliers: SupplierModel[];
    brands: BrandModel[];
    shapeStandards: ShapeStandardModel[];
    liningData: {
        linings: LiningDataModel[];
        setLinings: Dispatch<SetStateAction<LiningDataModel[]>>;
    };
    sections: SectionModel[];
}

const LiningChartTable = (props: LiningChartTableProps) => {
    useUserSettings();

    const {
        liningData: { linings, setLinings },
        campaign,
        isValid,
        isEdit,
        MPGs,
        zones,
        brands,
        suppliers,
        shapeStandards,
        kilnLength,
        sections,
    } = props;

    const handleClick = () => {
        const findFirstAvailable = () => {
            if (linings.length == 0) {
                return 0;
            }

            let position = 0;
            let shouldContinue = true;

            const sortedLinings = linings.sort((a, b) => a.start - b.start);

            if (sortedLinings[0].start > 0) {
                return 0;
            }

            sortedLinings.forEach((current, i) => {
                if (i > 0 && shouldContinue) {
                    const previous = sortedLinings[i - 1];
                    // We select the start position where there is a gap.
                    // The previous lining should not end where the current one begins,
                    // furthermore the previous lining end should be greater than the
                    // position we're in
                    if ((current.start > previous.end) && (previous.end > position)) {
                        shouldContinue = false;
                    }
                    // Regardless, keep the position stored before going further
                    position = previous.end;
                }
            });

            // if we did not find any gap, set the position to the end
            // of the last lining
            if (shouldContinue) {
                const last = sortedLinings[sortedLinings.length - 1];
                position = (last.end > position) ? last.end : position;
            }

            return position;
        };

        const minSize = convertToMeters(0.2, ConversionType.Read);
        let start = findFirstAvailable();
        let end = start + minSize;

        if (end <= kilnLength) {
            setLinings([...linings, { ...INITIAL_STATE_SLICE, start, end }]);
        }
    };

    const renderLines = useCallback(() => {
        return (
            linings &&
            linings.map((lining, index) => {
                linings[index].idCampaign = campaign.id ?? '';
                return (
                    <LiningChartTableRow
                        key={`lining-chart-table-row-${index}`}
                        liningData={{ linings, setLinings }}
                        campaign={campaign}
                        index={index}
                        isEdit={isEdit}
                        MPGs={MPGs}
                        suppliers={suppliers}
                        brands={brands}
                        zones={zones}
                        shapeStandards={shapeStandards}
                        lining={lining}
                        kilnLength={kilnLength}
                        sections={sections}
                    />
                );
            })
        );
    }, [
        linings,
        campaign,
        MPGs,
        suppliers,
        brands,
        zones,
        shapeStandards,
        kilnLength,
        sections,
    ]);

    const theme = createTheme({
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        display: 'none',
                    },
                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        height: '40px',
                        border: 'none',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: '40px',
                        border: 'none',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '&.MuiAutocomplete-inputRoot.MuiInputBase-root.MuiOutlinedInput-root  input': {
                            width: 'auto',
                        },
                    },
                    input: {
                        height: '40px',
                        border: 'none',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        '&[type="text"]': {
                            width: '80px',
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        height: '40px',
                        border: 'none',
                        alignContent: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors['Grey-RHIM'],
                        fontWeight: 'bold',
                        fontSize: '14px',
                        lineHeight: '18px',
                    },
                },
            },
            MuiTableCell: {
                variants: [
                    {
                        props: {
                            variant: 'head',
                        },
                        style: {
                            color: colors['White-RHIM'],
                            fontWeight: 700,
                            height: '48px',
                            padding: '8px',
                            border: 'none',
                        },
                    },
                    {
                        props: {
                            variant: 'body',
                        },
                        style: {
                            height: '40px',
                            padding: 0,
                            border: `1px solid ${colors['Grey40-RHIM']}`,
                            '& p': {
                                minWidth: '90px',
                            }
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        textAlign: 'center',
                        margin: 0,
                        padding: 0,
                        fontSize: '14px',
                        lineHeight: '18px',
                        minWidth: '80px',
                        overflowY: 'hidden',
                    },
                },
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        borderRadius: '4px',
                        overflowX: 'scroll',
                    },
                },
            },
        },
    });

    return (
        <Grid container margin='0' rowGap='8px'>
            <Grid container margin='0'>
                <ThemeProvider theme={theme}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Start {getIsMetric() ? '(m)' : '(ft)'}
                                    </TableCell>
                                    <TableCell>
                                        End {getIsMetric() ? '(m)' : '(ft)'}
                                    </TableCell>
                                    <TableCell>Zone</TableCell>
                                    <TableCell>Brand Name</TableCell>
                                    <TableCell>Mpg</TableCell>
                                    <TableCell>Supplier</TableCell>
                                    <TableCell>
                                        Brick Thickness
                                        {getIsMetric() ? ' (mm)' : ' (in)'}
                                    </TableCell>
                                    <TableCell>Shape Standard</TableCell>
                                    <TableCell>
                                        Average Expected Life (months)
                                    </TableCell>
                                    <TableCell>Install Date</TableCell>
                                    <TableCell>Replace Date</TableCell>
                                    <TableCell>Last Update Date</TableCell>
                                    <TableCell>
                                        <Grid
                                            display='grid'
                                            gridTemplateRows='auto auto'
                                            justifyItems='center'
                                        >
                                            <SvgIcon component={weightIcon} />
                                            <Typography variant='caption'>
                                                TON
                                            </Typography>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{renderLines()}</TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
            </Grid>
            <Grid container margin='0' justifyContent='space-between'>
                <FormControl error={!isValid}>
                    {!isValid ? (
                        <FormHelperText>
                            Every field must be filled and the linings may not
                            override each other.{' '}
                            <br/>
                            The brick thickness must not exceed the radius of the kiln sections where it is applied
                            <br/>
                            <b>
                                Remember that a lining in a conic section cannot
                                be partial. It must contain the whole conic
                                section.
                            </b>
                        </FormHelperText>
                    ) : (
                        <></>
                    )}
                </FormControl>
                <Button
                    hidden={!isEdit}
                    variant='outlined'
                    color='secondary'
                    startIcon={<Add fontSize='small' />}
                    size='large'
                    onClick={handleClick}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        ADD NEW LINE
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
};

export default memo(LiningChartTable);
