import CardFilterContext, {
    INITIAL_VALUES as initialValuesApplied,
} from '@app/contexts/CardFilterContext';
import FilterModalContext, {
    INITIAL_VALUES as initialValuesSelected,
} from '@app/contexts/FilterModalContext';
import MarketInsights from '@app/organisms/MarketInsights/MarketInsights';

const InsightsPage = () => {
    return (
        <CardFilterContext {...initialValuesApplied}>
            <FilterModalContext {...initialValuesSelected}>
                <MarketInsights />
            </FilterModalContext>
        </CardFilterContext>
    );
};

export default InsightsPage;
