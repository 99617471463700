import { memo } from 'react';
import KilnBundle from '@app/molecules/KilnBundle';
import KilnParameters from './KilnParameters';
import { ProcessTypeModel } from '@app/models/ProcessTypeModel';
import { KilnTireModel } from '@app/models/KilnTireModel';

interface IProps {
    expanded: number;
    setExpanded: Function;
    setIdProcessType: Function;
    idProcessType: string;
    setDiameter: Function;
    diameter?: number;
    setLength: Function;
    length?: number;
    setMrk: Function;
    mrk?: number;
    setTires: Function;
    tires: KilnTireModel[];
    setClinkerCapacity: Function;
    clinkerCapacity?: number;
    setGirthGear: Function;
    girthGear?: number;
    processTypes: ProcessTypeModel[];
}

const KilnParametersBundle = (props: IProps) => (
    <KilnBundle
        key='bundle-parameters'
        name='bundle-parameters'
        setExpanded={props.setExpanded}
        expanded={props.expanded}
        index={1}
        title='Kiln Parameters'
    >
        <KilnParameters
            setIdProcessType={props.setIdProcessType}
            idProcessType={props.idProcessType}
            setDiameter={props.setDiameter}
            diameter={props.diameter}
            setLength={props.setLength}
            length={props.length}
            setMrk={props.setMrk}
            mrk={props.mrk}
            setTires={props.setTires}
            tires={props.tires}
            setClinkerCapacity={props.setClinkerCapacity}
            clinkerCapacity={props.clinkerCapacity}
            setGirthGear={props.setGirthGear}
            girthGear={props.girthGear}
            processTypes={props.processTypes}
        />
    </KilnBundle>
);

export default memo(KilnParametersBundle);
