import {
    Box,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material';
import TableBodyRow from './TableBodyRow';
import TableHeaderRow from './TableHeaderRow';
import { memo } from 'react';

interface TableRowExpandedProps {
    expandedColumns: Array<
        string | { value: any; label: string; handle?: (value: any) => any }
    >;
    row: any;
    isExpanded: boolean;
}

const TableRowExpanded = (props: TableRowExpandedProps) => {
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={props.isExpanded} timeout='auto' unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Table size='small'>
                            <TableHeaderRow columns={props.expandedColumns} />
                            <TableBody>
                                <TableBodyRow
                                    columns={props.expandedColumns}
                                    row={props.row}
                                    key={`table-body-row-expanded-row-${props.row.id}`}
                                />
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};

export default memo(TableRowExpanded);
