import { RepositoryBase } from '@app/repositories/RepositoryBase';

export abstract class ServiceBase<T, R extends RepositoryBase<T>> {
    protected repository: R;

    public constructor(repository: R) {
        this.repository = repository;
    }

    public async getAll(): Promise<T[]> {
        return this.repository.getAll();
    }

    public async get<P>(params: P): Promise<T[]> {
        return this.repository.get(params);
    }

    public async getById(id: string): Promise<T> {
        return this.repository.getById(id);
    }

    public async update(entity: T): Promise<T> {
        return this.repository.update(entity);
    }

    public async delete(id: string): Promise<T> {
        return this.repository.delete(id);
    }

    public async create(entity: T): Promise<T> {
        return this.repository.create(entity);
    }
}
