import { useAppDispatch } from '@app/hooks';
import { MarketInsightsService } from '@app/services/MarketInsightsService';
import { MessageState, setMessage } from '@app/store/message/reducer';
import { Add, Download } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { memo, ReactElement, useMemo, useState } from 'react';
import { Dispatch } from 'redux';
import { setLoading } from '@app/store/loading/reducer';

export interface TabContainerProps {
    tabsList: Array<{
        label: string;
        component?: ReactElement;
        disabled?: boolean;
    }>;
    areaTitle?: string;
    active?: number;
    containsButtonExportExcel?: boolean
}

const TabContainer = (props: TabContainerProps) => {
    const { tabsList, areaTitle, active } = props;
    const [activeTab, setActiveTab] = useState(active ? active : 0);
    const serviceMkt = useMemo(() => new MarketInsightsService(), []);
    const dispatch = useAppDispatch();

    const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    const onClickExportKilnsWithoutLinings = async () => {
            dispatch(setLoading(true));
            try {
                await serviceMkt.queueReportKilnsWithoutLinings();
                dispatch(
                    setMessage({
                        title: 'Processing started successfully!',
                        message:
                            'We are processing your report and it will be available on the Reports page.',
                        color: 'success',
                        open: true,
                        duration: 70000,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        title: 'Error',
                        message:
                            'There was a problem with your report, please try exporting again later!',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
    }
    
    
    return (
        <Grid container margin={0}>
            {areaTitle !== null && (
                <Grid container margin={0} justifyContent={"space-between"}>
                    <Typography variant='h5' color='secondary'>
                        {areaTitle}
                    </Typography>
                    { 
                        props.containsButtonExportExcel !== null && props.containsButtonExportExcel && (
                            <Button
                            color='secondary'
                            startIcon={<Download/>}
                            size='large'
                            variant='outlined'
                            onClick={onClickExportKilnsWithoutLinings}
                        >
                            <Typography
                                sx={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                EXPORT DATA
                            </Typography>
                        </Button>
                        )
                    }
                </Grid>
            )}
            <Grid container margin={0}>
                <Box
                    sx={{
                        border: '1px solid #D8DDE1',
                        borderRadius: '4px',
                        margin: '38px 0 24px 0',
                        width: '100%',
                    }}
                >
                    <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        indicatorColor='secondary'
                        textColor='secondary'
                        variant='fullWidth'
                    >
                        {tabsList.map(
                            (
                                tab: { label: string; disabled?: boolean },
                                index: number
                            ) => (
                                <Tab
                                    key={`tab-${index}`}
                                    sx={{
                                        fontWeight: 700,
                                        opacity: index === activeTab ? 1 : 0.3,
                                    }}
                                    label={tab.label}
                                    disabled={tab.disabled}
                                />
                            )
                        )}
                    </Tabs>
                </Box>
            </Grid>
            <Grid container margin='0'>
                {tabsList[activeTab]?.component ?? <></>}
            </Grid>
        </Grid>
    );
};

export default memo(TabContainer);
