import { useMemo, memo } from 'react';
import StringUtils from '@app/utils/StringUtils';
import { InputLabel, Grid, TextField } from '@mui/material';

type IProps = {
    variant?: 'filled' | 'outlined' | 'standard';
    label?: string;
    title?: string;
    value?: string;
    onChange: Function;
    size?: 'medium' | 'small';
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    type?: 'text' | 'password';
    error?: boolean;
    helperText?: string;
    placeholder?: string;
};

const TextInput = (props: IProps) => {
    const {
        variant = 'outlined',
        label,
        title,
        value = '',
        onChange,
        size = 'small',
        required = false,
        readonly = false,
        disabled = false,
        error = false,
        helperText,
        type = 'text',
        placeholder,
    } = props;

    const hasTitle = useMemo(
        () => !StringUtils.isUndefinedOrWhiteSpace(title),
        [title]
    );

    const notFilled = useMemo(
        () => required && StringUtils.isUndefinedOrWhiteSpace(value),
        [required, value]
    );

    const legend = useMemo(() => {
        if (error) return helperText;
        if (notFilled) return 'Field must be filled.';
    }, [error, notFilled, helperText]);

    return (
        <Grid sx={{ width: '100%', margin: '0' }}>
            {hasTitle ? (
                <InputLabel>{`${title} ${required ? '*' : ''}`}</InputLabel>
            ) : (
                <></>
            )}
            <TextField
                fullWidth
                value={value}
                size={size}
                onChange={({ target: { value } }: any) => {
                    onChange(value);
                }}
                label={label}
                variant={variant}
                required={required}
                type={type}
                disabled={disabled}
                InputProps={{ readOnly: readonly }}
                error={error || notFilled}
                helperText={legend}
                placeholder={placeholder}
            />
        </Grid>
    );
};

export default memo(TextInput);
