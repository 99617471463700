import { ShapeStandardModel } from '@app/models/ShapeStandardModel';
import { ShapeStandardService } from '@app/services/ShapeStandardService';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { Add, Edit } from '@mui/icons-material';
import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { useMemo, useState, useCallback } from 'react';
import Modal from '@app/molecules/Modal';
import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import StringUtils from '@app/utils/StringUtils';
import { getAllShapeStandard } from '@app/store/shapeStandard/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useShapeStandard } from '@app/hooks/useShapeStandard';

const INITIAL_STATE: ShapeStandardModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    shape: '',
};

const ShapeStandardList = () => {
    const dispatch = useAppDispatch();
    const { shapeStandards } = useAppSelector((state) => state.shapeStandard);
    useShapeStandard();

    const shapeStandardService = useMemo(
        () => new ShapeStandardService(),
        []
    );

    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingShapeStandard, setEditingShapeStandard] =
        useState<ShapeStandardModel>({ ...INITIAL_STATE });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingShapeStandard({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleShapeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingShapeStandard({
                ...editingShapeStandard,
                shape: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await shapeStandardService.create({
                    ...editingShapeStandard,
                });
                dispatch(getAllShapeStandard());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingShapeStandard.shape);

        return (
            <Modal
                title='Create Shape Standard'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingShapeStandard?.shape}
                    label='Shape'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleShapeChange}
                />
            </Modal>
        );
    }, [editingShapeStandard, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingShapeStandard({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleShapeChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingShapeStandard({
                ...editingShapeStandard,
                shape: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await shapeStandardService.update({
                    ...editingShapeStandard,
                });
                dispatch(getAllShapeStandard());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await shapeStandardService.delete(editingShapeStandard.id);
                dispatch(getAllShapeStandard());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const isConfirmDisabled = () =>
            StringUtils.isUndefinedOrWhiteSpace(editingShapeStandard.shape);

        return (
            <Modal
                title='Edit Shape Standard'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
                confirmDisabled={isConfirmDisabled()}
            >
                <TextField
                    value={editingShapeStandard?.shape}
                    label='Shape'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleShapeChange}
                />
            </Modal>
        );
    }, [editingShapeStandard, showModalEditing]);

    const getEditAction = useCallback((shapeStandard: ShapeStandardModel) => {
        const handleClick = () => {
            setEditingShapeStandard(shapeStandard);
            setShowModalEditing(true);
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Edit ShapeStandard'>
                    <IconButton
                        sx={{ border: 'none', backgroundColor: 'inherit' }}
                        onClick={handleClick}
                    >
                        <Edit />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    }, []);

    const getEnableAction = useCallback((shapeStandard: ShapeStandardModel) => {
        const isChecked = shapeStandard.enabled;

        const handleChange = async () => {
            try {
                dispatch(setLoading(true));
                await shapeStandardService.update({
                    ...shapeStandard,
                    enabled: !isChecked,
                });
                dispatch(getAllShapeStandard());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            dispatch(setLoading(false));
        };

        return (
            <Grid container alignContent='center'>
                <Tooltip title='Enabled/Disable ShapeStandard'>
                    <Switch
                        onChange={handleChange}
                        value={isChecked}
                        checked={isChecked}
                    />
                </Tooltip>
            </Grid>
        );
    }, []);

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW SHAPE STANDARD
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Shape', value: 'shape' },
            {
                label: 'Created By',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={shapeStandards}
                    rowsPerPage={5}
                    searchColumns={['shape']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default ShapeStandardList;
