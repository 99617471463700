import { KilnProfileStructureModel } from '@app/models/KilnProfileStructureModel';
import { RepositoryBase } from '@app/repositories/RepositoryBase';

export class KilnProfileRepository<
    KilnProfileModel
> extends RepositoryBase<KilnProfileModel> {
    public constructor() {
        super('kilnProfile');
    }

    public async updateStructure(
        kiln: KilnProfileStructureModel
    ): Promise<KilnProfileStructureModel> {
        return this.handleResponse(await this.api.put('/structure', kiln));
    }

    public async createStructure(
        kiln: KilnProfileStructureModel
    ): Promise<KilnProfileStructureModel> {
        return this.handleResponse(await this.api.post('/structure', kiln));
    }

    public async getByCustomerId(
        customerId: string
    ): Promise<Array<KilnProfileStructureModel>> {
        return this.handleResponse(
            await this.api.get(`/customer/${customerId}`)
        );
    }
}
