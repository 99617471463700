import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllSalesUnit } from '@app/store/salesUnit/reducer';

export const useSalesUnit = (): void => {
    const { isFetching, lastUpdated, salesUnits } = useAppSelector(
        (state) => state.salesUnit
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && salesUnits.length === 0 && !lastUpdated) {
            dispatch(getAllSalesUnit());
        }
    }, []);
};
