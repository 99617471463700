import TableHeaderColumn from '@app/atoms/TableHeaderColumn';
import { SortDirection, TableHead, TableRow } from '@mui/material';
import {
    VerticalAlignTop,
    VerticalAlignBottom,
    ImportExport,
} from '@mui/icons-material';
import { PropsWithChildren, memo } from 'react';
import { TableBodyRowSystem } from './TableBodyRow';

interface TableHeaderRowProps {
    columns: Array<
        | string
        | {
              value: any;
              label: string;
              handle?: (value: any) => any;
              subtitle?: string;
              column?: string;
              disableSort?: boolean;
          }
    >;
    hasActions?: boolean;
    isExpandable?: boolean;
    onSort?: (property?: string) => void;
    sortedBy?: string;
    sorted?: SortDirection;
    disableSort?: boolean;
}

const TableHeaderRow = (props: PropsWithChildren<TableHeaderRowProps>) => (
    <TableHead>
        <TableRow>
            {props.isExpandable && (
                <TableHeaderColumn
                    isExpandable={props.isExpandable}
                    key={`table-header-row-column-collapsible`}
                />
            )}
            {props.columns.map((column, index) => {
                const isStringColumn = typeof column === 'string';
                const label = isStringColumn ? column : column.label;
                const subtitle = isStringColumn ? '' : column.subtitle ?? '';
                const columnName = isStringColumn
                    ? column
                    : column.value !== TableBodyRowSystem.ROW
                    ? column.value
                    : column.column;
                const disableSort =
                    props.disableSort ??
                    (isStringColumn
                        ? column === undefined
                        : column.disableSort) ??
                    columnName === undefined;
                return (
                    <TableHeaderColumn
                        subtitle={subtitle}
                        key={`table-header-row-column-${index}`}
                        onSort={props.onSort}
                        column={columnName}
                        sorted={props.sorted}
                        disableSort={disableSort}
                    >
                        {disableSort ? (
                            <></>
                        ) : props.sorted && props.sortedBy === columnName ? (
                            props.sorted === 'asc' ? (
                                <VerticalAlignTop
                                    color='secondary'
                                    width='24px'
                                />
                            ) : (
                                <VerticalAlignBottom
                                    color='secondary'
                                    width='24px'
                                />
                            )
                        ) : (
                            <ImportExport color='secondary' width='24px' />
                        )}
                        {label}
                    </TableHeaderColumn>
                );
            })}
            {props.hasActions && (
                <TableHeaderColumn subtitle=''></TableHeaderColumn>
            )}
        </TableRow>
    </TableHead>
);

export default memo(TableHeaderRow);
