import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import { CoatingAreaTypeRepository } from '@app/repositories/CoatingAreaTypeRepository';
import { ServiceBase } from '@app/services/ServiceBase';

export class CoatingAreaTypeService extends ServiceBase<
    CoatingAreaTypeModel,
    CoatingAreaTypeRepository<CoatingAreaTypeModel>
> {
    public constructor() {
        super(new CoatingAreaTypeRepository());
    }
}
