import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { getAllZone } from '@app/store/zone/reducer';

export const useZone = (): void => {
    const { isFetching, lastUpdated, zones } = useAppSelector(
        (state) => state.zone
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isFetching && zones.length === 0 && !lastUpdated) {
            dispatch(getAllZone());
        }
    }, []);
};
