import { setLoading } from '@app/store/loading/reducer';
import { setMessage } from '@app/store/message/reducer';
import { CoatingAreaTypeModel } from '@app/models/CoatingAreaTypeModel';
import Modal from '@app/molecules/Modal';
import { TableBodyRowSystem } from '@app/molecules/TableBodyRow';
import TableList from '@app/organisms/TableList';
import { CoatingAreaTypeService } from '@app/services/CoatingAreaTypeService';
import { Add, Edit } from '@mui/icons-material';
import {
    TextField,
    Grid,
    Tooltip,
    IconButton,
    Button,
    Typography,
    Switch,
} from '@mui/material';
import DateUtils from '@app/utils/DateUtils';
import { memo, useMemo, useState, useCallback } from 'react';
import { getAllCoatingAreaTypes } from '@app/store/coatingAreaType/reducer';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useCoatingAreaType } from '@app/hooks/useCoatingAreaType';

const INITIAL_STATE: CoatingAreaTypeModel = {
    id: '',
    enabled: true,
    createdAt: new Date(),
    createdBy: '',
    deletedAt: new Date(),
    deletedBy: '',
    updatedAt: new Date(),
    updatedBy: '',
    name: '',
    description: '',
};

const CoatingAreatypeList = () => {
    const dispatch = useAppDispatch();
    const { coatingAreaTypes } = useAppSelector(
        (state) => state.coatingAreaType
    );
    useCoatingAreaType();

    const coatingAreaTypesService = useMemo(
        () => new CoatingAreaTypeService(),
        []
    );
    const [showModalEditing, setShowModalEditing] = useState<boolean>(false);
    const [showModalCreating, setShowModalCreating] = useState<boolean>(false);
    const [editingCoatingAreaType, setEditingCoatingAreaType] =
        useState<CoatingAreaTypeModel>({
            ...INITIAL_STATE,
        });

    const renderCreateModal = useCallback(() => {
        const handleClose = () => {
            setEditingCoatingAreaType({ ...INITIAL_STATE });
            setShowModalCreating(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingCoatingAreaType({
                ...editingCoatingAreaType,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await coatingAreaTypesService.create({
                    ...editingCoatingAreaType,
                });
                dispatch(getAllCoatingAreaTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='New Coating Area Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                showModal={showModalCreating}
            >
                <TextField
                    value={editingCoatingAreaType?.name}
                    label='Coating Area Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
            </Modal>
        );
    }, [editingCoatingAreaType, showModalCreating]);

    const renderUpdateModal = useCallback(() => {
        const handleClose = () => {
            setEditingCoatingAreaType({ ...INITIAL_STATE });
            setShowModalEditing(false);
        };

        const handleNameChange = ({
            target: { value },
        }: {
            target: { value: string };
        }) => {
            setEditingCoatingAreaType({
                ...editingCoatingAreaType,
                name: value,
            });
        };

        const handleCancel = () => {
            handleClose();
        };

        const handleConfirm = async () => {
            try {
                dispatch(setLoading(true));
                await coatingAreaTypesService.update({
                    ...editingCoatingAreaType,
                });
                dispatch(getAllCoatingAreaTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        const handleDelete = async () => {
            try {
                dispatch(setLoading(true));
                await coatingAreaTypesService.delete(editingCoatingAreaType.id);
                dispatch(getAllCoatingAreaTypes());
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Success',
                        color: 'success',
                        open: true,
                    })
                );
            } catch (ex) {
                dispatch(
                    setMessage({
                        message: '',
                        title: 'Error',
                        color: 'error',
                        open: true,
                    })
                );
            }
            handleClose();
            dispatch(setLoading(false));
        };

        return (
            <Modal
                title='Edit Coating Area Type'
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                handleCancel={handleCancel}
                extraButton={
                    <Button
                        color='primary'
                        variant='outlined'
                        onClick={handleDelete}
                    >
                        DELETE
                    </Button>
                }
                showModal={showModalEditing}
            >
                <TextField
                    value={editingCoatingAreaType?.name}
                    label='Coating Area Type'
                    size='small'
                    sx={{ margin: '8px 0', width: '367px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onChange={handleNameChange}
                />
            </Modal>
        );
    }, [editingCoatingAreaType, showModalEditing]);

    const getEditAction = useCallback(
        (coatingAreaType: CoatingAreaTypeModel) => {
            const handleClick = () => {
                setEditingCoatingAreaType(coatingAreaType);
                setShowModalEditing(true);
            };

            return (
                <Grid container alignContent='center'>
                    <Tooltip title='Edit Coating Area Type'>
                        <IconButton
                            sx={{ border: 'none', backgroundColor: 'inherit' }}
                            onClick={handleClick}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Grid>
            );
        },
        []
    );

    const getEnableAction = useCallback(
        (coatingAreaType: CoatingAreaTypeModel) => {
            const isChecked = coatingAreaType.enabled;

            const handleChange = async () => {
                try {
                    dispatch(setLoading(true));
                    await coatingAreaTypesService.update({
                        ...coatingAreaType,
                        enabled: !isChecked,
                    });
                    dispatch(getAllCoatingAreaTypes());
                    dispatch(
                        setMessage({
                            message: '',
                            title: 'Success',
                            color: 'success',
                            open: true,
                        })
                    );
                } catch (ex) {
                    dispatch(
                        setMessage({
                            message: '',
                            title: 'Error',
                            color: 'error',
                            open: true,
                        })
                    );
                }
                dispatch(setLoading(false));
            };

            return (
                <Grid container alignContent='center'>
                    <Tooltip title='Enabled/Disable Coating Area Type'>
                        <Switch
                            onChange={handleChange}
                            value={isChecked}
                            checked={isChecked}
                        />
                    </Tooltip>
                </Grid>
            );
        },
        []
    );

    const getActions = useCallback(
        () => (
            <Button
                variant='contained'
                color='secondary'
                startIcon={<Add fontSize='small' />}
                size='large'
                onClick={() => {
                    setShowModalCreating(true);
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    ADD NEW COATING TYPE
                </Typography>
            </Button>
        ),
        []
    );

    const columns = useMemo(
        () => [
            { label: 'Area Type', value: 'name' },
            {
                label: 'Created by',
                value: 'createdBy',
                handle: (value: string) =>
                    value !== 'createdBy' ? value : '-',
            },
            {
                label: 'Last Updated',
                value: 'updatedAt',
                handle: (value: string) => DateUtils.formatUTCDate(value),
            },
            {
                label: 'Enable',
                value: TableBodyRowSystem.ROW,
                handle: getEnableAction,
            },
            {
                label: 'Edit',
                value: TableBodyRowSystem.ROW,
                handle: getEditAction,
            },
        ],
        []
    );

    return (
        <>
            <Grid container margin={0}>
                <TableList
                    columns={columns}
                    data={coatingAreaTypes}
                    rowsPerPage={5}
                    searchColumns={['name', 'description', 'createdBy']}
                    searchSibling={getActions()}
                />
            </Grid>
            {renderCreateModal()}
            {renderUpdateModal()}
        </>
    );
};

export default memo(CoatingAreatypeList);
