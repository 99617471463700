import { KilnStatusModel } from '@app/models/KilnStatusModel';
import { KilnStatusService } from '@app/services/KilnStatusService';
import { AppThunk } from '@app/store';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';
import { showMessage } from '../message/reducer';
import { BaseState } from '../BaseState';

// Actions
const GET_KILN_STATUS_REQUEST = `${window.env.__REDUX_PATH__}/kilnStatus/GET_KILN_STATUS_REQUEST`;
const GET_KILN_STATUS_SUCCESS = `${window.env.__REDUX_PATH__}/kilnStatus/GET_KILN_STATUS_SUCCESS`;
const GET_KILN_STATUS_FAILURE = `${window.env.__REDUX_PATH__}/kilnStatus/GET_KILN_STATUS_FAILURE`;

export interface KilnStatusState extends BaseState {
    kilnStatus: KilnStatusModel[];
}

// Action Types
interface GetKilnStatusRequestAction {
    type: typeof GET_KILN_STATUS_REQUEST;
}

interface GetKilnStatusSuccessAction {
    type: typeof GET_KILN_STATUS_SUCCESS;
    payload?: KilnStatusModel[];
}

interface GetKilnStatusFailureAction {
    type: typeof GET_KILN_STATUS_FAILURE;
    payload?: string;
}

type KilnStatusActionTypes =
    | GetKilnStatusSuccessAction
    | GetKilnStatusFailureAction
    | GetKilnStatusRequestAction;

// Reducer
const initialState: KilnStatusState = {
    kilnStatus: [],
    isFetching: false,
    lastUpdated: undefined,
    serverError: undefined,
};

const getKilnStatusRequestReducer = (
    state: KilnStatusState
): KilnStatusState => {
    return {
        ...state,
        kilnStatus: [],
        isFetching: true,
        lastUpdated: Date.now(),
    };
};

const getKilnStatusSuccessReducer = (
    state: KilnStatusState,
    action: GetKilnStatusSuccessAction
): KilnStatusState => {
    return {
        ...state,
        kilnStatus: action.payload ?? [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: undefined,
    };
};

const getKilnStatusFailureReducer = (
    state: KilnStatusState,
    action: GetKilnStatusFailureAction
): KilnStatusState => {
    return {
        ...state,
        kilnStatus: [],
        isFetching: false,
        lastUpdated: Date.now(),
        serverError: action.payload,
    };
};

const reducer = (
    state: KilnStatusState = initialState,
    action: KilnStatusActionTypes
): KilnStatusState => {
    switch (action.type) {
        case GET_KILN_STATUS_REQUEST:
            return getKilnStatusRequestReducer(state);
        case GET_KILN_STATUS_SUCCESS:
            return getKilnStatusSuccessReducer(
                state,
                action as GetKilnStatusSuccessAction
            );
        case GET_KILN_STATUS_FAILURE:
            return getKilnStatusFailureReducer(
                state,
                action as GetKilnStatusFailureAction
            );
        case 'CLEAR_STORE':
            return initialState;
        default:
            return state;
    }
};

export default reducer;

// Action Creators
export const getKilnStatusRequest = (): GetKilnStatusRequestAction => {
    return {
        type: GET_KILN_STATUS_REQUEST,
    };
};

export const getKilnStatusSuccess = (
    payload: KilnStatusModel[]
): GetKilnStatusSuccessAction => {
    return {
        type: GET_KILN_STATUS_SUCCESS,
        payload,
    };
};

export const getKilnStatusFailure = (
    payload: string
): GetKilnStatusFailureAction => {
    return {
        type: GET_KILN_STATUS_FAILURE,
        payload,
    };
};

export const getAllKilnStatus = (): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getKilnStatusRequest());

        try {
            const service = new KilnStatusService();
            const response = await service.getAll();

            dispatch(getKilnStatusSuccess(response));
            dispatch(
                showMessage({
                    message: 'KilnStatus load successfully!',
                    title: 'KilnStatus Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getKilnStatusFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'KilnStatus could not be load!',
                        title: 'KilnStatus Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};

export const getKilnStatus = (params: {
    enabled: boolean;
}): AppThunk<Promise<void>> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(getKilnStatusRequest());

        try {
            const service = new KilnStatusService();
            const response = await service.get(params);

            dispatch(getKilnStatusSuccess(response));
            dispatch(
                showMessage({
                    message: 'KilnStatus load successfully!',
                    title: 'KilnStatus Load',
                    color: 'success',
                    open: true,
                })
            );
        } catch (error) {
            const { response } = error as AxiosError;
            const serverError =
                response?.data?.error ?? 'An unexpected error occurred.';

            if (response?.status !== 401) {
                dispatch(getKilnStatusFailure(serverError));
                dispatch(
                    showMessage({
                        message: 'KilnStatus could not be load!',
                        title: 'KilnStatus Load',
                        color: 'error',
                        open: true,
                    })
                );
            }
        }
    };
};
